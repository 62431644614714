import { Flex } from '@adobe/react-spectrum';
import React, { useEffect } from 'react';
import { generatePath, useMatch, useNavigate } from 'react-router-dom';

import { HeaderContainer } from '@exchange-frontends/components';
import { useMediaQuery } from '@exchange-frontends/custom-hooks';
import { handleManageForLaunch } from '@exchange-frontends/utils/src/analytics';

import { ROUTES } from '../../../../constants';
import { useStore } from '../../store';
import { TABS_DEFINITION } from '../../utils/TabsDefinition';
import Tabs from './Tabs';

const Header = () => {
  const selectedOrg = useStore((state) => state.selectedOrg);
  const allApps = useStore((state) => state.allApps);
  const entitlements = useStore((state) => state.entitlements);
  const orgs = localStorage.getItem('orgs') ? JSON.parse(localStorage.getItem('orgs')) : undefined;

  const isLoadingData = !allApps || !entitlements || !selectedOrg;
  const isIndexPage = useMatch(ROUTES.MANAGE);
  const isSingleColumn = useMediaQuery('(max-width: 500px)');

  const matchedECAppsRoute = useMatch('/manage/apps/ec/:orgId/:type');
  const matchedECAppManageRoute = useMatch('/manage/apps/ec/:orgId/view/:appId');
  const navigate = useNavigate();

  /**
   * Listen for the selectedOrgChange event and update the selectedOrg state
   * NOTE: selectedOrg.id is the AMS ID, selectedOrg.code is the IMS ID
   */
  const selectedOrgChangeEventListener = (e) => {
    const newSelectedOrg = e.detail;
    // selectedOrg changed, setting selectedOrg to newSelectedOrg
    useStore.setState({ selectedOrg: newSelectedOrg });
    // selectedOrg changed, checking if we're on the EC Apps route
    if (newSelectedOrg?.id) {
      const isAdmin = orgs?.find((org) => org.id === newSelectedOrg.id)?.role === 'ADMIN';
      /** handle redirect */
      if (matchedECAppManageRoute) {
        // matchedECAppManageRoute found, redirecting to matchedECAppsRoute's path since this is a different org
        const path = !isAdmin ? TABS_DEFINITION.EC_APPS.path : matchedECAppManageRoute?.pattern?.path;
        const route = generatePath(path, { ...matchedECAppManageRoute.params, orgId: newSelectedOrg.code });
        navigate(route);
      } else if (matchedECAppsRoute) {
        // matchedECAppsRoute found, redirecting to matchedECAppsRoute's path
        const path = !isAdmin ? TABS_DEFINITION.EC_APPS.path : matchedECAppsRoute?.pattern?.path;
        const route = generatePath(path, { ...matchedECAppsRoute.params, orgId: newSelectedOrg.code });
        navigate(route);
      }
    }
  };
  useEffect(() => {
    // removing event listener for 'selectedOrgChange' if it exists
    document.removeEventListener('selectedOrgChange', selectedOrgChangeEventListener);
    // adding event listener for 'selectedOrgChange' event fired by __container/Header
    document.addEventListener('selectedOrgChange', selectedOrgChangeEventListener);
    return () => {
      // removing event listener for 'selectedOrgChange' upon unmount
      document.removeEventListener('selectedOrgChange', selectedOrgChangeEventListener);
    };
  }, [matchedECAppsRoute, matchedECAppManageRoute]);

  useEffect(() => {
    const routeOrg = matchedECAppsRoute?.params?.orgId;
    if (routeOrg) {
      if (routeOrg === selectedOrg?.code) {
        // routeOrg's org is already selected, returning
        return;
      }
      // routeOrg found, setting selectedOrg to routeOrg's org"
      if (orgs?.length) {
        const newSelectedOrg = orgs.find((org) => org.code === routeOrg) ?? selectedOrg;
        if (!newSelectedOrg) {
          // routeOrg's org not found in orgs, setting to orgs[0]"
          const defaultSelectedOrg = orgs?.[0];
          useStore.setState({
            selectedOrg: defaultSelectedOrg,
          });
          const isAdmin = defaultSelectedOrg?.role === 'ADMIN';
          const path = !isAdmin ? TABS_DEFINITION.EC_APPS.path : matchedECAppsRoute.pattern.path;
          const route = generatePath(path, { ...matchedECAppsRoute.params, orgId: defaultSelectedOrg?.code });
          navigate(route);
        }
        useStore.setState({
          selectedOrg: newSelectedOrg,
        });
      }
    } else {
      // no routeOrg found
      if (selectedOrg) {
        // no routeOrg found and selectedOrg found, returning
        return;
      }
      // no selectedOrg found, setting selectedOrg to session's selectedOrg. probably missed the initial event dispatch
      try {
        const sessionSelectedOrg = JSON.parse(localStorage.getItem('selectedOrg'));
        selectedOrgChangeEventListener({ detail: sessionSelectedOrg });
        // setSelectedOrg(sessionSelectedOrg);
        // useStore.setState({ selectedOrg: sessionSelectedOrg });
      } catch (error) {
        console.log('error:', error);
        selectedOrgChangeEventListener({ detail: orgs?.[0] || null });
      }
    }
  }, [matchedECAppsRoute, selectedOrg]);

  return isLoadingData && isIndexPage ? null : (
    <HeaderContainer onClickCapture={handleManageForLaunch}>
      <Flex
        wrap
        data-testid="manage-apps-header-flex"
        alignItems="bottom"
        justifyContent="center"
        marginBottom="-8px"
        minWidth="250px"
        height="100%"
        marginEnd={isSingleColumn ? '0' : '20px'}
      >
        <Tabs />
      </Flex>
    </HeaderContainer>
  );
};

export default Header;
