{
  "alpha": "Alpha",
  "black": "negro",
  "blue": "Azul",
  "blue purple": "púrpura azulado",
  "brightness": "Brillo",
  "brown": "marrón",
  "brown yellow": "amarillo amarronado",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cian",
  "cyan blue": "azul cian",
  "dark": "oscuro",
  "gray": "gris",
  "grayish": "grisáceo",
  "green": "Verde",
  "green cyan": "cian verdoso",
  "hue": "Tono",
  "light": "claro",
  "lightness": "Luminosidad",
  "magenta": "magenta",
  "magenta pink": "rosa magenta",
  "orange": "naranja",
  "orange yellow": "amarillo anaranjado",
  "pale": "pálido",
  "pink": "rosa",
  "pink red": "rojo rosado",
  "purple": "morado",
  "purple magenta": "magenta violáceo",
  "red": "Rojo",
  "red orange": "naranja rojizo",
  "saturation": "Saturación",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} transparente",
  "very dark": "muy oscuro",
  "very light": "muy claro",
  "vibrant": "intenso",
  "white": "blanco",
  "yellow": "amarillo",
  "yellow green": "verde amarillento"
}
