{
  "alpha": "Альфа",
  "black": "чорний",
  "blue": "Синій",
  "blue purple": "синьо-фіолетовий",
  "brightness": "Яскравість",
  "brown": "коричневий",
  "brown yellow": "коричнево-жовтий",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "блакитний",
  "cyan blue": "синьо-блакитний",
  "dark": "темний",
  "gray": "сірий",
  "grayish": "сіруватий",
  "green": "Зелений",
  "green cyan": "зелено-блакитний",
  "hue": "Тон",
  "light": "світлий",
  "lightness": "Освітленість",
  "magenta": "пурпуровий",
  "magenta pink": "пурпурово-рожевий",
  "orange": "помаранчевий",
  "orange yellow": "помаранчево-жовтий",
  "pale": "блідий",
  "pink": "рожевий",
  "pink red": "рожево-червоний",
  "purple": "фіолетовий",
  "purple magenta": "фіолетово-пурпуровий",
  "red": "Червоний",
  "red orange": "червоно-помаранчевий",
  "saturation": "Насиченість",
  "transparentColorName": "{lightness} {chroma} {hue}, прозорий на {percentTransparent}",
  "very dark": "дуже темний",
  "very light": "дуже світлий",
  "vibrant": "яскравий",
  "white": "білий",
  "yellow": "жовтий",
  "yellow green": "жовто-зелений"
}
