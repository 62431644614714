import React from 'react';

import useUserGeolocation from '../../../actions/myxchngApi/selectors/useUserGeolocation';
import LoadingPage from '../../../components/LoadingPage';

/**
 * @type {React.Context<{geolocation: string | null}>}
 */
export const GeolocationContext = React.createContext({
  geolocation: null,
});

export const GeolocationProvider = ({ children }) => {
  const { data: geolocation, isLoading } = useUserGeolocation();

  if (isLoading) {
    return <LoadingPage />;
  }

  return <GeolocationContext.Provider value={{ geolocation }}>{children}</GeolocationContext.Provider>;
};
