//@ts-check
import { Image } from '@adobe/react-spectrum';
import React from 'react';

import { capitalizeFirstLetter } from '../../utils';


// Access partner badges from environment variable
const partnerBadgesBasePath = (process.env.PARTNER_BADGES || '') + '/partner-badges';

/**
 * @typedef {'v1' | 'v2'} PartnerLevelVersion
 * @typedef {object} BadgeData
 * @property {Record<string, string>} versionBadges
 * @property {import('@adobe/react-spectrum').SpectrumImageProps['height']} height
 */

/** @type {Record<PartnerLevelVersion, BadgeData>} */
const badges = Object.freeze({
  v1: {
    versionBadges: {
      silver: '/silver.svg',
      gold: '/gold.svg',
      platinum: '/platinum.svg',
    },
    height: 'size-300',
  },
  v2: {
    height: 'size-225',
    versionBadges: {
      silver: '/silver-v2.svg',
      gold: '/gold-v2.svg',
      platinum: '/platinum-v2.svg',
      bronze: '/bronze-v2.svg',
    },
  },
});

/** @type { Record<string, string>} */
const partnerTypes = {
  SLN: 'Solution',
  DEFAULT: 'Technology',
};

/** @type {Record<string, PartnerLevelVersion>} */
const badgeVersions = {
  SLN: 'v2',
  DEFAULT: 'v1',
};

/**
 * @typedef {object} PartnerBadgeProps
 * @property {string} [level]
 * @property {PartnerLevelVersion} [version]
 * @property {string} [appType]
 *
 * @param {PartnerBadgeProps & Omit<import('@adobe/react-spectrum').SpectrumImageProps, 'src'>} props
 */
const PartnerBadge = ({ level, appType, version, ...imageProps }) => {
  const lowerCaseLevel = level?.toLowerCase();
  const partnerType = partnerTypes[appType] || partnerTypes.DEFAULT;
  const badgeVersion = version || badgeVersions[appType] || badgeVersions.DEFAULT;

  const { versionBadges, height } = badges[badgeVersion];
  const badge = versionBadges[lowerCaseLevel];

  if (!badge) {
    return null;
  }

  return (
    <Image
      src={`${partnerBadgesBasePath}${badge}`}
      alt={`${capitalizeFirstLetter(level)} ${partnerType} Partner`}
      objectFit="contain"
      height={height}
      {...imageProps}
    />
  );
};

export default PartnerBadge;
