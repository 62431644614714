{
  "alpha": "Alfa",
  "black": "sort",
  "blue": "Blå",
  "blue purple": "blålilla",
  "brightness": "Lysstyrke",
  "brown": "brun",
  "brown yellow": "brungul",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cyan",
  "cyan blue": "cyan blå",
  "dark": "mørk",
  "gray": "grå",
  "grayish": "grålig",
  "green": "Grøn",
  "green cyan": "grøn cyan",
  "hue": "Tone",
  "light": "lys",
  "lightness": "Lyshed",
  "magenta": "magenta",
  "magenta pink": "magenta pink",
  "orange": "orange",
  "orange yellow": "orangegul",
  "pale": "bleg",
  "pink": "lyserød",
  "pink red": "lyserødlig rød",
  "purple": "lilla",
  "purple magenta": "lilla magenta",
  "red": "Rød",
  "red orange": "rødorange",
  "saturation": "Farvemætning",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} gennemsigtig",
  "very dark": "meget mørk",
  "very light": "meget lys",
  "vibrant": "klar",
  "white": "hvid",
  "yellow": "gul",
  "yellow green": "gulgrøn"
}
