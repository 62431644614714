// @ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { ContainerContext } from '../../../utils';
import getEcPublisherInfo from '../actions/get-ec-publisher-info';

/**
 * @param {import('@action-types/ecPublisher').UseEcPublisherInfoOptions} [options]
 * @param {{publisherId: string}} [overrides]
 */
const useEcPublisherInfo = (options, overrides) => {
  const { id } = useParams();
  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;
  const publisherId = overrides?.publisherId || id;
  const { enabled = true, ...queryOptions } = options ?? {};

  const data = useQuery({
    ...queryOptions,
    queryKey: ['get-publisher-info', publisherId],
    enabled: Boolean(publisherId && enabled),
    queryFn: async ({ signal }) => {
      const response = await getEcPublisherInfo({ publisherId, token, signal });
      return response;
    },
  });

  return data;
};

export default useEcPublisherInfo;
