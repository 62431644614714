//@ts-check
import { useMemo } from 'react';

import { useStore } from '../../../../pages/manage/store';
import useManageEcAppsListDetails from '../../../myxchngApi/selectors/useManageEcAppsListDetails';
import useEcAppsListConsents from './useEcAppsListConsents';
import useEnterpriseEntitlements from './useEnterpriseEntitlements';

/**
 * @param {'ABD' | 'S2S'} [appType]
 * @param {import('@tanstack/react-query').UseQueryOptions} [options]
 */
const useEcAppsList = (appType, options) => {
  const orgId = useStore((state) => state.selectedOrg?.code);
  const entitlements = useEnterpriseEntitlements(appType, options);
  const appsDetails = useManageEcAppsListDetails(appType, options);
  const consents = useEcAppsListConsents(appType, options);

  const apps = useMemo(
    () =>
      entitlements.data?.enterpriseEntitlements?.map((entitlement) => {
        const appDetails = appsDetails.data?.cloudApps?.find((app) => app.id === entitlement.appId);
        const consent = consents.data?.consents?.find((consent) => consent.appId === entitlement.appId);
        return { appDetails, entitlement, consent };
      }),
    [entitlements.data, appsDetails.data, consents.data]
  );

  // app details error is not needed because if the name does not load, appId is shown instead
  const error = entitlements.error || consents.error;
  const isLoadingEtitlements = orgId && entitlements.isLoading;
  const isLoadingExtraData = !!apps?.length && (appsDetails.isLoading || consents.isLoading);
  const isLoading = isLoadingEtitlements || isLoadingExtraData;
  const metadata = entitlements.data?._metadata;
  const data = { metadata, apps };

  return { isLoading, data, error };
};

export default useEcAppsList;
