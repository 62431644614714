{
  "alpha": "Альфа",
  "black": "черный",
  "blue": "Синий",
  "blue purple": "сине-фиолетовый",
  "brightness": "Яркость",
  "brown": "коричневый",
  "brown yellow": "коричнево-желтый",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "голубой",
  "cyan blue": "цвет морской волны",
  "dark": "темный",
  "gray": "серый",
  "grayish": "сероватый",
  "green": "Зеленый",
  "green cyan": "сине-зеленый",
  "hue": "Оттенок",
  "light": "светлый",
  "lightness": "Освещенность",
  "magenta": "пурпурный",
  "magenta pink": "пурпурно-розовый",
  "orange": "оранжевый",
  "orange yellow": "оранжево-желтый",
  "pale": "бледный",
  "pink": "розовый",
  "pink red": "розово-красный",
  "purple": "фиолетовый",
  "purple magenta": "фиолетово-пурпурный",
  "red": "Красный",
  "red orange": "красно-оранжевый",
  "saturation": "Насыщенность",
  "transparentColorName": "{lightness} {chroma} {hue}, прозрачный на {percentTransparent}",
  "very dark": "очень темный",
  "very light": "очень светлый",
  "vibrant": "яркий",
  "white": "белый",
  "yellow": "желтый",
  "yellow green": "желто-зеленый"
}
