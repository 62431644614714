.spectrum--lightest .spectrum-Checkbox-label {
  color: rgb(80, 80, 80);
}
.spectrum--lightest .spectrum-Checkbox-box {
  border-color: rgb(149, 149, 149);
  background-color: rgb(255, 255, 255);
}
.spectrum--lightest .spectrum-Checkbox.is-indeterminate .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
  border-color: rgb(38, 128, 235);
}
.spectrum--lightest .spectrum-Checkbox:hover.is-indeterminate .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(20, 115, 230);

  }
.spectrum--lightest .spectrum-Checkbox:active.is-indeterminate .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(13, 102, 208);

  }
.spectrum--lightest .spectrum-Checkbox {
  border-color: rgb(149, 149, 149);
}
.spectrum--lightest .spectrum-Checkbox:hover .spectrum-Checkbox-box {
      border-color: rgb(116, 116, 116);

      box-shadow: none;
    }
.spectrum--lightest .spectrum-Checkbox:hover .spectrum-Checkbox-label {
      color: rgb(50, 50, 50);
    }
.spectrum--lightest .spectrum-Checkbox:active .spectrum-Checkbox-box {
      border-color: rgb(80, 80, 80);

    }
.spectrum--lightest .spectrum-Checkbox:active .spectrum-Checkbox-label {
      color: rgb(50, 50, 50);
    }
.spectrum--lightest .spectrum-Checkbox .spectrum-Checkbox-checkmark,
.spectrum--lightest .spectrum-Checkbox .spectrum-Checkbox-partialCheckmark {
    color: rgb(255, 255, 255);
  }
.spectrum--lightest .spectrum-Checkbox-input:disabled + .spectrum-Checkbox-box {
    border-color: rgb(211, 211, 211) !important;

    background-color: rgb(255, 255, 255);
  }
.spectrum--lightest .spectrum-Checkbox-input:disabled ~ .spectrum-Checkbox-label {
    color: rgb(188, 188, 188);
  }
.spectrum--lightest .spectrum-Checkbox-input.focus-ring + .spectrum-Checkbox-box {
    border-color: rgb(55, 142, 240) !important;

    box-shadow: 0 0 0 1px rgb(55, 142, 240) !important;
  }
.spectrum--lightest .spectrum-Checkbox-input.focus-ring ~ .spectrum-Checkbox-label {
    color: rgb(20, 115, 230) !important;
  }
.spectrum--lightest .spectrum-Checkbox--quiet.is-indeterminate .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox--quiet .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
  border-color: rgb(116, 116, 116);
}
.spectrum--lightest .spectrum-Checkbox--quiet:hover.is-indeterminate .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox--quiet:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(80, 80, 80);
  }
.spectrum--lightest .spectrum-Checkbox--quiet:active.is-indeterminate .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox--quiet:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(50, 50, 50);
  }
.spectrum--lightest .spectrum-Checkbox.is-invalid .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox.is-invalid .spectrum-Checkbox-box {
    border-color: rgb(227, 72, 80);
  }
.spectrum--lightest .spectrum-Checkbox.is-invalid .spectrum-Checkbox-label {
    color: rgb(215, 55, 63);
  }
.spectrum--lightest .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-box {
      border-color: rgb(215, 55, 63);
    }
.spectrum--lightest .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-label {
      color: rgb(201, 37, 45);
    }
.spectrum--lightest .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--lightest .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-box {
      border-color: rgb(201, 37, 45);
    }
.spectrum--lightest .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-label {
      color: rgb(201, 37, 45);
    }
