import { Flex, ProgressCircle } from '@adobe/react-spectrum';
import React from 'react';

const LoadingPage = () => {
  return (
    <Flex width="100%" flexGrow={1} alignItems="center" justifyContent="center">
      <ProgressCircle isIndeterminate size="L" aria-label="Loading page" />
    </Flex>
  );
};

export default LoadingPage;
