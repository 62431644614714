import { ActionButton, Flex, Heading, Text } from '@adobe/react-spectrum';
import React from 'react';

import useConsent from '../../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEcAppDetails from '../../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import useLoadingAndErrorState from '../../../../common/SpProductProfilesDialog/useLoadingAndErrorState';
import { WORKFLOWS } from '../IntegrationDetails';
import useWorkflow from '../useWorkflow';
import ProductProfilesTable from './ProductProfilesTable';

const ProductProfilesTab = () => {
  const { setWorkflow } = useWorkflow();
  const appDetailsQuery = useEcAppDetails();
  const consentQuery = useConsent();
  const appDetails = appDetailsQuery.data;
  const consent = consentQuery.data?.consent;
  const loadingAndErrorState = useLoadingAndErrorState({ appDetails, consent });

  if (loadingAndErrorState) {
    return (
      <Flex width="100%" justifyContent="center" marginTop="size-400">
        {loadingAndErrorState}
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="size-200">
      <Flex gap="size-200" justifyContent="space-between">
        <Heading margin={0}>Product profiles</Heading>
        <ActionButton onPress={() => setWorkflow(WORKFLOWS.PROFILES)}>Edit product profiles</ActionButton>
      </Flex>
      <Text>
        Your integration&apos;s service account will gain access to granular features through the product profiles
        listed here.
      </Text>
      <ProductProfilesTable />
    </Flex>
  );
};

export default ProductProfilesTab;
