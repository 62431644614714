{
  "alpha": "Alfa",
  "black": "melns",
  "blue": "Zila",
  "blue purple": "zili violets",
  "brightness": "Spilgtums",
  "brown": "brūns",
  "brown yellow": "brūni dzeltens",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "ciāns",
  "cyan blue": "ciāna zils",
  "dark": "tumšs",
  "gray": "pelēks",
  "grayish": "pelēcīgs",
  "green": "Zaļa",
  "green cyan": "zaļš ciāns",
  "hue": "Nokrāsa",
  "light": "gaišs",
  "lightness": "Gaišums",
  "magenta": "fuksiju",
  "magenta pink": "fuksiju rozā",
  "orange": "oranžs",
  "orange yellow": "oranži dzeltens",
  "pale": "bāls",
  "pink": "rozā",
  "pink red": "rozīgi sarkans",
  "purple": "violets",
  "purple magenta": "violets fuksiju",
  "red": "Sarkana",
  "red orange": "sarkanīgi oranžs",
  "saturation": "Piesātinājums",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} caurspīdīgs",
  "very dark": "ļoti tumšs",
  "very light": "ļoti gaišs",
  "vibrant": "košs",
  "white": "balts",
  "yellow": "dzeltens",
  "yellow green": "dzelteni zaļš"
}
