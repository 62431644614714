import React from 'react';

/**
 * @param {import('./types').TraderAddressProps} props
 */
export const TraderAddress = ({ address }) => {
  if (!address) return null;

  const englishCountryName = new Intl.DisplayNames(['en'], { type: 'region' });
  const countryName = address.country ? englishCountryName.of(address.country) : null;

  return (
    <>
      {address.line1}
      <br />
      {address.line2}
      {address.line2 && <br />}
      {address.city}, {address.state}
      <br />
      {address.postalCode}
      <br />
      {countryName}
    </>
  );
};
