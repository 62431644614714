//@ts-check
import useEcAppSdkCodes from '../../../../../../actions/consoleApi/selectors/useEcAppSdkCodes';
import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useUserDetails from '../../../../../../actions/jilApi/selectors/useUserDetails';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';

/** @param {import('@tanstack/react-query').UseQueryOptions} [options] */
const useIntegrationDetailsData = (options) => {
  const consentQuery = useConsent(options);
  const appDetailsQuery = useEcAppDetails(options);
  const technicalAccountId = consentQuery.data?.consent?.technicalAccountId;
  const servicesQuery = useEcAppSdkCodes(appDetailsQuery.data, options);
  const services = servicesQuery.data?.services;
  const userQuery = useUserDetails(technicalAccountId, { ...(options ?? {}), enabled: services?.length > 0 });

  return {
    consentQuery,
    appDetailsQuery,
    servicesQuery,
    userQuery,
  };
};

export default useIntegrationDetailsData;
