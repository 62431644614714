{
  "alpha": "Alfa",
  "black": "zwart",
  "blue": "Blauw",
  "blue purple": "paarsblauw",
  "brightness": "Helderheid",
  "brown": "bruin",
  "brown yellow": "bruingeel",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cyaan",
  "cyan blue": "cyaanblauw",
  "dark": "donker",
  "gray": "grijs",
  "grayish": "grijsachtig",
  "green": "Groen",
  "green cyan": "cyaangroen",
  "hue": "Kleurtoon",
  "light": "licht",
  "lightness": "Lichtsterkte",
  "magenta": "magenta",
  "magenta pink": "magentaroze",
  "orange": "oranje",
  "orange yellow": "oranjegeel",
  "pale": "bleek",
  "pink": "roze",
  "pink red": "rozerood",
  "purple": "paars",
  "purple magenta": "magentapaars",
  "red": "Rood",
  "red orange": "roodoranje",
  "saturation": "Verzadiging",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} transparant",
  "very dark": "heel donker",
  "very light": "heel licht",
  "vibrant": "levendig",
  "white": "wit",
  "yellow": "geel",
  "yellow green": "geelgroen"
}
