//@ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { ContainerContext } from '../../../utils';
import searchEcApps from '../actions/searchEcApps';

/** @param {import('@tanstack/react-query').UseQueryOptions} [options] */
const useEcAppDetails = (options) => {
  const { appId } = useParams();

  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/ecApp').EcAppDetailsQueryState} */
  const data = useQuery({
    ...(options ?? {}),
    queryKey: ['get-ec-app-details', appId, token],
    enabled: Boolean(appId && token),
    queryFn: async ({ signal }) => {
      const response = await searchEcApps({
        appIds: [appId],
        token: /** @type {string} */ (token),
        signal,
        type: 'details',
      });
      return response.cloudApps[0] || null;
    },
  });

  return data;
};

export default useEcAppDetails;
