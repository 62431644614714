{
  "alpha": "Alpha",
  "black": "Noir",
  "blue": "Bleu",
  "blue purple": "Violet bleu",
  "brightness": "Luminosité",
  "brown": "Brun",
  "brown yellow": "Jaune brun",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "Cyan",
  "cyan blue": "Bleu cyan",
  "dark": "Sombre",
  "gray": "Gris",
  "grayish": "Grisâtre",
  "green": "Vert",
  "green cyan": "Cyan vert",
  "hue": "Teinte",
  "light": "Clair",
  "lightness": "Luminosité",
  "magenta": "Magenta",
  "magenta pink": "Rose magenta",
  "orange": "Orange",
  "orange yellow": "Jaune orangé",
  "pale": "Pâle",
  "pink": "Rose",
  "pink red": "Rouge rosé",
  "purple": "Violet",
  "purple magenta": "Magenta violet",
  "red": "Rouge",
  "red orange": "Orange rouge",
  "saturation": "Saturation",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} transparent",
  "very dark": "Très sombre",
  "very light": "Très clair",
  "vibrant": "Vif",
  "white": "Blanc",
  "yellow": "Jaune",
  "yellow green": "Vert jaune"
}
