//@ts-check
import { Content, Dialog, DialogTrigger, Flex, Link, StatusLight, Text } from '@adobe/react-spectrum';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, generatePath } from 'react-router-dom';

import { useStaticData } from '@exchange-frontends/custom-hooks';

import { ROUTES } from '../../../../../../constants';
import { formatDate } from '../../../../../../utils';
import { EllipsisText } from '../../../../../components/styled-components/EllipsisText';
import { useStore } from '../../../../store';
import { TABS_DEFINITION } from '../../../../utils/TabsDefinition';
import IntegrationActionMenu from '../../../common/IntegrationActionMenu';

/** @type {Record<import('@action-types/consent').Consent['status'], import('./types/integrationsTable').ConsentStatusDetails>} */
export const consentStatusMap = Object.freeze({
  ALLOWED: { label: 'Access granted', variant: 'positive' },
  DENIED: { label: 'Access denied', variant: 'negative' },
  REQUESTED: { label: 'Pending access', variant: 'notice' },
  REVOKED: { label: 'Revoked', variant: 'negative' },
});

/** @type {import('./types/integrationsTable').Column[]} */
const columns = [
  {
    label: 'Application',
    minWidth: 100,
    key: 'name',
    CellContent: ({ data }) => {
      const orgId = useStore((state) => state.selectedOrg?.code);
      const appId = data.entitlement.appId;
      const appDetailsPath = generatePath(`${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATION_DETAILS.path}`, {
        orgId,
        appId,
      });

      return (
        <Link isQuiet>
          <RouterLink to={appDetailsPath}>{data.appDetails?.name || data.entitlement.appId}</RouterLink>
        </Link>
      );
    },
  },
  {
    label: 'Status',
    key: 'status',
    width: 138,
    CellContent: ({ data }) => {
      const statusData = consentStatusMap[data.consent?.status ?? ''];
      if (!statusData) {
        return null;
      } else {
        return (
          <StatusLight variant={statusData.variant} marginStart="-12px">
            {statusData.label}
          </StatusLight>
        );
      }
    },
  },
  {
    label: 'Date acquired',
    allowsSorting: true,
    key: 'CREATED',
    width: 150,
    CellContent: ({ data }) => <Text>{formatDate(data.entitlement.createdOn)}</Text>,
  },
  {
    label: 'Products',
    key: 'products',
    minWidth: 180,
    CellContent: ({ data }) => {
      /** @type {React.RefObject<HTMLDivElement>} */
      const textRef = useRef(null);
      const [isEllipsisShown, setIsEllipsisShown] = useState(false);
      const productsMap = /** @type {Map<string, { label: string }> | null} */ (useStaticData('hostApps'));

      const products = useMemo(
        () =>
          data.appDetails?.hostApps
            ?.concat(data.appDetails.optionalProducts || [])
            .map((app) => productsMap?.get(app)?.label || app)
            .sort((a, b) => a.localeCompare(b)) || [],
        [data.appDetails, productsMap]
      );
      const fullText = products.join(', ');

      useEffect(() => {
        const checkEllipsis = () => {
          if (!textRef.current) return;
          setIsEllipsisShown(textRef.current.scrollWidth > textRef.current.offsetWidth);
        };
        checkEllipsis();
        window.addEventListener('resize', checkEllipsis);
        return () => {
          window.removeEventListener('resize', checkEllipsis);
        };
      }, [fullText]);

      const hasOnlyOneProduct = products.length === 1;
      const shouldTextBeALink = hasOnlyOneProduct && isEllipsisShown;
      const text = <EllipsisText ref={textRef}>{products[0]}</EllipsisText>;

      return (
        <Flex gap="size-50">
          {!shouldTextBeALink && text}
          {(shouldTextBeALink || products.length > 1) && (
            <DialogTrigger type="popover">
              <Link isQuiet variant={shouldTextBeALink ? 'secondary' : 'primary'}>
                {shouldTextBeALink ? text : `+${products.length - 1} more`}
              </Link>
              <Dialog width="min-content" maxWidth="500px">
                <Content>
                  <Flex height="100%" alignItems="center">
                    <Text>{fullText}</Text>
                  </Flex>
                </Content>
              </Dialog>
            </DialogTrigger>
          )}
        </Flex>
      );
    },
  },
  {
    label: 'Actions',
    key: 'actions',
    align: 'end',
    width: 236,
    CellContent: IntegrationActionMenu,
  },
];

export default columns;
