//@ts-check
import { Link, View } from '@adobe/react-spectrum';
import React from 'react';

import { TEST_IDS, WORKFLOWS } from '../IntegrationDetails';
import useWorkflow from '../useWorkflow';

const NoProductProfilesView = () => {
  const { setWorkflow } = useWorkflow();
  const onAddProductProfile = () => setWorkflow(WORKFLOWS.PROFILES);

  return (
    <View data-testid={TEST_IDS.NO_PRODUCT_PROFILES_SELECTED} backgroundColor="static-white" padding="size-200">
      No product profiles selected. <Link onPress={onAddProductProfile}>Add a product profile here.</Link>
    </View>
  );
};

export default NoProductProfilesView;
