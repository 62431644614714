//@ts-check
import { ActionButton, Flex, Text, View } from '@adobe/react-spectrum';
import Copy from '@spectrum-icons/workflow/Copy';
import React from 'react';

import { copyText } from '../../../../../../../utils';
import { EllipsisText } from '../../../../../../components/styled-components/EllipsisText';
import { TEST_IDS } from '../IntegrationDetails';

/**
 * @param {object} props
 * @param {string} props.techAccountId
 */
const TechAccountSection = ({ techAccountId }) => {
  return (
    <Flex direction="column" gap="size-100" data-testid={TEST_IDS.TECH_ACCOUNT_SECTION}>
      <strong>Technical account</strong>
      <Text>
        This technical account was set up on your organization&apos;s behalf upon providing access in the generate
        access token browser based workflow. It serves as a connection between this application and your
        organization&apos;s Adobe account.{' '}
      </Text>
      <View
        backgroundColor="static-white"
        borderWidth="thin"
        borderRadius="regular"
        borderColor="gray-300"
        paddingX="size-200"
        paddingY="size-100"
        marginTop="size-50"
      >
        <Flex wrap rowGap="size-100" columnGap="size-500" alignItems="center">
          <Text>Technical account ID</Text>
          <Flex flexGrow={1} gap={{ base: 'size-100', L: 'size-500' }} alignItems="center" maxWidth="100%">
            <Text flexGrow={1} maxWidth={{ base: 'calc(100% - 42px)', L: 'calc(100% - 76px)' }}>
              <EllipsisText>{techAccountId}</EllipsisText>
            </Text>
            <ActionButton aria-label="Copy technical account ID" onPress={() => copyText(techAccountId)}>
              <Copy size="S" />
            </ActionButton>
          </Flex>
        </Flex>
      </View>
    </Flex>
  );
};

export default TechAccountSection;
