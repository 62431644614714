// @ts-check
import { useContext } from 'react';

import { ContainerContext } from '../../../utils';
import useEcPublisherInfo from './useEcPublisherInfo';

const useUserGeolocation = () => {
  const { ims } = useContext(ContainerContext);

  const queryResult = useEcPublisherInfo(
    {
      enabled: ims?.initialized ?? false,
    },
    { publisherId: '0011400001vdyqkAAA' }
  );

  return { ...queryResult, data: queryResult.data?.userLocation ?? undefined };
};

export default useUserGeolocation;
