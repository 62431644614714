{
  "alpha": "Alfa",
  "black": "juoda",
  "blue": "Mėlyna",
  "blue purple": "melsvai violetinė",
  "brightness": "Ryškumas",
  "brown": "ruda",
  "brown yellow": "rusvai geltona",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "žalsvai mėlyna",
  "cyan blue": "žalsvai mėlyna",
  "dark": "tamsi",
  "gray": "pilka",
  "grayish": "pilkšva",
  "green": "Žalia",
  "green cyan": "žalsvai mėlyna",
  "hue": "Atspalvis",
  "light": "šviesi",
  "lightness": "Šviesumas",
  "magenta": "rausvai raudona",
  "magenta pink": "purpurinė",
  "orange": "oranžinė",
  "orange yellow": "oranžinio atspalvio geltona",
  "pale": "blyški",
  "pink": "rožinė",
  "pink red": "rožinė raudona",
  "purple": "violetinė",
  "purple magenta": "purpurinė rausvai raudona",
  "red": "Raudona",
  "red orange": "rausvai oranžinė",
  "saturation": "Įsotinimas",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} skaidri",
  "very dark": "labai tamsi",
  "very light": "labai šviesi",
  "vibrant": "ryški",
  "white": "balta",
  "yellow": "geltona",
  "yellow green": "gelsvai žalia"
}
