{
  "alpha": "Alfa",
  "black": "negru",
  "blue": "Albastru",
  "blue purple": "albastru-violet",
  "brightness": "Luminozitate",
  "brown": "maro",
  "brown yellow": "galben maro",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "bleu",
  "cyan blue": "albastru-bleu",
  "dark": "închis",
  "gray": "gri",
  "grayish": "cenușiu",
  "green": "Verde",
  "green cyan": "verde bleu",
  "hue": "Nuanță",
  "light": "deschis",
  "lightness": "Luminozitate",
  "magenta": "fucsia",
  "magenta pink": "roz-fucsia",
  "orange": "portocaliu",
  "orange yellow": "galben-portocaliu",
  "pale": "pal",
  "pink": "roz",
  "pink red": "roz-roșu",
  "purple": "violet",
  "purple magenta": "violet-fucsia",
  "red": "Roșu",
  "red orange": "portocaliu-roșu",
  "saturation": "Saturație",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} transparent",
  "very dark": "foarte închis",
  "very light": "foarte deschis",
  "vibrant": "plin de viață",
  "white": "alb",
  "yellow": "galben",
  "yellow green": "galben-verde"
}
