//@ts-check
import { jilApiClient } from '../../utils/RequestClient';

/**
 * @param {object} params
 * @param {string} params.orgId
 * @param {string} params.userId
 * @param {string} params.token
 * @param {import('@action-types/user').PatchProductProfilesMutationParams['productProfiles']} params.productProfiles
 * @returns {Promise<import('@action-types/user').User>}
 * @throws {import('@action-types/RequestError').RequestError<import('@action-types/user').JilApiErrorResponse>}
 */
const patchProductProfiles = async ({ token, orgId, userId, productProfiles }) => {
  const { jsonResponse } = await jilApiClient.fetch({
    endpoint: `/organizations/${orgId}/users`,
    requestOptions: {
      method: 'PATCH',
      headers: {
        authorization: `Bearer ${token}`,
        accept: 'application/json',
        'content-type': 'application/json',
      },
      body: JSON.stringify(
        productProfiles.map(({ productId, licenseGroupId, operation }) => ({
          op: operation,
          path: `/${userId}/products/${productId}/licenseGroups/${licenseGroupId}`,
        }))
      ),
    },
  });

  return jsonResponse;
};

export default patchProductProfiles;
