//@ts-check
import { Content, Flex, Heading, IllustratedMessage, InlineAlert, View } from '@adobe/react-spectrum';
import Error500 from '@spectrum-icons/illustrations/Error';
import React from 'react';

import BoundaryTryAgainLink from '../../components/BoundaryTryAgainLink';

/**
 * @typedef {object} Props
 * @property {string} [sectionName]
 * @property {boolean} [shouldTryAgain=true]
 * @property {"illustration" | 'inlineAlert'} [variant]
 */

/** @param {Props & Omit<import('@adobe/react-spectrum').FlexProps,'children'>} props */
const ErrorStateFallback = ({ sectionName, variant = 'illustration', shouldTryAgain = true, ...containerProps }) => {
  const heading = <Heading>Something went wrong{sectionName && ` while loading ${sectionName}`}.</Heading>;
  const content = <Content>Please {shouldTryAgain ? <BoundaryTryAgainLink /> : 'try again'} in a few minutes.</Content>;

  return (
    <Flex {...containerProps}>
      {variant === 'inlineAlert' ? (
        <View width="100%">
          <InlineAlert variant="notice" width="100%">
            {heading}
            {content}
          </InlineAlert>
        </View>
      ) : (
        <IllustratedMessage>
          <Error500 />
          {heading}
          {content}
        </IllustratedMessage>
      )}
    </Flex>
  );
};

export default ErrorStateFallback;
