import { Button, DialogTrigger, Flex, Text } from '@adobe/react-spectrum';
import p from 'prop-types';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from '../../../../store';
import { CONSENT_STATUS_TO_APP_STATUS } from '../../../../utils/Constants';
import { UserDataContext } from '../../../App';
import ReviewDialog from '../ReviewDialog';
import RevokeDialog from '../RevokeDialog';
import { NonAdminNote } from '../ec/common/StyledComponents';

const getButtonVariant = (status) => {
  switch (status?.toLowerCase()) {
    case 'published':
      return 'negative';
    default:
      return 'primary';
  }
};

const getButtonLabel = (status) => {
  switch (status?.toLowerCase()) {
    case 'published':
      return 'Revoke';
    case 'pending':
      return 'Review';
    case CONSENT_STATUS_TO_APP_STATUS.DENIED:
      return 'Request again';
    default:
      return;
  }
};
/**
 * Button used for EC apps on the Manage Card.
 * @returns
 */
const ECCardButton = (props) => {
  const { isReviewButton, loading, app, appBuilderExtensionPoints, isAdmin, redirectHandler = false } = props;
  const buttonText = getButtonLabel(app?.status);
  const Dialog = isReviewButton ? ReviewDialog : RevokeDialog;
  const updateConsent = useStore((state) => state.updateConsent);
  const { orgId } = useParams();
  const { accessToken } = useContext(UserDataContext);
  const [isRequestAgainInProgress, setIsRequestAgainInProgress] = useState(false);
  const requestAgain = () => {
    setIsRequestAgainInProgress(true);
    updateConsent(accessToken, orgId, app.id, app.appVersionId, 'REQUEST', close).then((response) => {
      if (response.ok && redirectHandler) {
        redirectHandler();
      }
      setIsRequestAgainInProgress(false);
    });
  };

  /** used width: "max-content" to fix the button width issue for Request again case */
  return (
    <Flex justifyContent="flex-start" UNSAFE_style={{ width: 'max-content' }}>
      {!app?.jaeger && app?.status === 'rejected' ? (
        <Button
          variant={getButtonVariant(app?.status)}
          isDisabled={loading || isRequestAgainInProgress}
          onPress={() => requestAgain()}
          data-launchid={buttonText}
          data-testid={props['data-testid']}
        >
          <Text>{buttonText}</Text>
        </Button>
      ) : (
        <DialogTrigger>
          <Button
            variant={getButtonVariant(app?.status)}
            isDisabled={loading || !isAdmin}
            data-launchid={buttonText}
            data-testid={props['data-testid']}
          >
            <Text>{buttonText}</Text>
          </Button>
          {(close) => (
            <Dialog
              close={close}
              app={app}
              appBuilderExtensionPoints={appBuilderExtensionPoints}
              redirectHandler={redirectHandler}
            />
          )}
        </DialogTrigger>
      )}
      {!isAdmin && app?.status !== CONSENT_STATUS_TO_APP_STATUS.DENIED && <NonAdminNote />}
    </Flex>
  );
};

ECCardButton.propTypes = {
  isReviewButton: p.bool.isRequired,
  loading: p.bool.isRequired,
  app: p.object,
  appBuilderExtensionPoints: p.instanceOf(Map),
};

ECCardButton.defaultProps = {
  isReviewButton: false,
  loading: false,
};

export default ECCardButton;
