{
  "alpha": "ألفا",
  "black": "أسود",
  "blue": "أزرق",
  "blue purple": "أرجواني مزرق",
  "brightness": "السطوع",
  "brown": "بني",
  "brown yellow": "أصفر بني",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "سماوي",
  "cyan blue": "أزرق سماوي",
  "dark": "داكن",
  "gray": "رمادي",
  "grayish": "مائل للرمادي",
  "green": "أخضر",
  "green cyan": "سماوي مخضر",
  "hue": "درجة اللون",
  "light": "فاتح",
  "lightness": "الإضاءة",
  "magenta": "أرجواني",
  "magenta pink": "أرجواني وردي",
  "orange": "برتقالي",
  "orange yellow": "أصفر برتقالي",
  "pale": "باهت",
  "pink": "وردي",
  "pink red": "أحمر وردي",
  "purple": "أرجواني",
  "purple magenta": "بنفسجي",
  "red": "أحمر",
  "red orange": "برتقالي محمر",
  "saturation": "التشبع",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} شفاف",
  "very dark": "داكن جدًا",
  "very light": "فاتح جدًا",
  "vibrant": "ساطع",
  "white": "أبيض",
  "yellow": "أصفر",
  "yellow green": "أخضر مصفر"
}
