{
  "alpha": "Alfa",
  "black": "svart",
  "blue": "Blått",
  "blue purple": "blålila",
  "brightness": "Ljusstyrka",
  "brown": "brun",
  "brown yellow": "brungul",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cyan",
  "cyan blue": "cyanblå",
  "dark": "mörk",
  "gray": "grå",
  "grayish": "gråaktig",
  "green": "Grönt",
  "green cyan": "grön cyan",
  "hue": "Nyans",
  "light": "ljus",
  "lightness": "Ljushet",
  "magenta": "magenta",
  "magenta pink": "magentarosa",
  "orange": "orange",
  "orange yellow": "orangegul",
  "pale": "blek",
  "pink": "rosa",
  "pink red": "rosaröd",
  "purple": "lila",
  "purple magenta": "lila magenta",
  "red": "Rött",
  "red orange": "rödorange",
  "saturation": "Mättnad",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} genomskinlig",
  "very dark": "mycket mörk",
  "very light": "mycket ljus",
  "vibrant": "livfull",
  "white": "vit",
  "yellow": "gul",
  "yellow green": "gulgrön"
}
