{
  "alpha": "Alfa",
  "black": "črna",
  "blue": "Modra",
  "blue purple": "modro vijolična",
  "brightness": "Svetlost",
  "brown": "rjava",
  "brown yellow": "rjavo rumena",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cian",
  "cyan blue": "cian modra",
  "dark": "temna",
  "gray": "siva",
  "grayish": "sivkasta",
  "green": "Zelena",
  "green cyan": "zelena cian",
  "hue": "Barva",
  "light": "svetla",
  "lightness": "Lahkost",
  "magenta": "škrlatna",
  "magenta pink": "škrlatno roza",
  "orange": "oranžna",
  "orange yellow": "oranžno rumena",
  "pale": "bleda",
  "pink": "roza",
  "pink red": "roza rdeča",
  "purple": "vijolična",
  "purple magenta": "vijolično škrlatna",
  "red": "Rdeča",
  "red orange": "rdeče oranžna",
  "saturation": "Nasičenost",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} prozorna",
  "very dark": "zelo temna",
  "very light": "zelo svetla",
  "vibrant": "živahna",
  "white": "bela",
  "yellow": "rumena",
  "yellow green": "rumeno zelena"
}
