{
  "alpha": "Алфа",
  "black": "черно",
  "blue": "Синьо",
  "blue purple": "синьо лилаво",
  "brightness": "Яркост",
  "brown": "кафяво",
  "brown yellow": "кафяво жълто",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "циан",
  "cyan blue": "циан синьо",
  "dark": "тъмно",
  "gray": "сиво",
  "grayish": "сивкав",
  "green": "Зелено",
  "green cyan": "зелен циан",
  "hue": "Оттенък",
  "light": "светло",
  "lightness": "Лекота",
  "magenta": "магента",
  "magenta pink": "магента розово",
  "orange": "оранжево",
  "orange yellow": "оранжево жълто",
  "pale": "бледо",
  "pink": "розово",
  "pink red": "розово червено",
  "purple": "лилаво",
  "purple magenta": "лилаво магента",
  "red": "Червено",
  "red orange": "червен портокал",
  "saturation": "Наситеност",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} прозрачен",
  "very dark": "много тъмно",
  "very light": "много светло",
  "vibrant": " ярко",
  "white": "бяло",
  "yellow": "жълто",
  "yellow green": "жълто зелен"
}
