//@ts-check
import React, { createContext } from 'react';

import useProductProfilesData from './useProductProfilesData';
import useSaveProductProfiles from './useSaveProductProfiles';

/**
 * @typedef {object} ProductProfilesContext
 * @property {import('./types').SelectedProductProfile[]} selectedProductProfiles
 * @property {import('./types').SelectedProductProfile[]} originalSelectedProductProfiles
 * @property {React.Dispatch<import('./types').SelectedProductProfile>} setSelectedProductProfiles
 * @property {import('@action-types/user').PatchProductProfilesMutationParams['productProfiles']} editedProductProfiles
 * @property {() => Promise<void>} saveProductProfiles
 * @property {import('@action-types/user').PatchProductProfilesMutationState} editProfilesMutation
 * @property {boolean} [refetchingSelectedProfiles]
 */

/** @type {React.Context<Partial<ProductProfilesContext & import('../../tabs/apps-list/IntegrationsList/types/integrationsTable').CellContentProps['data']>>} */
export const ProductProfilesContext = createContext({});

/** @param {import('../../tabs/apps-list/IntegrationsList/types/integrationsTable').CellContentProps & React.PropsWithChildren} props */
const ProductProfilesProvider = (props) => {
  const technicalAccountId = props.data.consent?.technicalAccountId;
  const productProfilesData = useProductProfilesData(technicalAccountId);
  const saveProfilesData = useSaveProductProfiles(technicalAccountId, productProfilesData.editedProductProfiles);

  return (
    <ProductProfilesContext.Provider
      value={{
        ...productProfilesData,
        ...saveProfilesData,
        ...props.data,
      }}
    >
      {props.children}
    </ProductProfilesContext.Provider>
  );
};

export default ProductProfilesProvider;
