// @ts-check
import { ON_IMSLIB_INSTANCE } from '@identity/imslib/constants/ImsConstants';
import { useEffect } from 'react';

import { ccextApiClient } from '../actions/utils/RequestClient';

/**
 * @typedef {import('@identity/imslib').AdobeIMS} AdobeIMS
 *
 * This hook is used to authenticate the ccextApiClient
 */
export const useAuthenticatedCcextClient = () => {
  useEffect(() => {
    window.addEventListener(
      ON_IMSLIB_INSTANCE,
      (/** @type {CustomEvent<{instance: AdobeIMS; clientId: string}>} */ event) => {
        const ims = event?.detail?.instance;
        if (ims && ims.isSignedInUser()) {
          ccextApiClient.URL = `${process.env.CCES_ENDPOINT}/v3`;
          ccextApiClient.setCommonHeader('authorization', `Bearer ${ims?.getAccessToken()?.token}`);
        }
      }
    );
  }, []);
};
