//@ts-check
import {
  Cell,
  Column,
  Flex,
  ProgressCircle,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
} from '@adobe/react-spectrum';
import React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';

import { Pagination } from '@exchange-frontends/components';

import useEcAppsList from '../../../../../../actions/entitlementApi/selectors/ecAppsList/useEcAppsList';
import { ROUTES } from '../../../../../../constants';
import ErrorStateFallback from '../../../../../components/ErrorStateFallback';
import { NotFoundMessage } from '../../../../../components/NotFoundMessage';
import { useStore } from '../../../../store';
import columns from './integrationsTableColumns';

export const TEST_IDS = {
  APP_COUNT_TEXT: 'APP_COUNT_TEXT',
};

const IntegrationsTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orgName = useStore((state) => state.selectedOrg?.name);
  const { isLoading, error, data } = useEcAppsList('S2S', { refetchOnMount: true });
  const { apps, metadata } = data;

  if (isLoading) {
    return (
      <Flex justifyContent="center" flexGrow={1} alignItems="center" marginBottom="size-500">
        <ProgressCircle isIndeterminate aria-label="Loading apps list" size="L" />
      </Flex>
    );
  }

  if (error) {
    throw error.originalError;
  }

  if (!apps?.length) {
    return (
      <NotFoundMessage
        headingProps={{ maxWidth: '600px' }}
        heading="No server-to-server integrations to manage"
        contentProps={{ marginTop: 'size-150' }}
        content={[
          "If you recall acquiring a service integration successfully, it might not be showing here because you are currently signed in with a different organization. Try signing out and then signing back in with the organization you had used to acquire the application, and navigating to the option 'Manage' from the navigation bar at the top.",
          'Note that only enterprise organizations can acquire, view and manage service integrations currently.',
        ]}
        link={{
          text: 'Explore hundreds of Experience Cloud service integrations and App Builder applications',
          href: ROUTES.BROWSE + '/ec',
        }}
        linkProps={{ marginTop: 'size-150' }}
      />
    );
  }

  const currentPage = searchParams.get('page') || '1';
  const order = searchParams.get('order') === 'ascending' ? 'ascending' : 'descending';
  const sort = searchParams.get('sort') || 'CREATED';

  const totalCount = metadata?.totalCount;
  const pageSize = metadata?.pageSize;
  const totalPages = totalCount && pageSize && Math.ceil(totalCount / pageSize);
  const shouldShowPagination = !isLoading && totalPages && totalPages > 1;

  return (
    <>
      <Text marginBottom="size-200" data-testid={TEST_IDS.APP_COUNT_TEXT}>
        <strong>{totalCount}</strong>
        &nbsp;apps
        {orgName && (
          <>
            &nbsp;in
            <strong> {orgName}</strong>
          </>
        )}
      </Text>
      <TableView
        aria-label="Integrations Table"
        density="spacious"
        sortDescriptor={{ column: sort, direction: order }}
        onSortChange={({ column, direction }) =>
          setSearchParams({ sort: column?.toString() || '', order: direction || '' })
        }
      >
        <TableHeader columns={columns}>
          {({ label, ...columnProps }) => <Column {...columnProps}>{label}</Column>}
        </TableHeader>
        <TableBody items={apps}>
          {(rowData) => (
            <Row key={rowData.entitlement.appId}>
              {columns.map(({ key: columnKey, CellContent }) => (
                <Cell key={`${rowData.entitlement.appId}-${columnKey}`}>
                  <CellContent data={rowData} />
                </Cell>
              ))}
            </Row>
          )}
        </TableBody>
      </TableView>
      {shouldShowPagination && (
        <Pagination
          enablePageNumbers={true}
          defaultPage={Number(currentPage)}
          totalPages={totalPages}
          onChange={(page) => setSearchParams({ page })}
        />
      )}
    </>
  );
};

export default withErrorBoundary(IntegrationsTable, {
  FallbackComponent: () => (
    <ErrorStateFallback
      sectionName="integrations"
      justifyContent="center"
      alignItems="center"
      marginBottom="size-500"
      flexGrow={1}
    />
  ),
  onError: console.error,
});
