//@ts-check
import { myxchngApiClient } from '../../utils/RequestClient';
import RequestSearchParams from '../../utils/RequestSearchParams';

const acceptHeaderMap = Object.freeze({
  card: 'application/vnd.adobe.xt.app-card-v1+json',
  details: 'application/vnd.adobe.xt.app-detail-v2+json',
});

/**
 *
 * @param {object} params
 * @param {string[]} [params.appIds]
 * @param {string} [params.token]
 * @param {keyof typeof acceptHeaderMap} [params.type]
 * @param {AbortSignal} [params.signal]
 * @param {number | string} [params.pageSize]
 * @returns {Promise<import('@action-types/ecApp').AppCardResponse | import('@action-types/ecApp').AppDetailsResponse>}
 * @throws {import('@action-types/RequestError').RequestError<import('@action-types/ecApp').MyxchngApiErrorResponse>}
 */
const searchEcApps = async ({ signal, appIds, token, type = 'card', ...params }) => {
  const appId = appIds?.join(',');
  const pageSize = params.pageSize || appIds?.length;
  const searchParams = new RequestSearchParams({ appId, pageSize });
  const headers = new Headers({ accept: acceptHeaderMap[type] });
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }
  const { jsonResponse } = await myxchngApiClient.fetch({
    endpoint: `/apps${searchParams}`,
    requestOptions: {
      signal,
      headers,
    },
  });
  return jsonResponse;
};

export default searchEcApps;
