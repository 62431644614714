//@ts-check
/** URLSearchParams that filters out undefined values from the params object passed to the constructor */
class RequestSearchParams extends URLSearchParams {
  /** @param {Record<string, undefined | string | number>} params */
  constructor(params) {
    /** @type {Record<string, string>} */
    const filteredParams = Object.keys(params || {}).reduce((acc, key) => {
      const value = params[key];
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});

    super(filteredParams);
  }

  toString() {
    const searchString = super.toString();
    return searchString.length ? `?${searchString}` : '';
  }
}

export default RequestSearchParams;
