//@ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from '../../../../pages/manage/store';
import { ContainerContext } from '../../../../utils';
import getConsent from '../../actions/get-consent';

const selectedOrgSelector = (state) => state.selectedOrg?.code;

/**
 * @param {import('@action-types/consent').EcConsentQueryOptions} [options]
 * @param {object} [params]
 * @param {string} [params.appId]
 * @param {string} [params.orgId]
 */
const useConsent = (options = {}, params) => {
  /** @type {string | undefined} */
  const storeOrgId = useStore(selectedOrgSelector);
  const routeParams = useParams();
  const orgId = params?.orgId ?? storeOrgId;
  const appId = params?.appId ?? routeParams.appId;
  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/consent').EcConsentQueryState} */
  const data = useQuery({
    ...options,
    queryKey: ['get-consent', orgId, token, appId],
    enabled: Boolean(token && orgId && appId && (options.enabled ?? true)),
    queryFn: async ({ signal }) => {
      const response = await getConsent({
        signal,
        token: /** @type {string} */ (token),
        orgId: /** @type {string} */ (orgId),
        appIds: [appId],
      });
      const { consents, ...rest } = response ?? {};
      return { consent: consents?.[0], ...rest };
    },
  });

  return data;
};

export default useConsent;
