{
  "alpha": "אלפא",
  "black": "שחור",
  "blue": "כחול",
  "blue purple": "כחול סגול",
  "brightness": "בהירות",
  "brown": "חום",
  "brown yellow": "חום צהוב",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "טורקיז",
  "cyan blue": "כחול ציאן",
  "dark": "כהה",
  "gray": "אפור",
  "grayish": "אפרפר",
  "green": "ירוק",
  "green cyan": "ציאן ירוק",
  "hue": "גוון",
  "light": "אור",
  "lightness": "כמות אור",
  "magenta": "מג'נטה",
  "magenta pink": "ורוד מג'נטה",
  "orange": "כתום",
  "orange yellow": "כתום צהוב",
  "pale": "חיוור",
  "pink": "ורוד",
  "pink red": "ורוד אדום",
  "purple": "סגול",
  "purple magenta": "מג'נטה סגול",
  "red": "אדום",
  "red orange": "כתום אדום",
  "saturation": "רוויה",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} שקוף",
  "very dark": "כהה מאוד",
  "very light": "בהיר מאוד",
  "vibrant": "תוסס",
  "white": "לבן",
  "yellow": "צהוב",
  "yellow green": "צהוב ירוק"
}
