//@ts-check
import { Flex, Item, ListBox, Section } from '@adobe/react-spectrum';
import React, { useContext, useState } from 'react';

import useEcAppSdkCodes from '../../../../../actions/consoleApi/selectors/useEcAppSdkCodes';
import { ProductProfilesContext } from './ProductProfilesProvider';
import ProductProfilesTable from './ProductProfilesTable';

const ServicesList = () => {
  const { appDetails } = useContext(ProductProfilesContext);
  const servicesQuery = useEcAppSdkCodes(appDetails);
  const services = servicesQuery.data?.services;
  /** @type {ReturnType<typeof useState<import('@adobe/react-spectrum').Selection>>} */
  const [selectedServices, setSelectedServices] = useState(new Set([services[0].id.toString()]));
  const selectedService = services.find((service) => selectedServices.has(service.id.toString()));

  /** @param {import('@adobe/react-spectrum').Selection} selection */
  const onSelectionChange = (selection) => {
    if (selection === 'all' || !selection.size) return;
    setSelectedServices(selection);
  };

  return (
    <Flex direction={{ base: 'column', M: 'row' }} gap={{ base: 'size-200', L: 'size-700' }} width="100%">
      <ListBox
        flexGrow={1}
        maxWidth={{ M: '260px' }}
        position="sticky"
        aria-label="List of services"
        selectionMode="single"
        selectedKeys={selectedServices}
        onSelectionChange={onSelectionChange}
      >
        <Section title="API" items={services}>
          {(service) => <Item key={service?.id}>{service?.name}</Item>}
        </Section>
      </ListBox>
      {selectedService && <ProductProfilesTable key={selectedService.id} selectedService={selectedService} />}
    </Flex>
  );
};

export default ServicesList;
