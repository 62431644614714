import { Flex, Heading, Text, View } from '@adobe/react-spectrum';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import Info from '@spectrum-icons/workflow/Info';
import parsePhoneNumber from 'libphonenumber-js';
import React, { useState } from 'react';
import styled from 'styled-components';

import { TraderAddress } from './TraderAddress';
import { NO_DETAILS_MESSAGE } from './constants';

const FlexButton = styled.div`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

/**
 * @param {import('./types').TraderInformationProps['contactDetails']['phone']} phone
 */
const getFormattedPhoneNumber = (phone) => {
  if (!phone || !phone.number) {
    return null;
  }

  let phoneNumber = phone.number;

  if (phone.countryCode) {
    phoneNumber = phone.countryCode + phoneNumber;

    const parsedNumber = parsePhoneNumber(phoneNumber, phone.country);

    if (parsedNumber && parsedNumber.isValid()) {
      phoneNumber = parsedNumber.formatInternational();
    } else {
      phoneNumber = phoneNumber.replace(/^(\+?)/, '+');
    }
  }

  return phoneNumber;
};

/**
 * @param {import('./types').TraderInformationProps} props
 */
const TraderInformation = ({ contactDetails, noDetailsMessage = NO_DETAILS_MESSAGE }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ChevronIcon = isOpen ? ChevronDown : ChevronRight;

  const getTraderData = (contactDetails) => {
    const { email, phone, address } = contactDetails ?? {};

    const formattedPhone = getFormattedPhoneNumber(phone);

    const items = [
      {
        label: 'Email',
        value: email,
      },
      {
        label: 'Telephone',
        value: formattedPhone,
      },
      {
        label: 'Address',
        value: <TraderAddress address={address} />,
      },
    ];

    return items.filter((item) => item.value);
  };

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const data = contactDetails ? getTraderData(contactDetails) : null;

  return (
    <Flex direction="column" gap="size-100">
      <FlexButton
        onClick={toggleOpen}
        role="button"
        aria-expanded={isOpen}
        aria-controls="trader-information"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            toggleOpen();
          }
        }}
      >
        <Heading level={4} margin={0}>
          Trader information
        </Heading>
        <ChevronIcon size="S" />
      </FlexButton>
      {isOpen && (
        <Flex direction="column" gap="size-250" id="trader-information">
          <Text>
            This information is provided here in accordance with the{' '}
            <a href="https://www.adobe.com/go/eu_digital_services_act" target="_blank" rel="noopener noreferrer">
              EU Digital Services Act
            </a>{' '}
            trader requirements.
          </Text>
          {contactDetails ? (
            data.map(({ label, value }) => (
              <View key={label}>
                <Heading level={4} margin={0}>
                  {label}
                </Heading>
                <Text>{value}</Text>
              </View>
            ))
          ) : (
            <Flex gap="size-100">
              <Info maxWidth="18px" minWidth="18px" />
              <Text marginTop="2px">{noDetailsMessage}</Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default TraderInformation;
