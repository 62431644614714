//@ts-check
import { useMemo } from 'react';

import useIntegrationDetailsData from '../useIntegrationDetailsData';

/**
 * @param {import('@action-types/user').Product[]} [products]
 * @param {import('@action-types/service').Service} [service]
 */
const findProduct = (products, service) => {
  return products?.find?.((product) => product?.id === service?.properties?.licenseConfigs?.[0]?.productId);
};

/** @param {import('@action-types/user').LicenseGroup[]} licenseGroups */
const getSortedProfiles = (licenseGroups) => {
  return licenseGroups?.slice().sort((a, b) => a?.name?.localeCompare(b?.name));
};

/**
 * @param {import('@action-types/user').Product[]} products - The array of products.
 * @param {import('@action-types/service').Service[]} services - The array of services.
 */
const getProductProfiles = (products, services) =>
  services
    ?.filter((service) => findProduct(products, service)?.licenseGroups?.length > 0)
    .map((service) => {
      const product = findProduct(products, service);
      const name = service.name;
      const profiles = getSortedProfiles(product.licenseGroups);
      const id = product.id;
      return { id, name, profiles };
    });

const useProductProfilesTableData = () => {
  const { userQuery, servicesQuery } = useIntegrationDetailsData();
  const services = servicesQuery?.data?.services;
  const products = userQuery?.data?.products;
  const productProfiles = useMemo(() => getProductProfiles(products, services), [products, services]);

  return productProfiles;
};

export default useProductProfilesTableData;
