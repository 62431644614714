import { ActionGroup, DialogContainer, Flex, Heading, Item, Text } from '@adobe/react-spectrum';
import { Card, CardBody, CardFooter } from '@react/react-spectrum/Card';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ENV_STATUS } from '../../../../../utils/Constants';
import { formatDate } from '../../../../../utils/ECAppsExtensionsHelper';
import AppStatusLight from '../common/AppStatusLight';
import { DeleteEnvDialog } from './tabs/details/EnvDetailsButtons';

const ActionLink = styled(RouterLink)`
  text-align: left;
  text-decoration: none;
  font: normal normal normal 14px/17px adobe-clean;
  letter-spacing: 0;
  opacity: 1;
  color: ${({ $isDisabled }) => ($isDisabled ? 'var(--spectrum-gray-400)' : 'var(--spectrum-global-color-gray-800)')};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
`;
const CardSubTitle = styled.div`
  text-align: left;
  font: normal normal bold 14px/18px adobe-clean;
  color: var(--spectrum-global-color-gray-800);
  letter-spacing: 0.84px;
  text-transform: uppercase;
  opacity: 1;
`;
const VersionText = styled.div`
  text-align: left;
  font: normal normal bold 11px/14px adobe-clean;
  letter-spacing: 0.66px;
  color: var(--spectrum-global-color-gray-700);
  text-transform: uppercase;
  opacity: 1;
`;
/**
 * Used only for App Builder details page
 */
const EnvCard = ({ env, orgId, app }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isDeleteEnvDisabled = [
    ENV_STATUS.INSTALLED,
    ENV_STATUS.DEPLOYMENT_IN_PROGRESS,
    ENV_STATUS.UNDEPLOYMENT_IN_PROGRESS,
  ].includes(env?.status);
  const onItemAction = (key) => {
    if (key === 'delete') {
      setIsOpen(true);
    }
  };
  const buildUrl = (path) => {
    return `/manage/apps/ec/${orgId}/ABD/view/${app.id}/${env.id}/${path}`;
  };

  return (
    <>
      <Card
        data-testid="EC_APP_DETAILS__ENVIRONMENT_CARD"
        allowsSelection={false}
        style={{
          minWidth: '304px',
          minHeight: '209px',
          cursor: 'pointer',
        }}
        onClick={() => navigate(buildUrl('details'))}
      >
        <CardBody>
          <CardSubTitle>last modified {env?.createdAt ? formatDate(env?.createdAt) : 'N/A'}</CardSubTitle>
          <Heading>{env?.name}</Heading>
          <VersionText>Version</VersionText>
          <Text>{env?.status !== ENV_STATUS.DRAFT ? env?.deployedVersion : '-'}</Text>
        </CardBody>
        <CardFooter>
          <Flex justifyContent="space-between">
            <AppStatusLight status={env.status} data-testid="EC_APP_DETAILS__ENVIRONMENT_CARD__STATUS" />
            <ActionGroup
              overflowMode="collapse"
              buttonLabelBehavior="collapse"
              maxWidth="size-450"
              disabledKeys={isDeleteEnvDisabled ? ['delete', 'deploy_env'] : []}
              onAction={(key) => onItemAction(key)}
              data-testid="EC_APP_DETAILS__ENVIRONMENT_CARD__MORE_ACTIONS_BUTTON"
            >
              <Item key="view_env_details" textValue="View Details">
                <Text>
                  <ActionLink to={buildUrl('details')}>View Details</ActionLink>
                </Text>
              </Item>
              <Item key="view_env_insights" textValue="View Insights">
                <Text>
                  <ActionLink to={buildUrl('insights')}>View Insights</ActionLink>
                </Text>
              </Item>
              <Item key="deploy_env" textValue="Deploy">
                <Text>
                  <ActionLink $isDisabled={isDeleteEnvDisabled} disabled={isDeleteEnvDisabled} to={buildUrl('deploy')}>
                    {env.status === ENV_STATUS.INSTALLED ? 'Undeploy' : 'Deploy'}
                  </ActionLink>
                </Text>
              </Item>
              <Item key="delete" textValue="Delete">
                <Text>Delete</Text>
              </Item>
            </ActionGroup>
          </Flex>
        </CardFooter>
      </Card>
      <DialogContainer onDismiss={() => setIsOpen(false)}>
        {isOpen && <DeleteEnvDialog close={() => setIsOpen(false)} env={env} orgId={orgId} app={app} isDialogOpen />}
      </DialogContainer>
    </>
  );
};

export default EnvCard;
