{
  "alpha": "Alfa",
  "black": "fekete",
  "blue": "Kék",
  "blue purple": "kék lila",
  "brightness": "Fényesség",
  "brown": "barna",
  "brown yellow": "barna sárga",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "ciánkék",
  "cyan blue": "ciánkék",
  "dark": "sötét",
  "gray": "szürke",
  "grayish": "szürkés",
  "green": "Zöld",
  "green cyan": "zöld ciánkék",
  "hue": "Színárnyalat",
  "light": "világos",
  "lightness": "Világosság",
  "magenta": "bíbor",
  "magenta pink": "bíbor rózsaszín",
  "orange": "narancs",
  "orange yellow": "narancssárga",
  "pale": "halvány",
  "pink": "rózsaszín",
  "pink red": "rózsaszín piros",
  "purple": "lila",
  "purple magenta": "lila bíbor",
  "red": "Piros",
  "red orange": "piros narancs",
  "saturation": "Telítettség",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} átlátszó",
  "very dark": "nagyon sötét",
  "very light": "nagyon világos",
  "vibrant": "élénk",
  "white": "fehér",
  "yellow": "sárga",
  "yellow green": "sárga zöld"
}
