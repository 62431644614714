//@ts-check
import {
  ActionButton,
  Button,
  Content,
  Dialog,
  DialogTrigger,
  Footer,
  Heading,
  Link,
  Text,
} from '@adobe/react-spectrum';
import { useIsMobileDevice } from '@react-spectrum/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useRootHref } from '@exchange-frontends/custom-hooks';

import AccreditedPartnerSolutionSVG from './AccreditedPartnerSolutionSVG';
import RapidDeploymentSolutionSVG from './RapidDeploymentSolutionSVG';
import AssuranceProgramSVG from './AssuranceProgramSVG';

const GrowingFlexItem = styled.span`
  flex: 1;
`;

const ButtonContainer = styled.div`
  width: 100%;
  &&& > button {
    background: none;
    border: none;
  }
`;

const badgeTypes = {
  'ASSURED': 'ASSURED',
  'RAPID_DEPLOYMENT': 'RAPID_DEPLOYMENT',
  'ACCREDITED': 'ACCREDITED'
};

const badgeContent = {
  [badgeTypes.ASSURED]: {
    title: 'Assurance Program approved',
    content: 'This app has been reviewed and approved by the Adobe Assurance Program to meet quality standards',
    learnMoreLink: '/about#about-app-assurance-program',
    ariaLabel: 'Assurance Check',
    icon: AssuranceProgramSVG
  },
  [badgeTypes.RAPID_DEPLOYMENT]: {
    title: 'Rapid Deployment Solution',
    content: "Rapid Deployment solution is an innovative solution that solve a repeatable, industry-focused business challenge using the versatility of Adobe's powerful Experience Cloud technology combined with the domain expertise of an Adobe Solution Partner",
    learnMoreLink: 'https://solutionpartners.adobe.com/solution-partners/solution_validation.html',
    ariaLabel: 'Rapid Deployment Check',
    icon: RapidDeploymentSolutionSVG
  },
  [badgeTypes.ACCREDITED]: {
    title: 'Accredited Partner Solution',
    content: "Accredited Partner Solution is an innovative solution that solve a repeatable, industry-focused business challenge using the versatility of Adobe's powerful Experience Cloud technology combined with the domain expertise of an Adobe Solution Partner",
    learnMoreLink: 'https://solutionpartners.adobe.com/solution-partners/solution_validation.html',
    ariaLabel: 'Accredited Partner Check',
    icon: AccreditedPartnerSolutionSVG
  }
};

/**
 * @typedef {object} IconPopoverDialogProps
 * @property {keyof badgeTypes} badgeType - Type of badge to display (one of badgeTypes)
 */

/**
 * displays content in a popover triggered by an icon button.
 * 
 * @param {IconPopoverDialogProps} props
 * @returns {React.ReactElement} BadgePopover component
 */
const BadgePopover = ({ badgeType }) => {
  const isMobileDevice = useIsMobileDevice();
  const currentBadgeContent = badgeContent[badgeType] || badgeContent[badgeTypes.ASSURED];
  const { title, content, learnMoreLink, ariaLabel, icon: BadgeIcon } = currentBadgeContent;

  const isAssuranceProgram = badgeType === badgeTypes.ASSURED;
  const rootHref = useRootHref(learnMoreLink);
  const link = isAssuranceProgram ? rootHref : learnMoreLink;

  const renderLearnMoreLink = () => (
    isAssuranceProgram 
      ? <RouterLink to={link}>Learn more</RouterLink>
      : <a href={link} target="_blank" rel="noopener noreferrer">Learn more</a>
  );

  const getMobileStyle = (style) => isMobileDevice ? null : style;

  return (
    <DialogTrigger 
      type="popover" 
      hideArrow 
      placement="bottom start"
    >
      <ButtonContainer>
        <ActionButton aria-label={ariaLabel} width="100%" height="auto" isQuiet>
          <BadgeIcon />
        </ActionButton>
      </ButtonContainer>
      {(close) => (
        <Dialog 
          size="S" 
          maxWidth={getMobileStyle('400px')} 
          maxHeight={getMobileStyle('300px')}
        >
          <Heading
            marginTop={getMobileStyle('-20px')}
            marginStart={getMobileStyle('-16px')}
            marginBottom={getMobileStyle('size-175')}
          >
            {title}
          </Heading>
          <Content marginX={getMobileStyle('-20px')}>
            <Text>{content}</Text>
          </Content>
          <Footer 
            marginY={getMobileStyle('-20px')} 
            marginEnd={getMobileStyle('-15px')}
          >
            <GrowingFlexItem />
            <Link marginEnd={'size-100'} marginTop={'size-50'}>
              {renderLearnMoreLink()}
            </Link>
            <Button variant="secondary" onPress={close}>
              Dismiss
            </Button>
          </Footer>
        </Dialog>
      )}
    </DialogTrigger>
  );
};

BadgePopover.propTypes = {
  badgeType: PropTypes.oneOf(Object.values(badgeTypes)).isRequired
};

export default BadgePopover;