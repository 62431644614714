//@ts-check
import { Cell, Column, Flex, Row, TableBody, TableHeader, TableView, Text } from '@adobe/react-spectrum';
import Edit from '@spectrum-icons/workflow/Edit';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ProductProfilesContext } from './ProductProfilesProvider';

const TABLE_ID = 'select-product-profiles-table';
const PAGE_SIZE = 50;

const TableWrapper = styled.div`
  flex-grow: 2;
  #${TABLE_ID} > div {
    // spectrum does not include the checkbox column in the width and makes the table overflow on smallers screens
    overflow-x: hidden !important;
  }
`;

const columns = {
  PROFILE_NAME: {
    key: 'name',
    name: 'Product profile',
    allowsSorting: true,
  },
};

const columnsArray = Object.values(columns);

/**
 * Table for displaying avaialble and selected product profiles.
 * It displays 50 profiles and then loads more on scroll so that the table does not get laggy.
 * On stage there are a few services with thousands of profiles.
 * @param {object} props
 * @param {import('@action-types/service').Service} props.selectedService
 */
const ProductProfilesTable = ({ selectedService }) => {
  /** @type {ReturnType<typeof useState<import('@adobe/react-spectrum').SpectrumTableProps['sortDescriptor']>>} */
  const [sortDescriptor, setSortDescriptor] = useState({ column: columns.PROFILE_NAME.key, direction: 'ascending' });
  const [shownAmount, setShownAmount] = useState(PAGE_SIZE);
  const {
    setSelectedProductProfiles,
    selectedProductProfiles,
    editProfilesMutation,
    editedProductProfiles,
    originalSelectedProductProfiles,
    refetchingSelectedProfiles,
  } = useContext(ProductProfilesContext);

  const hasSelectedProfiles =
    originalSelectedProductProfiles?.flatMap(({ licenseGroupIds }) => licenseGroupIds)?.length > 0;
  const productProfiles = selectedService?.properties?.licenseConfigs;
  const productId = productProfiles?.[0]?.productId;
  const selectedProductProfileKeys = selectedProductProfiles?.find(
    (product) => product.productId === productId
  )?.licenseGroupIds;

  const sortedProductProfiles = useMemo(() => {
    const sortKey = sortDescriptor.column.toString();
    const sortedProfiles = productProfiles.slice().sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    if (sortDescriptor.direction === 'descending') {
      sortedProfiles.reverse();
    }
    return sortedProfiles.slice(0, shownAmount);
  }, [productProfiles, shownAmount, sortDescriptor.column, sortDescriptor.direction]);

  const onLoadMore = () => {
    if (productProfiles.length > shownAmount) {
      setShownAmount((shownAmount) => shownAmount + PAGE_SIZE);
    }
  };

  /** @param {import('@adobe/react-spectrum').Selection} selection */
  const onSelectionChange = (selection) => {
    const selectedKeys = selection === 'all' ? new Set(productProfiles.map((profile) => profile.id)) : selection;
    const licenseGroupIds = Array.from(selectedKeys).map((key) => key.toString());
    const { productId } = sortedProductProfiles[0];
    setSelectedProductProfiles({
      productId,
      licenseGroupIds,
    });
  };

  const savingProfiles = editProfilesMutation.isLoading || refetchingSelectedProfiles;

  return (
    <TableWrapper>
      <TableView
        isQuiet
        id={TABLE_ID}
        maxHeight="360px"
        density="spacious"
        selectionStyle="checkbox"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        onSelectionChange={onSelectionChange}
        selectedKeys={selectedProductProfileKeys}
        selectionMode={savingProfiles ? 'single' : 'multiple'}
        aria-label={`List of product profiles for ${selectedService?.name}`}
        disabledKeys={savingProfiles ? sortedProductProfiles.map(({ id }) => id) : undefined}
      >
        <TableBody items={sortedProductProfiles} onLoadMore={onLoadMore}>
          {(productProfile) => {
            const isValueDifferent = editedProductProfiles.some(
              ({ licenseGroupId }) => licenseGroupId === productProfile.id
            );
            const isChanged = hasSelectedProfiles && isValueDifferent;

            return (
              <Row key={productProfile.id}>
                {(columKey) => (
                  <Cell key={`${columKey}-${productProfile.id}`}>
                    <Flex width="100%" justifyContent="space-between" gap="size-200">
                      <Text>{productProfile[columKey.toString()]}</Text>
                      {isChanged && <Edit size="S" />}
                    </Flex>
                  </Cell>
                )}
              </Row>
            );
          }}
        </TableBody>
        {/* Header needs to be below body, otherwise the rows won't rerender when the selection changes */}
        <TableHeader columns={columnsArray}>
          {(column) => (
            <Column key={column.key} allowsSorting={column.allowsSorting}>
              {column.name}
            </Column>
          )}
        </TableHeader>
      </TableView>
    </TableWrapper>
  );
};

export default ProductProfilesTable;
