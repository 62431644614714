{
  "alpha": "Alpha",
  "black": "Schwarz",
  "blue": "Blau",
  "blue purple": "Blaulila",
  "brightness": "Helligkeit",
  "brown": "Braun",
  "brown yellow": "Braungelb",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "Cyan",
  "cyan blue": "Cyanblau",
  "dark": "dunkles",
  "gray": "Grau",
  "grayish": "gräuliches",
  "green": "Grün",
  "green cyan": "Grüncyan",
  "hue": "Farbton",
  "light": "helles",
  "lightness": "Leuchtkraft",
  "magenta": "Magenta",
  "magenta pink": "Magentarosa",
  "orange": "Orange",
  "orange yellow": "Orangegelb",
  "pale": "blasses",
  "pink": "Rosa",
  "pink red": "Rosarot",
  "purple": "Lila",
  "purple magenta": "Lilamagenta",
  "red": "Rot",
  "red orange": "Rotorange",
  "saturation": "Sättigung",
  "transparentColorName": "{lightness} {chroma} {hue}, zu {percentTransparent} transparent",
  "very dark": "sehr dunkles",
  "very light": "sehr helles",
  "vibrant": "lebhaftes",
  "white": "Weiß",
  "yellow": "Gelb",
  "yellow green": "Gelbgrün"
}
