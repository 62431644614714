//@ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { useStore } from '../../../pages/manage/store';
import { ContainerContext } from '../../../utils';
import getUser from '../actions/getUser';

const selectedOrgSelector = (state) => state.selectedOrg?.code;

/**
 * @param {string} userId
 * @param {import('@tanstack/react-query').UseQueryOptions} options */
const useUserDetails = (userId, options = {}) => {
  /** @type {string | undefined} */
  const orgId = useStore(selectedOrgSelector);
  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/user').UserDetailsQueryState} */
  const data = useQuery({
    ...options,
    retry: false,
    queryKey: ['get-user-details', orgId, token, userId],
    enabled: Boolean(token && orgId && userId && (options.enabled ?? true)),
    queryFn: ({ signal }) =>
      getUser({
        token: /** @type {string} */ (token),
        orgId: /** @type {string} */ (orgId),
        userId,
        signal,
      }),
  });

  return data;
};

export default useUserDetails;
