import { useMemo, useReducer } from 'react';

import useUserDetails from '../../../../../actions/jilApi/selectors/useUserDetails';

/**
 * Compares the current product profiles with the original product profiles and returns the product profiles that are in the current state but not in the original state.
 * Data is mapped to the format required by the API.
 * @param {import('./types').SelectedProductProfile[]} productProfiles
 * @param {import('./types').SelectedProductProfile[]} originalProductProfiles
 * @param {import('@action-types/user').PatchProductProfilesMutationParams['productProfiles'][0]['operation']} operation
 * @returns {import('@action-types/user').PatchProductProfilesMutationParams['productProfiles']}
 */
const getEditedProductProfiles = (productProfiles, originalProductProfiles, operation) => {
  return productProfiles.flatMap((product) => {
    const correspondingService = originalProductProfiles?.find(({ productId }) => productId === product.productId);
    const licenseGroupIds = product?.licenseGroupIds?.filter(
      (licenseGroupId) => !correspondingService?.licenseGroupIds?.includes(licenseGroupId)
    );
    return licenseGroupIds?.map((licenseGroupId) => ({
      licenseGroupId,
      operation,
      productId: product.productId,
    }));
  });
};

/**
 * @param {import('./types').SelectedProductProfile[]} currentState
 * @param {import('./types').SelectedProductProfile} action
 */
const selectedProfilesReducer = (currentState, { productId, licenseGroupIds }) => {
  const newState = currentState.filter((service) => service.productId !== productId);
  newState.push({ productId, licenseGroupIds });
  return newState;
};

/** @param {string} technicalAccountId */
const useProductProfilesData = (technicalAccountId) => {
  const userQuery = useUserDetails(technicalAccountId);

  const originalSelectedProductProfiles = useMemo(
    () =>
      userQuery.data?.products.map((product) => ({
        productId: product.id,
        licenseGroupIds: product.licenseGroups.map(({ id }) => id),
      })) ?? [],
    [userQuery.data?.products]
  );

  const [selectedProductProfiles, setSelectedProductProfiles] = useReducer(
    selectedProfilesReducer,
    originalSelectedProductProfiles
  );

  const editedProductProfiles = useMemo(() => {
    const addedProductProfiles = getEditedProductProfiles(
      selectedProductProfiles,
      originalSelectedProductProfiles,
      'add'
    );
    const removedProductProfiles = getEditedProductProfiles(
      originalSelectedProductProfiles,
      selectedProductProfiles,
      'remove'
    );
    return [...addedProductProfiles, ...removedProductProfiles];
  }, [originalSelectedProductProfiles, selectedProductProfiles]);

  return {
    editedProductProfiles,
    originalSelectedProductProfiles,
    selectedProductProfiles,
    setSelectedProductProfiles,
  };
};

export default useProductProfilesData;
