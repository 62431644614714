import { useNavigate } from 'react-router-dom';

import { getCorrectUrlSlug } from '@exchange-frontends/utils';

import RequestSearchParams from '../actions/utils/RequestSearchParams';
import { ROUTES } from '../constants';
import { PUBLISHER_TAB_MAP } from '../pages/publisher/utils/publisher-data';

/**
 * @param {object} props
 * @param {import('@action-types/ecApp').Publisher} props.publisher
 * @param {string} props.appType
 * @param {string} props.cloud
 */
const usePublisherLink = ({ publisher, appType, cloud }) => {
  const navigate = useNavigate();
  const { id, name, deeplinkUrl } = publisher || {};
  const isSolution = appType === 'SLN';
  const publisherNameSlug = getCorrectUrlSlug(name);
  const search = new RequestSearchParams();
  if (appType === 'SLN') {
    search.set('tab', PUBLISHER_TAB_MAP.SOLUTIONS.id);
  }
  const publisherDetailsRoute = `${ROUTES.PUBLISHER}/${cloud}/${id}/${publisherNameSlug}${search}`;
  const publisherLinkType = isSolution && deeplinkUrl ? 'solution' : 'default';
  const publisherLinkMap = {
    solution: {
      publisherLink: deeplinkUrl,
      publisherTarget: '_blank',
      openPublisherLink: () => window.open(deeplinkUrl, '_blank', 'noopener,noreferrer'),
    },
    default: {
      publisherLink: publisherDetailsRoute,
      publisherTarget: '_self',
      openPublisherLink: () => navigate(publisherDetailsRoute),
    },
  };

  return publisherLinkMap[publisherLinkType];
};

export default usePublisherLink;
