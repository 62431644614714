// @ts-check
import { Flex, Heading, Image, Link, Text, View } from '@adobe/react-spectrum';
import React from 'react';
import { Link as RouterLink, generatePath, useParams } from 'react-router-dom';

import illustration from '../../../../../../../static/illustrations/Illu_ConsentFlow_320x180.png';

import { ROUTES } from '../../../../../../constants';
import { useStore } from '../../../../store';
import { TABS_DEFINITION } from '../../../../utils/TabsDefinition';
import { INTEGRATION_DETAILS_ROUTES } from './IntegrationDetails';
import useIntegrationDetailsData from './useIntegrationDetailsData';

const IntegrationHelperText = () => {
  const params = useParams();
  const isAdmin = useStore((state) => state.selectedOrg?.role === 'ADMIN');
  const { consentQuery, userQuery, servicesQuery } = useIntegrationDetailsData();

  const technicalAccountId = consentQuery.data?.consent?.technicalAccountId;
  const selectedProductProfiles = userQuery.data?.products?.flatMap((product) => product?.licenseGroups);
  const status = consentQuery.data?.consent?.status;
  const services = servicesQuery.data?.services;

  const shouldShow =
    isAdmin && technicalAccountId && status === 'ALLOWED' && !selectedProductProfiles?.length && services?.length > 0;

  if (shouldShow) {
    /** @param {string} tab */
    const navigateToTab = (tab) =>
      generatePath(`${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATION_DETAILS.path}`, { ...params, tab });

    return (
      <View
        borderWidth="thick"
        borderColor="static-blue-600"
        borderRadius="regular"
        backgroundColor="static-white"
        padding="size-200"
      >
        <Flex gap="size-200" alignItems="center">
          <Image
            isHidden={{ base: true, S: false }}
            src={illustration.startsWith('/') ? illustration : `/${illustration}`}
            maxWidth="size-2000"
            alt=""
          />
          <Flex direction="column" gap="size-50">
            <Heading margin={0}>Next step</Heading>
            <Text>
              A technical account was set up on your organization&apos;s behalf upon providing access. View the details
              and scopes on the{' '}
              <Link>
                <RouterLink to={navigateToTab(INTEGRATION_DETAILS_ROUTES.ACCESS_DETAILS.path)}>
                  Access details tab
                </RouterLink>
              </Link>{' '}
              or configure product profiles on the{' '}
              <Link>
                <RouterLink to={navigateToTab(INTEGRATION_DETAILS_ROUTES.PRODUCT_PROFILES.path)}>
                  Product profiles tab
                </RouterLink>
              </Link>
              .
            </Text>
          </Flex>
        </Flex>
      </View>
    );
  } else {
    return null;
  }
};

export default IntegrationHelperText;
