{
  "alpha": "Alfa",
  "black": "crno",
  "blue": "Plava",
  "blue purple": "plavo ljubičasta",
  "brightness": "Svjetlina",
  "brown": "smeđa",
  "brown yellow": "smeđe žuta",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cijan",
  "cyan blue": "cijan plava",
  "dark": "tamno",
  "gray": "siva",
  "grayish": "sivkasto",
  "green": "Zelena",
  "green cyan": "zelena cijan",
  "hue": "Nijansa",
  "light": "svjetlo",
  "lightness": "Osvijetljenost",
  "magenta": "magenta",
  "magenta pink": "magentno ružičasta",
  "orange": "narančasta",
  "orange yellow": "narančasto žuta",
  "pale": "blijeda",
  "pink": "ružičasta",
  "pink red": "ružičasto crvena",
  "purple": "ljubičasta",
  "purple magenta": "ljubičasta magenta",
  "red": "Crvena",
  "red orange": "crveno narančasta",
  "saturation": "Zasićenost",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} prozirnosti",
  "very dark": "jako tamna",
  "very light": "vrlo svijetlo",
  "vibrant": "vibrantna",
  "white": "bijela",
  "yellow": "žuto",
  "yellow green": "žuto zelena"
}
