export const getExtensionName = (productName, appBuilderExtensionPoints) => {
  let extensionName;
  appBuilderExtensionPoints?.forEach((value, key) => {
    if (productName?.includes(key)) {
      extensionName = value;
    }
  });
  return extensionName;
};

export const getExtensionDetails = (app, appBuilderExtensionPoints) => {
  const ExtensionDetails = [];
  if (app?.jaeger && !app.products?.length) return ['App Builder'];
  if (app && !app.jaeger) return [app.extensionType || 'App Builder'];
  app?.products?.map((product) => {
    appBuilderExtensionPoints?.forEach((value, key) => {
      if (product?.name.includes(key)) {
        ExtensionDetails.push(value);
      }
    });
  });
  return ExtensionDetails;
};

//map extension points to product names
export const getProductsList = (app) => {
  let list = [];
  if (app?.jaeger) {
    if (!app?.products) return [];
    app?.products?.map((product) => {
      if (product?.name.includes('dx/excshell/1')) {
        list.push('Experience Cloud App');
      } else if (product?.name.includes('dx/asset-compute/worker/1')) {
        list.push('AEM Assets Processing Profile');
      } else if (product?.name.includes('aem/cf-console-admin/1')) {
        list.push('AEM Sites UI Extension');
      }
    });
  } else {
    const hostApps = app?.hostApps ?? [];
    const optionalProducts = app?.optionalProducts ?? [];
    list = [...hostApps, ...optionalProducts];
  }
  return list;
};

export const getExtensionDetailsList = (product, appBuilderExtensionPoints, excShellUrl) => {
  const appBuilderExtensionName = getExtensionName(product.name, appBuilderExtensionPoints);
  const isExcShell = appBuilderExtensionName?.indexOf('ExcShell') > -1;
  const productEndPoints = product?.endpoints;

  let endpointsArray = [];
  if (Array.isArray(productEndPoints)) {
    // backward compatibility
    endpointsArray = productEndPoints.map((endpoint) =>
      isExcShell ? `${excShellUrl}/?devMode=true#/custom-apps/?localDevUrl=${endpoint}` : endpoint
    );
  } else {
    endpointsArray.push(
      isExcShell ? `${excShellUrl}/?devMode=true#/custom-apps/?localDevUrl=${productEndPoints}` : productEndPoints
    );
  }

  return endpointsArray;
};

export const formatDate = (dateString) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(dateString);
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

export const getIconUrl = (app) => {
  let iconUrl = '';
  if (app.jaeger) {
    iconUrl = app.iconUrl;
  } else if (app?.assets && app.assets.length > 0) {
    iconUrl = app?.assets.find((asset) => asset?.type === 'icon' && asset?.order === 1)?.url;
  }

  return iconUrl;
};

export const buildAppListingUrl = (app) => {
  return location.origin + `/apps/ec/${app.id}`; // details page
};
