{
  "alpha": "Alfa",
  "black": "černá",
  "blue": "Modrá",
  "blue purple": "modrofialová",
  "brightness": "Jas",
  "brown": "hnědá",
  "brown yellow": "hnědožlutá",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "tyrkysová",
  "cyan blue": "tyrkysovomodrá",
  "dark": "tmavá",
  "gray": "šedá",
  "grayish": "našedlá",
  "green": "Zelená",
  "green cyan": "zelenotyrkysová",
  "hue": "Odstín",
  "light": "světlá",
  "lightness": "Světlost",
  "magenta": "purpurová",
  "magenta pink": "purpurově růžová",
  "orange": "oranžová",
  "orange yellow": "oranžovožlutá",
  "pale": "bledá",
  "pink": "růžová",
  "pink red": "růžovočervená",
  "purple": "fialová",
  "purple magenta": "fialově purpurová",
  "red": "Červená",
  "red orange": "červenooranžová",
  "saturation": "Sytost",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} průhledné",
  "very dark": "velmi tmavá",
  "very light": "velmi světlá",
  "vibrant": "zářivá",
  "white": "bílá",
  "yellow": "žlutá",
  "yellow green": "žlutozelená"
}
