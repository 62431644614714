//@ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { useStore } from '../../../pages/manage/store';
import { ContainerContext } from '../../../utils';
import getServicesBySdkCodes from '../actions/getServicesBySdkCodes';

const selectedOrgSelector = (state) => state.selectedOrg?.code;

/**
 * @typedef {object} AdditionalOptions
 * @property {boolean} [onlyServicesWithProfiles = true]
 * @property {boolean} [shouldSort]
 *
 * @param {string[]} [sdkCodes]
 * @param {import('@tanstack/react-query').UseQueryOptions & AdditionalOptions} options */
const useServicesBySdkCodes = (sdkCodes, options = {}) => {
  /** @type {string | undefined} */
  const orgId = useStore(selectedOrgSelector);
  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;
  const onlyServicesWithProfiles = options.onlyServicesWithProfiles ?? true;
  const shouldSort = options.shouldSort ?? true;

  /** @type {import('@action-types/service').ServicesBySdkCodesQueryState} */
  const data = useQuery({
    ...options,
    queryKey: ['services-by-sdk-codes', orgId, token, sdkCodes, onlyServicesWithProfiles, shouldSort],
    enabled: Boolean(token && orgId && (options.enabled ?? true)),
    retry: false,
    queryFn: async ({ signal }) => {
      const servicesResponse = await getServicesBySdkCodes({
        token: /** @type {string} */ (token),
        orgId: /** @type {string} */ (orgId),
        sdkCodes,
        signal,
      });
      if (servicesResponse?.services?.length > 0) {
        if (onlyServicesWithProfiles) {
          servicesResponse.services = servicesResponse.services.filter(
            (service) => service.properties?.licenseConfigs?.length > 0
          );
        }
        if (shouldSort) {
          servicesResponse.services.sort((a, b) => a?.name?.localeCompare?.(b?.name));
        }
      }

      return servicesResponse;
    },
  });

  return data;
};

export default useServicesBySdkCodes;
