{
  "alpha": "Alfa",
  "black": "preto",
  "blue": "Azul",
  "blue purple": "azul-púrpura",
  "brightness": "Luminosidade",
  "brown": "castanho",
  "brown yellow": "amarelo-castanho",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "ciano",
  "cyan blue": "azul-ciano",
  "dark": "escuro",
  "gray": "cinzento",
  "grayish": "acinzentado",
  "green": "Verde",
  "green cyan": "verde-ciano",
  "hue": "Tonalidade",
  "light": "claro",
  "lightness": "Claridade",
  "magenta": "magenta",
  "magenta pink": "rosa-magenta",
  "orange": "laranja",
  "orange yellow": "amarelo-laranja",
  "pale": "pálido",
  "pink": "cor-de-rosa",
  "pink red": "vermelho-rosa",
  "purple": "púrpura",
  "purple magenta": "púrpura-magenta",
  "red": "Vermelho",
  "red orange": "laranja-vermelho",
  "saturation": "Saturação",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} transparente",
  "very dark": "muito escuro",
  "very light": "muito claro",
  "vibrant": "vibrante",
  "white": "branco",
  "yellow": "amarelo",
  "yellow green": "verde-amarelo"
}
