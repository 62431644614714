{
  "alpha": "Alfa",
  "black": "crno",
  "blue": "Plava",
  "blue purple": "plavoljubičasta",
  "brightness": "Osvetljenost",
  "brown": "smeđa",
  "brown yellow": "smeđežuta",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cijan",
  "cyan blue": "cijan plava",
  "dark": "tamno",
  "gray": "siva",
  "grayish": "sivkasta",
  "green": "Zelena",
  "green cyan": "zeleno cijan",
  "hue": "Nijansa",
  "light": "svetla",
  "lightness": "Osvetljenje",
  "magenta": "purpurnocrvena",
  "magenta pink": "magenta ružičasta",
  "orange": "narandžasta",
  "orange yellow": "narandžastožuta",
  "pale": "bledo",
  "pink": "ružičasta",
  "pink red": "ružičastocrvena",
  "purple": "ljubičasta",
  "purple magenta": "ljubičasta magenta",
  "red": "Crvena",
  "red orange": "crvenonarandžasta",
  "saturation": "Zasićenje",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} prozirna",
  "very dark": "veoma tamno",
  "very light": "vrlo svetlo",
  "vibrant": "živopisna",
  "white": "bela",
  "yellow": "žuto",
  "yellow green": "žutozelena"
}
