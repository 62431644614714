//@ts-check
import { entitlementApiClient } from '../../utils/RequestClient';
import RequestSearchParams from '../../utils/RequestSearchParams';

/**
 * @param {object} params
 * @param {string} params.orgId
 * @param {string} params.token
 * @param {string} params.appId
 * @param {'ALLOW' | 'DENY' | 'REQUEST' | 'REVOKE'} params.action
 * @param {string} [params.appType]
 * @param {string} [params.redirectOrg]
 * @param {string} [params.stateToken]
 * @param {string} [params.notes]
 * @returns {Promise<import('@action-types/consent').Consent>}
 * @throws {import('@action-types/RequestError').RequestError<import('@action-types/entitlement').EntitlementApiErrorResponse>}
 */
const patchConsent = async ({ token, orgId, appId, action, appType, redirectOrg, stateToken, notes = 'N/A' }) => {
  const search = new RequestSearchParams({ appType, redirectOrg, stateToken });
  const { jsonResponse } = await entitlementApiClient.fetch({
    endpoint: `/enterprise/consent${search}`,
    requestOptions: {
      method: 'PATCH',
      body: JSON.stringify({ action, appId, notes }),
      headers: {
        'x-org-id': orgId,
        authorization: token,
      },
    },
  });
  return jsonResponse;
};

export default patchConsent;
