//@ts-check
import { Button, ButtonGroup, Content, Dialog, Divider, Flex, Heading, Link, Text } from '@adobe/react-spectrum';
import React from 'react';
import styled from 'styled-components';

import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { LINKS } from '../../../../../../constants';

const UnorderedList = styled.ul`
  margin: 0;
`;

/**
 * @param {object} props
 * @param {() => void} props.close
 */
const DocumentationDialog = ({ close }) => {
  const appDetails = useEcAppDetails();
  const documents = appDetails.data?.assets
    ?.filter((asset) => asset?.type === 'document')
    .sort((a, b) => a.order - b.order);

  return (
    <Dialog maxWidth="555px">
      <Heading>Documentation</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-65">
          <Link isQuiet>
            <a href={LINKS.SP.VIEW_DOCS} target="_blank" rel="noopener noreferrer">
              Documentation on access/consent management features on Exchange marketplace
            </a>
          </Link>
          {documents?.length > 0 && (
            <>
              <Text>Documentation from the publisher about this application:</Text>
              <UnorderedList>
                {documents?.map((document) => (
                  <li key={document.url}>
                    <Link isQuiet>
                      <a href={document.url} target="_blank" rel="noopener noreferrer">
                        {document.name || document.url}
                      </a>
                    </Link>
                  </li>
                ))}
              </UnorderedList>
            </>
          )}
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={close}>
          Close
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default DocumentationDialog;
