import { Button, ButtonGroup, Content, Dialog } from '@adobe/react-spectrum';
import React from 'react';

import ErrorStateFallback from './ErrorStateFallback';

/**
 * @param {object} props
 * @param {() => void} props.close
 * @param {string} [sectionName]
 */
const ErrorDialogFallback = ({ close, sectionName }) => (
  <Dialog minHeight="80vh">
    <Content height="100%">
      <ErrorStateFallback height="100%" justifyContent="center" alignItems="center" sectionName={sectionName} />
    </Content>
    <ButtonGroup>
      <Button variant="secondary" onPress={close}>
        Close
      </Button>
    </ButtonGroup>
  </Dialog>
);

export default ErrorDialogFallback;
