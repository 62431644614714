//@ts-check
import { DialogContainer } from '@adobe/react-spectrum';
import React from 'react';

import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEnterpriseEntitlement from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useEnterpriseEntitlement';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import SpProductProfilesDialog from '../../../common/SpProductProfilesDialog';
import { WORKFLOWS } from './IntegrationDetails';
import useWorkflow from './useWorkflow';

const ProductProfilesDialog = () => {
  const { data: appDetails } = useEcAppDetails();
  const { data: entitlement } = useEnterpriseEntitlement();
  const { data: consentData } = useConsent();
  const { workflow, setWorkflow } = useWorkflow();

  const consent = consentData?.consent;
  const status = consent?.status;
  const isOpen = workflow === WORKFLOWS.PROFILES && status === 'ALLOWED';

  const closeDialog = () => setWorkflow(null);

  return (
    <DialogContainer onDismiss={closeDialog}>
      {isOpen && <SpProductProfilesDialog data={{ appDetails, entitlement, consent }} />}
    </DialogContainer>
  );
};

export default ProductProfilesDialog;
