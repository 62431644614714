import { Button, Content, ContextualHelp, Flex, Text } from '@adobe/react-spectrum';
import { TD } from '@react/react-spectrum/Table';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { DEPLOYMENT_STATUS } from '../../../../../utils/Constants';

export const FormattedCell = styled.div`
  display: flex;
  flex-direction: ${({ $isMobileSized }) => ($isMobileSized ? 'column' : 'row')};
  justify-content: ${({ $isMobileSized }) => ($isMobileSized ? 'space-between' : 'flex-start')};
  padding: 0 var(--spectrum-global-dimension-static-size-65);
  gap: var(--spectrum-global-dimension-static-size-100);
`;

export const ProductsCell = styled.span`
  display: flex;
  text-align: left;
  align-items: center;
  font: normal normal normal 12px/18px adobe-clean;
  letter-spacing: 0;
  color: #4b4b4b;
  opacity: 1;
`;

export const AppDetailsSection = styled.div`
  padding-left: 16px;
  text-align: left;
  font: normal normal bold 18px/23px adobe-clean;
  letter-spacing: 0;
  color: var(--spectrum-global-color-gray-900);
  opacity: 1;
  margin: var(--spectrum-global-dimension-static-size-250) 0;
`;

export const AppDetailsTD = styled(TD)`
  &&&&&& {
    padding-top: 0;
    border: none;
  }
`;

export const InlineMessage = styled.div`
  display: flex;
  align-items: center;
  color: white;
  background-color: var(--spectrum-global-color-static-red-700);
  height: var(--spectrum-global-dimension-static-size-600);
  gap: var(--spectrum-global-dimension-static-size-125);
  padding: 0 var(--spectrum-global-dimension-static-size-125);
  margin-bottom: var(--spectrum-global-dimension-static-size-250);
  width: 100%;
`;

export const FormattedText = styled.span`
  font: normal normal 300 16px/24px adobe-clean;
  color: var(--spectrum-global-color-gray-900);
`;

export const EmptyStateContainer = ({ header, content }) => {
  return (
    <Flex wrap="wrap" alignItems="center" justifyContent="center" marginTop="52px" gap="size-125">
      <span
        style={{
          font: 'normal normal 300 28px/29px adobe-clean',
          color: 'color-gray-900',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {header}
      </span>
      <FormattedText>{content}</FormattedText>
    </Flex>
  );
};

export const ItalicText = styled.span`
  text-align: center;
  font: italic normal normal 14px/17px adobe-clean;
  letter-spacing: 0.14px;
  color: var(--spectrum-global-color-gray-700);
  opacity: 1;
`;

export const SmallerText = styled.span`
  font-size: var(--spectrum-global-dimension-static-size-150);
  display: flex;
  max-width: 350px;
  word-wrap: break-word;
`;

export const CapitalizedText = styled.div`
  text-transform: capitalize;
  margin-right: var(--spectrum-global-dimension-size-100);
`;

export const ExtensionDetailsLink = styled.a`
  max-width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:before {
    float: right;
    content: attr(data-content-end);
  }
`;

export const NonAdminNote = (props) => {
  return (
    <ContextualHelp variant="help" {...props}>
      <Content>
        <Text>Only system administrator can perform this action.</Text>
      </Content>
    </ContextualHelp>
  );
};
export const NoInsightsContainer = ({
  title = 'No logs are available',
  orgId,
  appId,
  envId,
  isEnvInstalled,
  currentDeployment,
}) => {
  const navigate = useNavigate();
  const deploymentInProgress =
    currentDeployment?.status === DEPLOYMENT_STATUS.UNDEPLOYMENT_IN_PROGRESS ||
    currentDeployment?.status === DEPLOYMENT_STATUS.IN_PROGRESS;
  return (
    <Flex wrap="wrap" alignItems="center" justifyContent="center" direction="column" gap="30px">
      <Flex wrap="wrap" alignItems="center" justifyContent="center" marginTop="52px" gap="size-125">
        <span
          style={{
            font: 'normal normal 300 28px/29px adobe-clean',
            color: 'color-gray-900',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {title}
        </span>
        {!isEnvInstalled && !deploymentInProgress && (
          <FormattedText>Get started by deploying this environment.</FormattedText>
        )}
        {currentDeployment?.status === DEPLOYMENT_STATUS.IN_PROGRESS && (
          <FormattedText>Deployment in progress...</FormattedText>
        )}
        {currentDeployment?.status === DEPLOYMENT_STATUS.UNDEPLOYMENT_IN_PROGRESS && (
          <FormattedText> Undeployment in progress...</FormattedText>
        )}
      </Flex>
      {!isEnvInstalled && !deploymentInProgress && (
        <Button
          variant="primary"
          style="outline"
          onPress={() => {
            navigate(`/manage/apps/ec/${orgId}/ABD/view/${appId}/${envId}/deploy`);
          }}
        >
          Deploy
        </Button>
      )}
    </Flex>
  );
};
