//@ts-check
import { AlertDialog, DialogContainer, Flex, Text } from '@adobe/react-spectrum';
import { success } from '@react/react-spectrum/Toast/js/ToastContainer';
import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import useEcAppSdkCodes from '../../../../../../actions/consoleApi/selectors/useEcAppSdkCodes';
import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { WORKFLOWS } from './IntegrationDetails';

const AccessGrantedDialog = () => {
  const consentData = useConsent();
  const appDetailsQuery = useEcAppDetails();
  const servicesQuery = useEcAppSdkCodes(appDetailsQuery.data);
  const [searchParams, setSearchParams] = useSearchParams();
  const consentParam = searchParams.get('admin_consent');

  const hasProductProfiles = servicesQuery.data?.services?.length > 0;

  const status = consentData.data?.consent?.status;
  const shouldShowConfirmation = consentParam === 'true' && status === 'ALLOWED';
  const isDialogOpen = shouldShowConfirmation && hasProductProfiles;
  const shouldShowSuccessToast = shouldShowConfirmation && !hasProductProfiles;

  const onContinue = () => {
    searchParams.delete('admin_consent');
    searchParams.set('workflow', WORKFLOWS.PROFILES);
    setSearchParams(searchParams);
  };

  const onDismiss = useCallback(() => {
    searchParams.delete('admin_consent');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (shouldShowSuccessToast) {
      onDismiss();
      success('Access granted successfully');
    }
  }, [shouldShowSuccessToast, onDismiss]);

  return (
    <DialogContainer onDismiss={onDismiss}>
      {isDialogOpen && (
        <AlertDialog
          width="100%"
          maxWidth="600px"
          cancelLabel="Close"
          variant="confirmation"
          primaryActionLabel="Continue"
          title="Access granted successfully"
          onSecondaryAction={onDismiss}
          onPrimaryAction={onContinue}
        >
          <Flex direction="column" gap="size-250">
            <Text>
              Next steps are to add product profiles for your integration&apos;s service account to gain access to
              granular features.
            </Text>
            <Text>
              This step must be completed by the system administrator of your organization&apos;s Adobe account.
            </Text>
          </Flex>
        </AlertDialog>
      )}
    </DialogContainer>
  );
};

export default AccessGrantedDialog;
