{
  "alpha": "Alfa",
  "black": "siyah",
  "blue": "Mavi",
  "blue purple": "mavi mor",
  "brightness": "Parlaklık",
  "brown": "kahverengi",
  "brown yellow": "kahverengi sarı",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "camgöbeği",
  "cyan blue": "camgöbeği mavi",
  "dark": "koyu",
  "gray": "gri",
  "grayish": "grimsi",
  "green": "Yeşil",
  "green cyan": "yeşil camgöbeği",
  "hue": "Ton",
  "light": "açık",
  "lightness": "Canlılık",
  "magenta": "eflatun",
  "magenta pink": "eflatun pembe",
  "orange": "turuncu",
  "orange yellow": "turuncu sarı",
  "pale": "solgun",
  "pink": "pembe",
  "pink red": "pembe kırmızı",
  "purple": "mor",
  "purple magenta": "mor eflatun",
  "red": "Kırmızı",
  "red orange": "kırmızı portakal",
  "saturation": "Doygunluk",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} saydam",
  "very dark": "çok koyu",
  "very light": "çok açık",
  "vibrant": "canlı",
  "white": "beyaz",
  "yellow": "sarı",
  "yellow green": "sarı yeşil"
}
