//@ts-check
import { Button, ButtonGroup, Content, Dialog, Divider, Heading, Link } from '@adobe/react-spectrum';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import ManageContactPublisherDialog from '../manage/components/tabs/apps-list/ec/ManageContactPublisherDialog';
import ErrorDialogFallback from './ErrorDialogFallback';

const InformationText = styled.div`
  font-size: var(--spectrum-global-dimension-static-font-size-100);
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

/**
 * @typedef {object} GetSupportDialogProps
 * @property {import('@action-types/ecApp').AppDetails['support']} props.support
 * @property {string} [props.appId] - App Id needed for contact publisher dialog
 * @property {boolean} [props.showContactPublisher]
 * @property {string} [props.appType]
 * @property {()=> void} props.close
 */

/** @param {GetSupportDialogProps} props */
const GetSupportDialogWithoutBoundary = ({ support, close, appId, showContactPublisher, appType }) => {
  const [isContactPublisherOpen, setIsContactPublisherOpen] = useState(false);

  if (isContactPublisherOpen || (showContactPublisher && !support)) {
    return (
      <ManageContactPublisherDialog
        close={close}
        appId={appId}
        onGoBack={support ? () => setIsContactPublisherOpen(false) : undefined}
        appType={appType}
      />
    );
  } else {
    return (
      <Dialog>
        <Heading>Get support</Heading>
        <Divider />
        <Content>
          {support?.email && (
            <>
              <Heading level={4} marginTop="size-200" marginBottom="size-0">
                Email
              </Heading>
              <Link isQuiet>
                <a href={`mailto:${support?.email}`}>{support?.email}</a>
              </Link>
            </>
          )}
          {support?.url && (
            <>
              <Heading level={4} marginTop="size-200" marginBottom="size-0">
                URL
              </Heading>
              <Link isQuiet>
                <a
                  href={support?.url}
                  data-launchid="Help and Support : Get Support : Url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {support?.url}
                </a>
              </Link>
            </>
          )}
          {support?.info?.trim() && (
            <>
              <Heading level={4} marginTop="size-200" marginBottom="size-0">
                Other information
              </Heading>
              <InformationText>
                {support?.info.split(/\r+|\n+|\\n+|\\r+/).map((data, index) => (
                  <p key={index}>{data}</p>
                ))}
              </InformationText>
            </>
          )}
        </Content>
        <ButtonGroup>
          <Button variant="secondary" onPress={close}>
            Close
          </Button>
          {showContactPublisher && (
            <Button variant="primary" onPress={() => setIsContactPublisherOpen(true)}>
              Connect with publisher
            </Button>
          )}
        </ButtonGroup>
      </Dialog>
    );
  }
};

/** @param {GetSupportDialogProps} props */
const GetSupportDialog = (props) => {
  return (
    <ErrorBoundary FallbackComponent={() => <ErrorDialogFallback close={props.close} />}>
      <GetSupportDialogWithoutBoundary {...props} />
    </ErrorBoundary>
  );
};

export default GetSupportDialog;
