.spectrum--darkest .spectrum-Checkbox-label {
  color: rgb(200, 200, 200);
}
.spectrum--darkest .spectrum-Checkbox-box {
  border-color: rgb(124, 124, 124);
  background-color: rgb(26, 26, 26);
}
.spectrum--darkest .spectrum-Checkbox.is-indeterminate .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
  border-color: rgb(38, 128, 235);
}
.spectrum--darkest .spectrum-Checkbox:hover.is-indeterminate .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(55, 142, 240);

  }
.spectrum--darkest .spectrum-Checkbox:active.is-indeterminate .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(75, 156, 245);

  }
.spectrum--darkest .spectrum-Checkbox {
  border-color: rgb(124, 124, 124);
}
.spectrum--darkest .spectrum-Checkbox:hover .spectrum-Checkbox-box {
      border-color: rgb(162, 162, 162);

      box-shadow: none;
    }
.spectrum--darkest .spectrum-Checkbox:hover .spectrum-Checkbox-label {
      color: rgb(239, 239, 239);
    }
.spectrum--darkest .spectrum-Checkbox:active .spectrum-Checkbox-box {
      border-color: rgb(200, 200, 200);

    }
.spectrum--darkest .spectrum-Checkbox:active .spectrum-Checkbox-label {
      color: rgb(239, 239, 239);
    }
.spectrum--darkest .spectrum-Checkbox .spectrum-Checkbox-checkmark,
.spectrum--darkest .spectrum-Checkbox .spectrum-Checkbox-partialCheckmark {
    color: rgb(26, 26, 26);
  }
.spectrum--darkest .spectrum-Checkbox-input:disabled + .spectrum-Checkbox-box {
    border-color: rgb(73, 73, 73) !important;

    background-color: rgb(26, 26, 26);
  }
.spectrum--darkest .spectrum-Checkbox-input:disabled ~ .spectrum-Checkbox-label {
    color: rgb(92, 92, 92);
  }
.spectrum--darkest .spectrum-Checkbox-input.focus-ring + .spectrum-Checkbox-box {
    border-color: rgb(20, 115, 230) !important;

    box-shadow: 0 0 0 1px rgb(20, 115, 230) !important;
  }
.spectrum--darkest .spectrum-Checkbox-input.focus-ring ~ .spectrum-Checkbox-label {
    color: rgb(55, 142, 240) !important;
  }
.spectrum--darkest .spectrum-Checkbox--quiet.is-indeterminate .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox--quiet .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
  border-color: rgb(162, 162, 162);
}
.spectrum--darkest .spectrum-Checkbox--quiet:hover.is-indeterminate .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox--quiet:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(200, 200, 200);
  }
.spectrum--darkest .spectrum-Checkbox--quiet:active.is-indeterminate .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox--quiet:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(239, 239, 239);
  }
.spectrum--darkest .spectrum-Checkbox.is-invalid .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox.is-invalid .spectrum-Checkbox-box {
    border-color: rgb(227, 72, 80);
  }
.spectrum--darkest .spectrum-Checkbox.is-invalid .spectrum-Checkbox-label {
    color: rgb(236, 91, 98);
  }
.spectrum--darkest .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-box {
      border-color: rgb(236, 91, 98);
    }
.spectrum--darkest .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-label {
      color: rgb(247, 109, 116);
    }
.spectrum--darkest .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--darkest .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-box {
      border-color: rgb(247, 109, 116);
    }
.spectrum--darkest .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-label {
      color: rgb(247, 109, 116);
    }
