//@ts-check
import { Divider, Flex, ProgressCircle, View } from '@adobe/react-spectrum';
import React, { useEffect } from 'react';
import { ErrorBoundary, withErrorBoundary } from 'react-error-boundary';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import useEnterpriseEntitlement from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useEnterpriseEntitlement';
import { ROUTES } from '../../../../../../constants';
import ErrorStateFallback from '../../../../../components/ErrorStateFallback';
import { useStore } from '../../../../store';
import { TABS_DEFINITION } from '../../../../utils/TabsDefinition';
import AccessDetailsTab from './AccessDetailsTab';
import AccessGrantedDialog from './AccessGrantedDialog';
import AdminRoute from './AdminRoute';
import IntegrationApplicationDetails from './IntegrationApplicationDetails';
import IntegrationDetailsHeader from './IntegrationDetailsHeader';
import ProductProfilesDialog from './ProductProfilesDialog';
import ProductProfilesTab from './ProductProfilesTab';
import SideNavigation from './SideNavigation';
import useConsentBackupStrategy from './useConsentBackupStrategy';
import useIntegrationDetailsData from './useIntegrationDetailsData';

export const WORKFLOWS = Object.freeze({
  PROFILES: 'profiles',
  REVOKE: 'revoke',
});

export const TEST_IDS = Object.freeze({
  APP_DETAILS: 'app-details',
  APP_DETAILS_SECTION: 'app-details-section',
  APP_HEADER_STATUS: 'app-header-status',
  APP_HEADER_EMAIL: 'app-header-email',
  APP_HEADER_DATE: 'app-header-dates',
  REQUIRED_PRODUCTS: 'required-products',
  OPTIONAL_PRODUCTS: 'optional-products',
  DESCRIPTION: 'app-details-description',
  PUBLISHER_NAME: 'app-details-publisher-name',
  ACTIVITY_LOG_TABLE: 'activity-log-table',
  ACCESS_DETAILS_DENIED: 'access-details-denied',
  PRODUCT_PROFILES_DENIED: 'product-profiles-denied',
  NON_ADMIN_BANNER: 'non-admin-banner',
  ACCESS_DETAILS_TAB: 'access-details-tab',
  TECH_ACCOUNT_SECTION: 'tech-account-section',
  SCOPES_SECTION: 'scopes-section',
  NO_PRODUCT_PROFILES_SELECTED: 'no-product-profiles-selected',
});

export const INTEGRATION_DETAILS_ROUTES = Object.freeze({
  APPLICATION_DETAILS: { path: 'details', name: 'Application details', Element: IntegrationApplicationDetails },
  PRODUCT_PROFILES: {
    path: 'profiles',
    name: 'Product profiles',
    Element: () => (
      <AdminRoute
        Element={ProductProfilesTab}
        description="Viewing or editing product profiles must be completed by the system administrator of your organization's Adobe account."
        testId={TEST_IDS.PRODUCT_PROFILES_DENIED}
      />
    ),
  },
  ACCESS_DETAILS: {
    path: 'access',
    name: 'Access details',
    Element: () => (
      <AdminRoute
        Element={AccessDetailsTab}
        description="Only system administrators of your organization can view the access details for this app."
        testId={TEST_IDS.ACCESS_DETAILS_DENIED}
      />
    ),
  },
});

const routes = Object.values(INTEGRATION_DETAILS_ROUTES);

const IntegrationDetails = () => {
  const { consentQuery, appDetailsQuery, userQuery, servicesQuery } = useIntegrationDetailsData({
    refetchOnMount: true,
  });
  const isAdmin = useStore((state) => state.selectedOrg?.role === 'ADMIN');
  const entitlementQuery = useEnterpriseEntitlement('S2S', { refetchOnMount: true });
  const { isUpdatingConsent } = useConsentBackupStrategy();

  const navigate = useNavigate();
  const routeParams = useParams();
  const [searchParams] = useSearchParams();
  const consentParam = searchParams.get('admin_consent');

  const allQueries = [entitlementQuery, consentQuery, appDetailsQuery, userQuery, servicesQuery];
  const error = allQueries.find((query) => !query.data && query.error);
  const isLoadingData = allQueries.some((query) => query.isLoading && query.isFetching);
  const isLoading = isLoadingData || isUpdatingConsent;

  const technicalAccountId = consentQuery.data?.consent?.technicalAccountId;
  const status = consentQuery.data?.consent?.status;
  const accessGranted = technicalAccountId && status === 'ALLOWED';
  const routeVisibilityMap = new Map([
    [INTEGRATION_DETAILS_ROUTES.APPLICATION_DETAILS, true],
    [INTEGRATION_DETAILS_ROUTES.PRODUCT_PROFILES, accessGranted],
    [INTEGRATION_DETAILS_ROUTES.ACCESS_DETAILS, accessGranted],
  ]);
  const shownRoutes = routes.filter((route) => routeVisibilityMap.get(route));
  const selectedTabData = shownRoutes.find((route) => route.path === routeParams.tab);
  const { path: selectedTab, Element: SideContent, name: tabName } = selectedTabData ?? {};

  // Redirect to 'Application details' tab if the selected tab is not found in the shown routes array.
  useEffect(() => {
    if (!selectedTab) {
      navigate(
        {
          search: searchParams.toString(),
          pathname: generatePath(`${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATION_DETAILS.path}`, {
            ...routeParams,
            tab: INTEGRATION_DETAILS_ROUTES.APPLICATION_DETAILS.path,
          }),
        },
        { replace: true }
      );
    }
  }, [navigate, routeParams, searchParams, selectedTab]);

  // Redirect to integrations list if the entitlement is not found or if user clicked on cancel from IMS consent screen.
  useEffect(() => {
    const consentCancelled = consentParam === 'false';
    const entitlementNotFound = !entitlementQuery.data && !entitlementQuery.isLoading && !entitlementQuery.error;
    if (entitlementNotFound || consentCancelled) {
      navigate(generatePath(`${ROUTES.MANAGE}/${TABS_DEFINITION.INTEGRATIONS.path}`, routeParams));
    }
  }, [consentParam, entitlementQuery.data, entitlementQuery.error, entitlementQuery.isLoading, navigate, routeParams]);

  if (isLoading || !selectedTab) {
    return (
      <Flex justifyContent="center" flexGrow={1} alignItems="center" marginBottom="size-500">
        <ProgressCircle isIndeterminate aria-label="Loading app details" size="L" />
      </Flex>
    );
  } else if (error) {
    throw error.originalError;
  }

  return (
    <div data-testid={TEST_IDS.APP_DETAILS}>
      {isAdmin && accessGranted && (
        <>
          <AccessGrantedDialog />
          <ProductProfilesDialog />
        </>
      )}
      <IntegrationDetailsHeader />
      <Divider
        size="S"
        marginTop={{ base: 'size-50', M: 'size-200' }}
        marginBottom={{ base: 'size-200', S: 'size-400' }}
      />
      <Flex direction={{ base: 'column', S: 'row' }} gap={{ base: 'size-200', S: 'size-400' }}>
        <SideNavigation routes={shownRoutes} selectedTab={selectedTab} />
        {SideContent && (
          <View flexGrow={1}>
            <ErrorBoundary FallbackComponent={() => <ErrorStateFallback sectionName={tabName?.toLowerCase()} />}>
              <SideContent />
            </ErrorBoundary>
          </View>
        )}
      </Flex>
    </div>
  );
};

export default withErrorBoundary(IntegrationDetails, {
  onError: console.error,
  FallbackComponent: () => (
    <ErrorStateFallback
      sectionName="application details"
      justifyContent="center"
      alignItems="center"
      marginBottom="size-500"
      flexGrow={1}
    />
  ),
});
