{
  "alpha": "Alpha",
  "black": "musta",
  "blue": "Sininen",
  "blue purple": "sinivioletti",
  "brightness": "Kirkkaus",
  "brown": "ruskea",
  "brown yellow": "ruskeankeltainen",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "syaani",
  "cyan blue": "syaaninsininen",
  "dark": "tumma",
  "gray": "harmaa",
  "grayish": "harmahtava",
  "green": "Vihreä",
  "green cyan": "vihreänsyaani",
  "hue": "Sävy",
  "light": "vaalea",
  "lightness": "Valomäärä",
  "magenta": "magenta",
  "magenta pink": "magentapinkki",
  "orange": "oranssi",
  "orange yellow": "oranssinkeltainen",
  "pale": "vaalea",
  "pink": "pinkki",
  "pink red": "vaaleanpunainen",
  "purple": "violetti",
  "purple magenta": "violettimagenta",
  "red": "Punainen",
  "red orange": "punaoranssi",
  "saturation": "Värikylläisyys",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} läpinäkyvä",
  "very dark": "hyvin tumma",
  "very light": "erittäin vaalea",
  "vibrant": "eloisa",
  "white": "valkea",
  "yellow": "keltainen",
  "yellow green": "keltavihreä"
}
