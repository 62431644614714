//@ts-check
import React from 'react';
import { Helmet } from 'react-helmet';

import { useStore } from '../../../../store';
import { TABS_DEFINITION } from '../../../../utils/TabsDefinition';
import IntegrationsTable from './IntegrationsTable';

const IntegrationsList = () => {
  const orgName = useStore((state) => state.selectedOrg?.name);
  const pageTitle = `Manage ${TABS_DEFINITION.INTEGRATIONS.tabLabel}${
    orgName ? ` for ${orgName}` : ''
  } | Adobe Exchange`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="title" content={pageTitle} />
        <meta name="robots" content="noodp, FOLLOW, INDEX" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content="Service Principal, server-to-server integration, AEP Sources streaming connector"
        />
      </Helmet>
      <IntegrationsTable />
    </>
  );
};

export default IntegrationsList;
