{
  "alpha": "Alfa",
  "black": "čierna",
  "blue": "Modrá",
  "blue purple": "modrofialová",
  "brightness": "Jas",
  "brown": "hnedá",
  "brown yellow": "hnedožltá",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "azúrová",
  "cyan blue": "azúrová modrá",
  "dark": "tmavá",
  "gray": "sivá",
  "grayish": "sivastá",
  "green": "Zelená",
  "green cyan": "zelená azúrová",
  "hue": "Odtieň",
  "light": "svetlá",
  "lightness": "Svetlosť",
  "magenta": "purpurová",
  "magenta pink": "ružová purpurová",
  "orange": "oranžová",
  "orange yellow": "oranžovožltá",
  "pale": "bledá",
  "pink": "ružová",
  "pink red": "ružovočervená",
  "purple": "fialová",
  "purple magenta": "fialová purpurová",
  "red": "Červená",
  "red orange": "červenooranžová",
  "saturation": "Sýtosť",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} priehľadná",
  "very dark": "veľmi tmavá",
  "very light": "veľmi svetlá",
  "vibrant": "energická",
  "white": "biela",
  "yellow": "žltá",
  "yellow green": "žltozelená"
}
