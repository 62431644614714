{
  "alpha": "Alfa",
  "black": "preto",
  "blue": "Azul",
  "blue purple": "roxo azulado",
  "brightness": "Brilho",
  "brown": "marrom",
  "brown yellow": "marrom amarelado",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "ciano",
  "cyan blue": "azul-ciano",
  "dark": "escuro",
  "gray": "cinza",
  "grayish": "acinzentado",
  "green": "Verde",
  "green cyan": "verde-ciano",
  "hue": "Matiz",
  "light": "claro",
  "lightness": "Luminosidade",
  "magenta": "magenta",
  "magenta pink": "rosa-magenta",
  "orange": "laranja",
  "orange yellow": "amarelo alaranjado",
  "pale": "pálido",
  "pink": "rosa",
  "pink red": "rosa avermelhado",
  "purple": "roxo",
  "purple magenta": "roxo-magenta",
  "red": "Vermelho",
  "red orange": "laranja avermelhado",
  "saturation": "Saturação",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} transparente",
  "very dark": "muito escuro",
  "very light": "muito claro",
  "vibrant": "vibrante",
  "white": "branco",
  "yellow": "amarelo",
  "yellow green": "verde amarelado"
}
