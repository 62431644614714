import { Flex, Item, Picker, View } from '@adobe/react-spectrum';
import { SideNav, SideNavItem } from '@react/react-spectrum/SideNav';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStore } from '../../../../../store';

/**
 * Used ONLY for App Builder details page
 */
const ManageAppSideNav = ({ currentEnvId, currentOrgId, currentAppId }) => {
  const location = useLocation();
  const isEnvPages =
    location.pathname.includes('details') ||
    location.pathname.includes('deploy') ||
    location.pathname.includes('insights');
  const selectedItem = location.pathname.split('/').pop();
  const navigate = useNavigate();
  const ecAppsLoading = useStore((state) => state.ecAppsLoading);
  const stateEnvList = useStore((state) => state.envList);
  const envList = useMemo(() => stateEnvList?.[currentAppId] || null, [stateEnvList?.[currentAppId], currentAppId]);
  const app = useStore(
    useCallback(
      (state) => {
        if (!ecAppsLoading) {
          return state.allApps.find((obj) => obj.id === currentAppId) || null;
        }
        return null;
      },
      [currentAppId, ecAppsLoading]
    )
  );

  const handleNavigation = (e) => {
    switch (e.target.text) {
      case 'Environment details':
        navigate(`/manage/apps/ec/${currentOrgId}/ABD/view/${currentAppId}/${currentEnvId}/details`);
        break;
      case 'Configuration':
        navigate(`/manage/apps/ec/${currentOrgId}/ABD/view/${currentAppId}/${currentEnvId}/deploy`);
        break;
      case 'Insights':
        navigate(`/manage/apps/ec/${currentOrgId}/ABD/view/${currentAppId}/${currentEnvId}/insights`);
        break;
      default:
        navigate(`/manage/apps/ec/${currentOrgId}/ABD/view/${currentAppId}`);
    }
  };
  return (
    <>
      <SideNav maxWidth="size-6000" value={!isEnvPages ? 'app_details' : null}>
        <SideNavItem value="app_details" onClick={handleNavigation}>
          Application details
        </SideNavItem>
      </SideNav>

      {app && !app.jaeger && envList && (
        <Flex wrap="wrap" width="270px" marginTop="size-300" marginBottom="size-300">
          <View paddingStart="size-150" paddingEnd="0" paddingBottom="size-75" paddingTop="size-100">
            <strong>Environments:</strong>
          </View>
          <Picker
            aria-label="Environments"
            items={envList}
            placeholder={'Select an environment...'}
            width="size-3000"
            maxWidth="100%"
            isDisabled={envList.length === 0 || app.status === 'pending'}
            selectedKey={currentEnvId || null}
            onSelectionChange={(id) => {
              navigate(`/manage/apps/ec/${currentOrgId}/ABD/view/${currentAppId}/${id}/details`);
            }}
          >
            {(item) => <Item>{item?.name}</Item>}
          </Picker>
        </Flex>
      )}
      {isEnvPages && (
        <SideNav value={selectedItem}>
          <SideNavItem data-testid="details-tab" value="details" onClick={handleNavigation}>
            Environment details
          </SideNavItem>
          <SideNavItem data-testid="deploy-tab" value="deploy" onClick={handleNavigation}>
            Configuration
          </SideNavItem>
          <SideNavItem data-testid="insights-tab" value="insights" onClick={handleNavigation}>
            Insights
          </SideNavItem>
        </SideNav>
      )}
    </>
  );
};

export default ManageAppSideNav;
