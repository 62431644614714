import { Flex,  View } from '@adobe/react-spectrum';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  getProductsList,
  makeCCSupportedProductObjectsArray,
} from '@exchange-frontends/utils';
import { DynamicLengthListWithTooltip } from '@exchange-frontends/components';

import ProductElement from './ProductElement';
import PartnerBadge from './PartnerBadge';
import AppCard from './AppCard';
import CCAppCard from './CCAppCard' 

const CCFooter = ({ supportedProducts, productsLocs, selectedProduct }) => (
  <DynamicLengthListWithTooltip
    list={makeCCSupportedProductObjectsArray(supportedProducts, productsLocs, selectedProduct)?.map(
      ({ key, name }, idx) => (
        <ProductElement key={key} code={key} name={name} useTooltip={idx < 3} />
      )
    )}
    amountShown={3}
    tooltipVariant="inspect"
    tooltipTriggerTextColor="gray-800"
    tooltipBgColor="gray-600"
  />
);

const ECFooter = ({ id, hostApps, optionalProducts, productsLocs, product }) => {
  const isABD = id.startsWith('ab');

  const products = (
    <DynamicLengthListWithTooltip
      list={getProductsList(hostApps, optionalProducts, productsLocs, product)}
      amountShown={1}
    />
  );

  if (isABD) {
    return (
      <Flex gap="size-200">
        <ProductElement code="ABD" name="App Builder application" useTooltip={true} data-testid="product-icon" />
        {products}
      </Flex>
    );
  } else {
    return products;
  }
};

const ECContent = ({ summary, price, subscriptionTenure }) => {
  const shouldShowPrice = price !== undefined;

  if (!shouldShowPrice) {
    return summary || '';
  }

  const isFree = price === '0.00';
  const priceString = subscriptionTenure ? `$${price}/${subscriptionTenure}` : `$${price}`;

  return (
    <Flex direction="column" gap="size-200">
      <View>{summary}</View>
      <View alignSelf="end">{isFree ? 'Free' : priceString}</View>
    </Flex>
  );
};


/**
 * @param {object} props
 * @param {'cc'|'ec'|'dc'} [props.cloud]
 * @param {import('@action-types/ccApps').CcAppItem | import('@action-types/ecApp').AppCardDetails} [props.app]
 * @param {boolean} [props.loading]
 * @param {string} [props.GoldBadge] - The URL of the Gold badge image
 * @param {string} [props.PlatinumBadge] - The URL of the Platinum badge image
 * @param {string} [props.product] - If provided, will make this product show first in the product list
 * @param {Map<string, string>} [props.productsLocs] - JS Map of SAP codes to names
 * @param {string} [props.headerId] - The id of the header for the card
 * @param {boolean} [props.shouldShowStars] - Whether to show the stars
 * @param {boolean} [props.shouldShowPrice] - Whether to show the price
 */
const CloudAppCard = ({
  cloud,
  app,
  loading,
  productsLocs,
  product,
  headerId,
  shouldShowStars = true,
  shouldShowPrice = true,
  listingType,
  appType
}) => {
  const CardComponent = cloud === 'cc' ? CCAppCard : AppCard;
  const { search } = useLocation();
  const selectedProduct = qs.parse(search).product;

  const price = app?.price ? Number.parseFloat(app?.price).toFixed(2) : undefined;

  const subscriptionTenure = app?.subscription?.days === 30 ? 'mo' : app?.subscription?.days === 365 ? 'yr' : undefined;

  // This is existing condition for hiding the silver badge in case of EC apps(except SLN)
  const shouldHideSilverBadge = app?.publisher?.level?.toLowerCase() === 'silver' && app?.appType !== 'SLN';

  const getBadgeType = () => {
    if (app?.appType === "SLN" && app?.badges?.length > 0) {
      return app.badges[0];
    }
    return app?.isVerified ? "ASSURED" : undefined;
  };

  const getCardContent = () => {
    if (!app) {
      return null;
    }

    if (cloud === 'cc') {
      return app?.summary;
    }

    return <ECContent summary={app?.summary} price={price} subscriptionTenure={subscriptionTenure} />;
  };

  const getCardFooter = () => {
    if (!app) {
      return null;
    }

    if (cloud === 'cc') {
      return (
        <CCFooter
          supportedProducts={app?.supportedProducts}
          productsLocs={productsLocs}
          selectedProduct={selectedProduct}
        />
      );
    }

    if (cloud === 'ec' || cloud === 'dc') {
      return (
        <ECFooter
          id={app?.id}
          hostApps={app?.hostApps}
          optionalProducts={app?.optionalProducts}
          productsLocs={productsLocs}
          product={product}
        />
      );
    }
  };

  return (
    <CardComponent cardWidth="100%">
      <CardComponent.Header
        icon={app?.assets.find((a) => a?.type === 'icon')?.url}
        title={app?.name}
        subtitle={app?.publisher?.name}
        badgeType={getBadgeType()}
        appId={app?.id}
        listingType={listingType}
        publisherId={app?.publisher?.id}
        loading={loading}
        publisher = {app?.publisher}
        id={headerId}
        cloud={app?.type}
        appType={appType}
      />
      <CardComponent.Body
        loading={loading}
        rating={app?.rating}
        price={price}
        hasTrial={app?.hasTrial}
        shouldShowPrice={shouldShowPrice}
        shouldShowStars={shouldShowStars}
        subscriptionTenure={subscriptionTenure}
        requiresPayment={app?.requiresPayment}
      >
        {getCardContent()}
      </CardComponent.Body>
      <CardComponent.Footer loading={loading}>
        {getCardFooter()}
        {!shouldHideSilverBadge && <PartnerBadge level={app?.publisher?.level} appType={app?.appType} />}
      </CardComponent.Footer>
    </CardComponent>
  );
};

export default CloudAppCard;
