// @ts-check
import { Link } from '@adobe/react-spectrum';
import React from 'react';
import { useErrorBoundary } from 'react-error-boundary';

/**
 * A "try again" link that is used to reset the error boundary.
 */
const BoundaryTryAgainLink = () => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <Link isQuiet onPress={resetBoundary}>
      try again
    </Link>
  );
};

export default BoundaryTryAgainLink;
