import React from 'react';

const AssuranceProgramSVG = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 145.4 70.5"
      style={{ enableBackground: 'new 0 0 145.4 70.5' }}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`.st0{fill:#FFFFFF;stroke:#0265DC;stroke-width:1.4232;stroke-miterlimit:10;}
.st1{fill:#0265DC;}`}
      </style>
      <g>
        <path
          className="st0"
          d="M137.6,69.8H7.8c-3.9,0-7.1-3.2-7.1-7.1l0-54.9c0-3.9,3.2-7.1,7.1-7.1l129.8,0c3.9,0,7.1,3.2,7.1,7.1v54.9C144.7,66.6,141.5,69.8,137.6,69.8z"
        />
        <g>
          <path
            className="st1"
            d="M23,28.3L21.7,32c0,0.1-0.1,0.2-0.3,0.2h-2.9c-0.2,0-0.2-0.1-0.2-0.3l5-14.5c0.1-0.3,0.2-0.6,0.3-1.5c0-0.1,0.1-0.2,0.2-0.2h4c0.1,0,0.2,0,0.2,0.2L33.7,32c0,0.1,0,0.2-0.1,0.2h-3.3c-0.1,0-0.2,0-0.3-0.2l-1.3-3.7H23z M27.8,25.1c-0.5-1.7-1.5-4.7-2-6.5h0c-0.4,1.7-1.3,4.5-2,6.5H27.8z"
          />
          <path
            className="st1"
            d="M39.6,22.2c-1.2,0-1.6,0.4-1.6,0.9c0,0.4,0.3,0.8,1.8,1.3c3,1.1,4,2.3,4,4.1c0,2.4-1.9,4-5.2,4c-1.5,0-2.9-0.3-3.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.3v-2.7c0-0.2,0.1-0.2,0.2-0.2c1.2,0.7,2.6,1,3.7,1c1.2,0,1.7-0.3,1.7-0.9c0-0.5-0.4-0.8-1.9-1.4c-2.8-1.1-3.8-2.3-3.8-4.1c0-2,1.6-3.9,4.9-3.9c1.5,0,2.6,0.2,3.3,0.5c0.2,0.1,0.2,0.2,0.2,0.3v2.5c0,0.2-0.1,0.3-0.3,0.2C42.1,22.5,40.8,22.2,39.6,22.2z"
          />
          <path
            className="st1"
            d="M50.1,22.2c-1.2,0-1.6,0.4-1.6,0.9c0,0.4,0.3,0.8,1.8,1.3c3,1.1,4,2.3,4,4.1c0,2.4-1.9,4-5.2,4c-1.5,0-2.9-0.3-3.6-0.7c-0.1-0.1-0.2-0.2-0.2-0.3v-2.7c0-0.2,0.1-0.2,0.2-0.2c1.2,0.7,2.6,1,3.7,1c1.2,0,1.7-0.3,1.7-0.9c0-0.5-0.4-0.8-1.9-1.4c-2.8-1.1-3.8-2.3-3.8-4.1c0-2,1.6-3.9,4.9-3.9c1.5,0,2.6,0.2,3.3,0.5c0.2,0.1,0.2,0.2,0.2,0.3v2.5c0,0.2-0.1,0.3-0.3,0.2C52.6,22.5,51.3,22.2,50.1,22.2z"
          />
          <path
            className="st1"
            d="M67.3,29.4c0,1.3,0.1,2.1,0.2,2.6c0,0.2,0,0.2-0.2,0.2h-2.7c-0.2,0-0.3,0-0.3-0.2c-0.1-0.2-0.1-0.6-0.2-0.8c-1.1,1-2.4,1.2-3.4,1.2c-2.5,0-4.3-1.4-4.3-4.9v-7.7c0-0.2,0-0.2,0.2-0.2h3.1c0.2,0,0.2,0.1,0.2,0.2v7.3c0,1.4,0.6,2.2,2,2.2c0.8,0,1.4-0.3,2-0.7v-8.8c0-0.2,0.1-0.2,0.3-0.2h3c0.2,0,0.2,0.1,0.2,0.2V29.4z"
          />
          <path
            className="st1"
            d="M73,19.7c0.2,0,0.3,0.1,0.3,0.2c0.1,0.3,0.3,0.9,0.3,1.4c0.8-1,2.2-1.9,4-1.9c0.2,0,0.2,0.1,0.2,0.2v3.1c0,0.1,0,0.2-0.2,0.2c-1.8-0.1-3.3,0.5-3.8,1.1l0,8c0,0.2,0,0.2-0.2,0.2h-3c-0.2,0-0.3-0.1-0.3-0.2v-8.7c0-1.1,0-2.5-0.1-3.4c0-0.1,0-0.2,0.1-0.2H73z"
          />
          <path
            className="st1"
            d="M88.8,29.4c0,0.7,0,1.2,0,1.8c0,0.1,0,0.1,0,0.1c-2.2,0.9-3.7,1.1-5.5,1.1c-2.8,0-5.1-1.3-5.1-4.2c0-2.7,2.3-4.1,5.7-4.1c0.7,0,1.3,0,1.5,0.1V24c0-0.5-0.3-1.6-2.4-1.6c-1.3,0-2.4,0.3-3.3,0.7c-0.1,0.1-0.2,0-0.2-0.1v-2.5c0-0.1,0-0.2,0.2-0.3c1.1-0.5,2.5-0.8,3.9-0.8c3.6,0,5.2,2.2,5.2,5.1V29.4z M85.4,26.8c-0.4,0-0.8-0.1-1.3-0.1c-1.8,0-2.5,0.6-2.5,1.5c0,0.8,0.6,1.5,2.2,1.5c0.6,0,1.1-0.1,1.6-0.2V26.8z"
          />
          <path
            className="st1"
            d="M91.6,22.6c0-0.9-0.1-2.2-0.1-2.7c0-0.1,0-0.2,0.2-0.2h2.6c0.2,0,0.3,0.1,0.3,0.2c0.1,0.2,0.2,0.5,0.2,0.8c1.1-0.9,2.2-1.3,3.6-1.3c1.9,0,4.2,1,4.2,5.1V32c0,0.2,0,0.2-0.2,0.2h-3c-0.2,0-0.3-0.1-0.3-0.2v-7.2c0-1.5-0.6-2.3-2-2.3c-0.9,0-1.6,0.3-2.1,0.8V32c0,0.1-0.1,0.2-0.2,0.2h-3c-0.2,0-0.2-0.1-0.2-0.2V22.6z"
          />
          <path
            className="st1"
            d="M114.4,31.8c0,0.1,0,0.2-0.1,0.2c-0.8,0.3-1.8,0.4-2.8,0.4c-4.1,0-6.6-2.7-6.6-6.4c0-3.8,2.8-6.6,7.1-6.6c1.2,0,1.9,0.1,2.3,0.3c0.1,0.1,0.1,0.1,0.1,0.3l0,2.6c0,0.2-0.1,0.2-0.2,0.2c-0.5-0.2-1.2-0.3-2.1-0.3c-2.1,0-3.7,1.2-3.7,3.4c0,2.6,1.9,3.5,3.7,3.5c0.9,0,1.7-0.1,2.1-0.2c0.1,0,0.2,0,0.2,0.1V31.8z"
          />
          <path
            className="st1"
            d="M119.3,26.9c0.1,1.4,1.1,2.6,3.6,2.6c1.1,0,2.1-0.2,3.1-0.6c0.1,0,0.2,0,0.2,0.1v2.4c0,0.2-0.1,0.3-0.2,0.3c-1,0.5-2.1,0.7-3.9,0.7c-4.7,0-6.4-3.2-6.4-6.4c0-3.5,2.1-6.6,6.1-6.6c3.8,0,5.3,2.9,5.3,5.4c0,0.8,0,1.4-0.1,1.7c0,0.1-0.1,0.2-0.2,0.2c-0.3,0-1.3,0.1-2.6,0.1H119.3z M122.5,24.4c0.8,0,1.2,0,1.3,0c0,0,0-0.2,0-0.2c0-0.6-0.5-2-2.1-2c-1.6,0-2.3,1.2-2.4,2.2H122.5z"
          />
          <path
            className="st1"
            d="M20.1,38.3c0-0.2,0-0.2,0.2-0.2c1,0,3-0.1,4.9-0.1c5.1,0,6.8,2.8,6.8,5.6c0,4.1-3.1,5.8-7,5.8c-0.7,0-0.8,0-1.4,0v5c0,0.1,0,0.2-0.2,0.2h-3.1c-0.1,0-0.2-0.1-0.2-0.2V38.3z M23.6,46.3c0.4,0,0.7,0,1.4,0c2,0,3.6-0.6,3.6-2.7c0-1.6-1.1-2.6-3.3-2.6c-0.8,0-1.5,0-1.7,0V46.3z"
          />
          <path
            className="st1"
            d="M36.8,42.1c0.2,0,0.3,0.1,0.3,0.2c0.1,0.3,0.3,0.9,0.3,1.4c0.8-1,2.2-1.9,4-1.9c0.2,0,0.2,0,0.2,0.2v3.1c0,0.1,0,0.2-0.2,0.2c-1.8-0.1-3.3,0.5-3.8,1.1l0,8c0,0.2,0,0.2-0.2,0.2h-3c-0.2,0-0.3-0.1-0.3-0.2v-8.7c0-1.1,0-2.5-0.1-3.4c0-0.1,0-0.2,0.1-0.2H36.8z"
          />
          <path
            className="st1"
            d="M54.8,48.2c0,3.9-2.6,6.6-6.2,6.6c-4.3,0-6.2-3.2-6.2-6.5c0-3.6,2.4-6.5,6.3-6.5C52.8,41.8,54.8,44.9,54.8,48.2z M46,48.3c0,2.2,1,3.6,2.7,3.6c1.5,0,2.6-1.3,2.6-3.6c0-2-0.8-3.6-2.7-3.6C47.1,44.7,46,46,46,48.3z"
          />
          <path
            className="st1"
            d="M63.6,51.6c3.6,0.2,4.9,1.6,4.9,3.6c0,2.6-2.6,4.7-6.6,4.7c-4.2,0-6-2.1-6-3.9c0-0.8,0.7-1.8,2.3-3.2c-0.4-0.6-0.6-1.2-0.5-1.9c0-0.6,0.2-1.2,0.5-1.7c-1-0.7-1.7-1.7-1.7-3.2c0-2.4,1.9-4.3,5.2-4.3c0.9,0,1.8,0.1,2.4,0.2c0.2,0,0.4,0,0.6,0H68c0.1,0,0.2,0,0.2,0.2v2.3c0,0.1,0,0.2-0.2,0.2c-0.4,0-1.2-0.1-1.7-0.1c0.2,0.4,0.4,0.9,0.4,1.6c0,2.7-2.2,4-5,4c-0.3,0-0.6,0-0.9-0.1c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.2,0.1,0.5,0.3,0.7L63.6,51.6z M60,54.5c-0.6,0.4-1,1.1-1,1.3c0,0.8,1.1,1.6,3.1,1.6c2.2,0,3.2-0.8,3.2-1.6c0-0.9-1.1-1.2-2.5-1.3L60,54.5z M59.8,46.1c0,1,0.6,1.7,1.8,1.7c1.1,0,1.7-0.6,1.7-1.6c0-1.1-0.5-1.8-1.8-1.8C60.5,44.3,59.8,44.9,59.8,46.1z"
          />
          <path
            className="st1"
            d="M72.8,42.1c0.2,0,0.3,0.1,0.3,0.2c0.1,0.3,0.3,0.9,0.3,1.4c0.8-1,2.2-1.9,4-1.9c0.1,0,0.2,0,0.2,0.2v3.1c0,0.1,0,0.2-0.2,0.2c-1.8-0.1-3.3,0.5-3.8,1.1l0,8c0,0.2,0,0.2-0.2,0.2h-3c-0.2,0-0.3-0.1-0.3-0.2v-8.7c0-1.1,0-2.5-0.1-3.4c0-0.1,0-0.2,0.1-0.2H72.8z"
          />
          <path
            className="st1"
            d="M88.7,51.8c0,0.7,0,1.2,0,1.8c0,0.1,0,0.1,0,0.1c-2.2,0.9-3.7,1.1-5.5,1.1c-2.8,0-5.1-1.3-5.1-4.2c0-2.7,2.3-4.1,5.7-4.1c0.7,0,1.3,0,1.5,0.1v-0.2c0-0.5-0.3-1.6-2.4-1.6c-1.3,0-2.4,0.3-3.3,0.7c-0.1,0.1-0.2,0-0.2-0.1v-2.5c0-0.1,0-0.2,0.2-0.3c1.1-0.5,2.5-0.8,3.9-0.8c3.6,0,5.2,2.2,5.2,5.1V51.8z M85.3,49.2c-0.4,0-0.8-0.1-1.3-0.1c-1.8,0-2.5,0.6-2.5,1.5c0,0.8,0.6,1.5,2.2,1.5c0.6,0,1.1-0.1,1.6-0.2V49.2z"
          />
          <path
            className="st1"
            d="M91.7,54.6c-0.2,0-0.2-0.1-0.2-0.2v-9.6c0-0.9-0.1-2-0.1-2.5c0-0.1,0-0.2,0.2-0.2h2.7c0.1,0,0.2,0,0.3,0.2c0.1,0.2,0.1,0.5,0.2,0.9c1.2-1,2.3-1.3,3.6-1.3c1.5,0,2.5,0.6,3.2,1.6c1.1-0.9,2.4-1.6,4.1-1.6c2.2,0,4.3,1.2,4.3,5v7.6c0,0.2-0.1,0.2-0.2,0.2h-3c-0.2,0-0.3-0.1-0.3-0.2v-7.2c0-1.3-0.5-2.3-1.9-2.3c-0.8,0-1.6,0.3-2.1,0.8c0.1,0.3,0.1,0.9,0.1,1.4v7.3c0,0.1,0,0.2-0.2,0.2h-3c-0.2,0-0.3,0-0.3-0.2V47c0-1.5-0.7-2.2-1.9-2.2c-0.8,0-1.4,0.2-2.1,0.7v8.8c0,0.1,0,0.2-0.2,0.2H91.7z"
          />
        </g>
      </g>
    </svg>
  );
};
export default AssuranceProgramSVG;
