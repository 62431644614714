import { ActionButton, Content, Dialog, DialogTrigger, Heading, Text } from '@adobe/react-spectrum';
import React from 'react';

import { useMediaQuery, useStaticData } from '@exchange-frontends/custom-hooks';

import { getProductsList } from '../../../../../utils/ECAppsExtensionsHelper';
import { FormattedCell, ProductsCell } from './StyledComponents';

const ProductCell = ({ item }) => {
  const isMobileSized = useMediaQuery('(max-width: 800px)');
  const hostApps = useStaticData('hostApps');
  const mapHostApps = (key) => {
    const appInfo = hostApps ? hostApps.get(key) : null;
    return appInfo ? appInfo.label : key;
  };

  const getProductsCount = (app) => {
    const products = getProductsList(app);
    return products?.length || 0;
  };

  const getPrimaryProduct = (app) => {
    const prodString = getProductsList(app);
    return app?.jaeger ? prodString[0] : mapHostApps(prodString[0]);
  };
  const getSecondaryProducts = (app) => {
    let prodString = getProductsList(app);
    if (prodString.length > 1) {
      prodString = prodString.slice(1);
    }
    const data = app?.jaeger ? prodString : prodString.map((product) => mapHostApps(product));
    return data.join(', ');
  };

  return (
    <FormattedCell
      $isMobileSized={isMobileSized}
      justifyContent={getProductsCount(item) > 1 ? 'space-around' : 'flex-start'}
    >
      <ProductsCell data-testid="EC_APPS_TABLE_ROW__PRODUCT">{getPrimaryProduct(item)}</ProductsCell>
      {getProductsCount(item) > 1 && (
        <DialogTrigger type="popover" hideArrow="true">
          <ActionButton minWidth="size-560" data-testid="EC_APPS_TABLE_ROW__MORE_PRODUCTS_BUTTON">
            +{getProductsCount(item) - 1}
          </ActionButton>
          <Dialog>
            <Heading>Products</Heading>
            <Content>
              <Text>{getSecondaryProducts(item)}</Text>
            </Content>
          </Dialog>
        </DialogTrigger>
      )}
    </FormattedCell>
  );
};

export default ProductCell;
