//@ts-check
import { entitlementApiClient } from '../../utils/RequestClient';
import RequestSearchParams from '../../utils/RequestSearchParams';

/**
 * @param {object} params
 * @param {string} params.orgId
 * @param {string} params.token
 * @param {string[]} params.appIds
 * @param {AbortSignal} [params.signal]
 * @returns {Promise<import('@action-types/consent').ConsentsResponse>}
 * @throws {import('@action-types/RequestError').RequestError<import('@action-types/entitlement').EntitlementApiErrorResponse>}
 */
const getConsent = async ({ signal, token, orgId, appIds }) => {
  const appId = appIds.join(',');
  const searchParams = new RequestSearchParams({ appId });
  const headers = {
    'x-org-id': orgId,
    authorization: token,
  };
  const { jsonResponse } = await entitlementApiClient.fetch({
    endpoint: `/enterprise/consent${searchParams}`,
    requestOptions: {
      signal,
      headers,
    },
  });
  return jsonResponse;
};

export default getConsent;
