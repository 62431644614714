//@ts-check
import { myxchngApiClient } from '../../utils/RequestClient';
import RequestSearchParams from '../../utils/RequestSearchParams';

/**
 * @param {object} params
 * @param {string} params.appId
 * @param {string} params.orgId
 * @param {string} params.token
 * @returns {Promise<string>}
 * @throws {import('@action-types/RequestError').RequestError<import('@action-types/ecApp').MyxchngApiErrorResponse>}
 */
const generateStateToken = async ({ token, appId, orgId }) => {
  const searchParams = new RequestSearchParams({ appId, orgId });
  const { textResponse } = await myxchngApiClient.fetch({
    endpoint: `/generateStateToken${searchParams}`,
    requestOptions: {
      parseBodyOnSuccess: false,
      parseBodyOnError: true,
      headers: {
        authorization: `Bearer ${token}`,
        accept: 'application/json, text/plain',
      },
    },
  });
  return textResponse;
};

export default generateStateToken;
