{
  "alpha": "Alfa",
  "black": "nero",
  "blue": "Blu",
  "blue purple": "blu viola",
  "brightness": "Luminosità",
  "brown": "marrone",
  "brown yellow": "giallo bruno",
  "colorName": "{hue} {chroma} {lightness}",
  "cyan": "ciano",
  "cyan blue": "blu ciano",
  "dark": "scuro",
  "gray": "grigio",
  "grayish": "grigiastro",
  "green": "Verde",
  "green cyan": "verde ciano",
  "hue": "Tonalità",
  "light": "chiaro",
  "lightness": "Luminosità",
  "magenta": "magenta",
  "magenta pink": "rosa magenta",
  "orange": "arancio",
  "orange yellow": "giallo arancio",
  "pale": "tenue",
  "pink": "rosa",
  "pink red": "rosa rosso",
  "purple": "viola",
  "purple magenta": "viola magenta",
  "red": "Rosso",
  "red orange": "rosso arancio",
  "saturation": "Saturazione",
  "transparentColorName": "{hue} {chroma} {lightness}, trasparenza {percentTransparent}",
  "very dark": "molto scuro",
  "very light": "molto chiaro",
  "vibrant": "vivace",
  "white": "bianco",
  "yellow": "giallo",
  "yellow green": "giallo verde"
}
