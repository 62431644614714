import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Image,
  ProgressCircle,
  Text,
  TextField,
  View,
} from '@adobe/react-spectrum';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStore } from '../../../store';
import { CONSENT_ACTIONS } from '../../../utils/Constants';
import { getIconUrl } from '../../../utils/ECAppsExtensionsHelper';
import { UserDataContext } from '../../App';
import { ImageContainer } from './ManageCard';
import { ErrorContainer } from './ReviewDialog';
import ExtensionDetails from './ec/common/ExtensionDetails';

const RevokeDialog = ({ app, close, appBuilderExtensionPoints, redirectHandler, ...rest }) => {
  // Custom Hooks and Hooks
  const retractXRApp = useStore((state) => state.retractXRApp);
  const updateConsent = useStore((state) => state.updateConsent);
  const [revoking, setRevoking] = useState(false);
  const [error, setError] = useState(null);
  const [nameTyped, setNameTyped] = useState('');
  const [nameError, setNameError] = useState(false);
  const [isRevokeAvailable, setIsRevokeAvailable] = useState(false);
  const fetchCurrentApp = useStore((state) => state.fetchCurrentApp);
  const {
    accessToken,
    profile: { email, displayName },
  } = useContext(UserDataContext);
  const { orgId } = useParams();
  const name = app.title || app.name;
  // Review Use Effect
  useEffect(() => {
    if (revoking) {
      setError(null);
      if (app?.jaeger) {
        retractXRApp(accessToken, orgId, app.id, email, displayName, redirectHandler, close).then((response) => {
          if (!response.ok) {
            setRevoking(false);
            setError(response.error);
          }
        });
      } else {
        let appVersionId = app.appVersionId;
        if (!appVersionId) {
          fetchCurrentApp(accessToken, orgId, app.id)
            .then((response) => {
              if (response.ok) {
                appVersionId = response.currentApp?.appVersionId;
              }
            })
            .finally(() => {
              updateConsent(accessToken, orgId, app.id, appVersionId, CONSENT_ACTIONS.DENY, '', close).then(
                (response) => {
                  if (!response.ok) {
                    setRevoking(false);
                    setError(response.error);
                  } else {
                    redirectHandler && redirectHandler();
                  }
                }
              );
            });
        } else {
          updateConsent(
            accessToken,
            orgId,
            app.id,
            appVersionId,
            CONSENT_ACTIONS.DENY,
            '',
            close,
            'Application revoked'
          ).then((response) => {
            if (!response.ok) {
              setRevoking(false);
              setError(response.error);
            } else {
              redirectHandler && redirectHandler();
            }
          });
        }
      }
    }
  }, [revoking]);

  useEffect(() => {
    let isDisabled = revoking;
    if (!app?.jaeger) {
      setIsRevokeAvailable(revoking);
      isDisabled = revoking || !!(!nameTyped || nameError);
    }
    setIsRevokeAvailable(isDisabled);
  }, [app?.jaeger, nameError, nameTyped, revoking]);

  const handleNameTyped = (value) => {
    setNameTyped(value);
    if (value !== name) {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  return (
    <Dialog {...rest} maxWidth="500px" width="90vw" data-testid="revoke-dialog">
      <Heading>Revoke this app</Heading>
      <Divider />
      <Content>
        {!app.jaeger && (
          <View marginBottom="size-200" width="100%">
            All installations of this application will be undeployed. To use this application again, you will need an
            approval from your system administrator.
          </View>
        )}
        <Flex wrap="wrap" width="100%" gap="size-100">
          <Flex alignItems="center" justifyContent="center">
            <ImageContainer style={{ display: 'flex', alignItems: 'center' }}>
              <Image alt="App icon" width="40px" height="40px" src={getIconUrl(app)} />
            </ImageContainer>
            <View marginEnd="auto">
              <Heading margin="0">{name}</Heading>
              <ExtensionDetails app={app} appBuilderExtensionPoints={appBuilderExtensionPoints} />
            </View>
          </Flex>
          <Flex width="100%" wrap="wrap">
            <Text width="100%">{app.description}</Text>
            {error && <ErrorContainer>{error}</ErrorContainer>}
          </Flex>
        </Flex>
        {!app.jaeger && (
          <>
            <Text marginTop="size-350" marginBottom="size-125">
              <strong>This action can not be undone.</strong>
            </Text>
            <p>To revoke this app, type in the application name.</p>
            <TextField
              width="100%"
              label="App name"
              isRequired="true"
              aria-invalid="true"
              id="appName"
              aria-describedby="appNameError"
              aria-required="true"
              value={nameTyped}
              autoComplete="off"
              placeholder="Type in app name"
              validationState={nameError ? 'invalid' : undefined}
              onChange={handleNameTyped}
              autoFocus
            />
          </>
        )}
      </Content>
      <ButtonGroup>
        <Button onPress={close} variant="primary" isDisabled={!!revoking} data-launchid="Cancel app revoke">
          Cancel
        </Button>
        <Button
          variant="negative"
          isDisabled={isRevokeAvailable}
          onPress={() => setRevoking(true)}
          data-launchid="Revoke app"
        >
          Revoke
          {revoking && <ProgressCircle size="S" isIndeterminate aria-label="Revoking the app" marginStart="size-150" />}
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default RevokeDialog;
