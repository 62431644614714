//@ts-check
import { ActionButton, DialogTrigger, Flex, Text } from '@adobe/react-spectrum';
import Document from '@spectrum-icons/workflow/Document';
import Help from '@spectrum-icons/workflow/Help';
import Search from '@spectrum-icons/workflow/Search';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEnterpriseEntitlement from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useEnterpriseEntitlement';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { ROUTES } from '../../../../../../constants';
import GetSupportDialog from '../../../../../components/GetSupportDialog';
import IntegrationActionMenu from '../../../common/IntegrationActionMenu';
import DocumentationDialog from './DocumentationDialog';
import IntegrationBreadcrumbs from './IntegrationBreadcrumbs';

const IntegrationDetailsHeader = () => {
  const navigate = useNavigate();
  const { appId } = useParams();

  const appDetails = useEcAppDetails();
  const entitlement = useEnterpriseEntitlement();
  const consent = useConsent();

  return (
    <Flex wrap="wrap-reverse" gap="size-200" alignItems="end">
      <IntegrationBreadcrumbs />
      <Flex wrap gap="size-100" justifyContent="end" flexGrow={1}>
        <DialogTrigger>
          <ActionButton>
            <Help />
            <Text>Get support</Text>
          </ActionButton>
          {(close) => (
            <GetSupportDialog
              showContactPublisher
              appId={appId}
              support={appDetails?.data?.support}
              close={close}
              appType={appDetails.data?.appType}
            />
          )}
        </DialogTrigger>
        <DialogTrigger>
          <ActionButton>
            <Document />
            <Text>View documentation</Text>
          </ActionButton>
          {(close) => <DocumentationDialog close={close} />}
        </DialogTrigger>
        <ActionButton onPress={() => navigate(`${ROUTES.DETAILS}/ec/${appId}`)}>
          <Search />
          <Text>View listing</Text>
        </ActionButton>
        <IntegrationActionMenu
          type="details"
          data={{ appDetails: appDetails.data, entitlement: entitlement.data, consent: consent.data?.consent }}
        />
      </Flex>
    </Flex>
  );
};

export default IntegrationDetailsHeader;
