{
  "alpha": "Άλφα",
  "black": "μαύρο",
  "blue": "Μπλε",
  "blue purple": "μπλε μωβ",
  "brightness": "Φωτεινότητα",
  "brown": "καφέ",
  "brown yellow": "καφέ κίτρινο",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "κυανό",
  "cyan blue": "κυανό μπλε",
  "dark": "σκούρο",
  "gray": "γκρι",
  "grayish": "γκριζωπό",
  "green": "Πράσινο",
  "green cyan": "πράσινο κυανό",
  "hue": "Τόνος",
  "light": "ανοιχτό",
  "lightness": "Φωτεινότητα",
  "magenta": "ματζέντα",
  "magenta pink": "ματζέντα ροζ",
  "orange": "πορτοκαλί",
  "orange yellow": "πορτοκαλί κίτρινο",
  "pale": "ανοιχτό",
  "pink": "ροζ",
  "pink red": "ροζ κόκκινο",
  "purple": "μωβ",
  "purple magenta": "μωβ ματζέντα",
  "red": "Κόκκινο",
  "red orange": "κόκκινο πορτοκαλί",
  "saturation": "Κορεσμός",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} διαφανές",
  "very dark": "πολύ σκούρο",
  "very light": "πολύ ανοιχτό",
  "vibrant": "έντονο",
  "white": "λευκό",
  "yellow": "κίτρινο",
  "yellow green": "κίτρινο πράσινο"
}
