// @ts-check

export const PUBLISHER_TAB_MAP = Object.freeze({
  APPLICATIONS: {
    id: 'applications',
    name: 'Applications',
  },
  SOLUTIONS: {
    id: 'solutions',
    name: 'Solutions',
  },
});
