import { Flex, Text } from '@adobe/react-spectrum';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { useAnalyticsPageLoad } from '@exchange-frontends/custom-hooks';
import { managePage, parseFromLocalStorage } from '@exchange-frontends/utils';

import { ROUTES } from '../../../../../constants';
import { NotFoundMessage } from '../../../../components/NotFoundMessage';
import { useStore } from '../../../store';
import { TABS_DEFINITION } from '../../../utils/TabsDefinition';
import AppsTable from './ec/AppsTable';

// Selectors
// These are here so that they are not re-declared on every render
const ecAppsLoadingSelector = (state) => state.ecAppsLoading;
const abdExtPointsSelector = (state) => state.appBuilderExtensionPoints;

const EcAppsList = () => {
  const selectedOrg = useStore((state) => state.selectedOrg);
  const ecAppsLoading = useStore(ecAppsLoadingSelector);
  const appBuilderExtensionPoints = useStore(abdExtPointsSelector);
  const orgs = parseFromLocalStorage('orgs');

  const apps = useStore(
    useCallback((state) => {
      let apps = Array(5).fill(null);
      if (!state.ecAppsLoading) {
        apps = state.allApps;
      }
      return apps;
    }, [])
  );

  useAnalyticsPageLoad(managePage, !ecAppsLoading, apps?.length);

  const pageTitle = `Manage ${TABS_DEFINITION.APP_BUILDER.tabLabel} for ${selectedOrg?.name ?? ''} | Adobe Exchange`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="robots" content="noodp, FOLLOW, INDEX" />
        <meta name="title" content={pageTitle} />
        <meta property="og:title" content="" />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="og:type" content="website" />
        <meta name="keywords" content="" />
      </Helmet>
      {selectedOrg && apps?.length ? (
        <Flex width="100%" justifyContent="space-between" alignItems="center" marginBottom="size-200">
          <Text>
            {ecAppsLoading ? (
              `Loading apps`
            ) : (
              <>
                {apps?.length} {'app'}
                {apps?.length > 1 ? 's' : ''}
              </>
            )}{' '}
            <>
              in <strong>{selectedOrg?.name}</strong>
            </>
          </Text>
          <Flex
            width="50%"
            justifyContent="flex-end"
            alignItems="center"
            marginBottom="size-200"
            gap={'size-100'}
          ></Flex>
        </Flex>
      ) : null}
      <Flex alignItems="center" direction="column">
        {/*render ec apps table view*/}
        {apps?.length > 0 && orgs?.length > 0 && (
          <AppsTable
            apps={apps}
            loading={ecAppsLoading}
            appBuilderExtensionPoints={appBuilderExtensionPoints}
            selectedOrg={selectedOrg}
          />
        )}
        {/*@todo render ec apps card/grid view - SITES-12823 (never)*/}
        {!apps?.length || !orgs?.length ? (
          <NotFoundMessage
            headingProps={{ maxWidth: '600px' }}
            heading={['No App Builder applications to manage.', 'Looking for your acquired App Builder application?']}
            contentProps={{ marginTop: 'size-150' }}
            content="You might have acquired the application using a different organization. Try signing out and then sign back in with the organization you used to acquire the application. Only enterprise organizations can acquire, view and manage App Builder applications."
            link={{
              text: 'Explore hundreds of Experience Cloud service integrations and App Builder applications',
              href: ROUTES.BROWSE + '/ec',
            }}
            linkProps={{ marginTop: 'size-150' }}
          />
        ) : null}
      </Flex>
    </>
  );
};

export default EcAppsList;
