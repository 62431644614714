{
  "alpha": "Alfa",
  "black": "must",
  "blue": "Sinine",
  "blue purple": "sinakaslilla",
  "brightness": "Heledus",
  "brown": "pruun",
  "brown yellow": "pruunikaskollane",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "tsüaan",
  "cyan blue": "tsüaansinine",
  "dark": "tume",
  "gray": "hall",
  "grayish": "hallikas",
  "green": "Roheline",
  "green cyan": "tsüaanroheline",
  "hue": "Värv",
  "light": "valgus",
  "lightness": "Valgus",
  "magenta": "magentapunane",
  "magenta pink": "magentaroosa",
  "orange": "oranž",
  "orange yellow": "oranžikaskollane",
  "pale": "kahvatu",
  "pink": "roosa",
  "pink red": "vaarikapunane",
  "purple": "lilla",
  "purple magenta": "purpurne magenta",
  "red": "Punane",
  "red orange": "punakasoranž",
  "saturation": "Küllastus",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} läbipaistev",
  "very dark": "väga tume",
  "very light": "väga hele",
  "vibrant": "ere",
  "white": "valge",
  "yellow": "kollane",
  "yellow green": "kollakasroheline"
}
