{
  "hue": "Hue",
  "saturation": "Saturation",
  "lightness": "Lightness",
  "brightness": "Brightness",
  "red": "Red",
  "green": "Green",
  "blue": "Blue",
  "alpha": "Alpha",
  "colorName": "{lightness} {chroma} {hue}",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} transparent",
  "very dark": "very dark",
  "dark": "dark",
  "light": "light",
  "very light": "very light",
  "pale": "pale",
  "grayish": "grayish",
  "vibrant": "vibrant",
  "black": "black",
  "white": "white",
  "gray": "gray",
  "pink": "pink",
  "pink red": "pink red",
  "red orange": "red orange",
  "brown": "brown",
  "orange": "orange",
  "orange yellow": "orange yellow",
  "brown yellow": "brown yellow",
  "yellow": "yellow",
  "yellow green": "yellow green",
  "green cyan": "green cyan",
  "cyan": "cyan",
  "cyan blue": "cyan blue",
  "blue purple": "blue purple",
  "purple": "purple",
  "purple magenta": "purple magenta",
  "magenta": "magenta",
  "magenta pink": "magenta pink"
}
