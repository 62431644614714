import React from 'react';
import { Navigate, generatePath } from 'react-router-dom';

import { parseFromLocalStorage } from '@exchange-frontends/utils';

import useSpFeatureFlag from '../../../actions/utilApi/selectors/useSpFeatureFlag';
import AppBuilderAppsList from '../components/tabs/apps-list/AppBuilderAppsList';
import AppsList from '../components/tabs/apps-list/AppsList';
import IntegrationDetails from '../components/tabs/apps-list/IntegrationDetails';
import IntegrationsList from '../components/tabs/apps-list/IntegrationsList';
import { ECRoutes } from '../components/tabs/apps-list/ec/ECRoutes';
import { useStore } from '../store';

const NavigateToAppBuilderAppsList = () => {
  const selectedOrg = useStore((state) => state.selectedOrg);
  const orgs = parseFromLocalStorage('orgs');
  const hasOrgs = orgs?.length > 0;
  if (!selectedOrg && hasOrgs) {
    return null;
  }
  return <Navigate replace to={generatePath('/manage/apps/ec/:orgId?/ABD/list', { orgId: selectedOrg?.code })} />;
};

const computePath = (path) => generatePath(path, { orgId: useStore.getState().selectedOrg?.code });

export const TABS_DEFINITION = Object.freeze({
  APP_BUILDER: {
    key: 'APP_BUILDER',
    path: 'apps/ec/:orgId?/ABD/list',
    computePath,
    shouldShow: true,
    Element: AppBuilderAppsList,
    tabLabel: 'App Builder applications',
    launchId: 'Experience Cloud Applications',
  },
  CC_PLUGINS: {
    key: 'CC_PLUGINS',
    path: 'apps/cc',
    shouldShow: true,
    Element: () => <AppsList />,
    tabLabel: 'Creative Cloud extensions',
    launchId: 'Manage CC plugins',
  },
  INTEGRATIONS: {
    key: 'INTEGRATIONS',
    path: 'apps/ec/:orgId?/S2S/list',
    computePath,
    getIsEnabled: () => useSpFeatureFlag.getState()?.data,
    shouldShow: true,
    Element: IntegrationsList,
    tabLabel: 'Integrations',
    launchId: 'Integrations',
  },
  EC_APPS: {
    key: 'EC_APPS',
    path: 'apps/ec/:orgId?/list',
    shouldShow: false,
    Element: NavigateToAppBuilderAppsList,
  },
  PENDING_APPS: {
    key: 'PENDING_APPS',
    path: 'apps/ec/:orgId?/pending',
    shouldShow: false,
    Element: NavigateToAppBuilderAppsList,
  },
  APPROVED_APPS: {
    key: 'APPROVED_APPS',
    path: 'apps/ec/:orgId?/approved',
    shouldShow: false,
    Element: NavigateToAppBuilderAppsList,
  },
  INTEGRATION_DETAILS: {
    key: 'INTEGRATION_DETAILS',
    path: 'apps/ec/:orgId/S2S/view/:appId/:tab?',
    getIsEnabled: () => useSpFeatureFlag.getState()?.data,
    shouldShow: false,
    Element: IntegrationDetails,
  },
  APP_BUILDER_DETAILS: {
    key: 'APP_BUILDER_DETAILS',
    path: 'apps/ec/:orgId/ABD?/view/:appId/*',
    shouldShow: false,
    Element: ECRoutes,
  },
});
