import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useUpdateConsent from '../../../../../../actions/entitlementApi/selectors/useUpdateConsent';
import { useStore } from '../../../../store';

const useConsentBackupStrategy = () => {
  const routeParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const updateConsent = useUpdateConsent();
  const consent = useConsent();
  const [calledUpdateConsent, setCalledUpdateConsent] = useState(false);
  const isAdmin = useStore((state) => state.selectedOrg?.role === 'ADMIN');

  const appType = consent?.data?.consent?.appType;
  const status = consent.data?.consent?.status;
  const clickedOnAllow = searchParams.get('admin_consent') === 'true';
  const redirectOrg = searchParams.get('org_id');
  const stateToken = searchParams.get('state');
  const notConsented = status && status !== 'ALLOWED';
  const shouldUpdateConsent = isAdmin && clickedOnAllow && notConsented && !consent.isFetching && !calledUpdateConsent;
  const isUpdatingConsent = updateConsent.isLoading || shouldUpdateConsent;

  // Update consent status if the workflow is 'consented' and the status is not 'ALLOWED'.
  // This is a backup strategy in case the consent status is not updated when the user consents from the IMS browser based workflow.
  useEffect(() => {
    if (shouldUpdateConsent) {
      (async () => {
        try {
          setCalledUpdateConsent(true);
          await updateConsent.mutateAsync({
            appType,
            redirectOrg,
            stateToken,
            appId: routeParams.appId,
            action: 'ALLOW',
            refetch: consent.refetch,
            showErrorToast: true,
          });
        } catch (error) {
          // Do nothing. The error will be handled by the useUpdateConsent hook.
        }
      })();
    }
  }, [appType, consent.refetch, redirectOrg, routeParams.appId, shouldUpdateConsent, stateToken, updateConsent]);

  // Remove stale query params from the URL after the consent status is updated.
  useEffect(() => {
    if (status === 'ALLOWED') {
      const newSearch = new URLSearchParams(searchParams);
      newSearch.delete('state');
      newSearch.delete('orgId');
      newSearch.delete('scope');
      const currentSearchString = searchParams.toString();
      const newSearchString = newSearch.toString();
      if (newSearchString !== currentSearchString) {
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, setSearchParams, status]);

  return { isUpdatingConsent, updateConsent };
};

export default useConsentBackupStrategy;
