{
  "alpha": "Alfa",
  "black": "svart",
  "blue": "Blå",
  "blue purple": "blålilla",
  "brightness": "Lysstyrke",
  "brown": "brun",
  "brown yellow": "brungul",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cyan",
  "cyan blue": "cyanblå",
  "dark": "mørk",
  "gray": "grå",
  "grayish": "gråaktig",
  "green": "Grønn",
  "green cyan": "grønncyan",
  "hue": "Fargetone",
  "light": "lys",
  "lightness": "Lyshet",
  "magenta": "magenta",
  "magenta pink": "magentarosa",
  "orange": "oransje",
  "orange yellow": "oransjegul",
  "pale": "blek",
  "pink": "rosa",
  "pink red": "rosarød",
  "purple": "lilla",
  "purple magenta": "lillamagenta",
  "red": "Rød",
  "red orange": "rødoransje",
  "saturation": "Metning",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} gjennomsiktig",
  "very dark": "svært mørk",
  "very light": "svært lys",
  "vibrant": "levende",
  "white": "hvit",
  "yellow": "gul",
  "yellow green": "gulgrønn"
}
