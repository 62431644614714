// @ts-check
import { myxchngApiClient } from '../../utils/RequestClient';

/**
 * @typedef {import('@action-types/ecPublisher').EcPublisherInfoResponse} EcPublisherInfoResponse
 *
 * @param {object} params
 * @param {string} [params.publisherId]
 * @param {string} [params.token]
 * @param {AbortSignal} [params.signal]
 * @returns {Promise<EcPublisherInfoResponse>}
 */
const getEcPublisherInfo = async ({ signal, publisherId, token }) => {
  const headers = new Headers({});
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }

  /** @type {Awaited<ReturnType<typeof myxchngApiClient.fetch<EcPublisherInfoResponse, unknown>>>} */
  const { jsonResponse, fetchResponse } = await myxchngApiClient.fetch({
    endpoint: `/publishers/${publisherId}`,
    requestOptions: {
      signal,
      headers,
    },
  });

  const userLocation = fetchResponse.headers.get('x-user-location');

  return { ...jsonResponse, userLocation };
};

export default getEcPublisherInfo;
