//@ts-check
import { Cell, Column, Row, TableBody, TableHeader, TableView } from '@adobe/react-spectrum';
import React from 'react';
import styled from 'styled-components';

import NoProductProfilesView from './NoProductProfilesView';
import useProductProfilesTableData from './useProductProfilesTableData';

const List = styled.ul`
  padding-left: 16px;
  margin: 0;
`;

/** @type {Record<string, import('@adobe/react-spectrum').SpectrumColumnProps>} */
const columns = Object.freeze({
  API: {
    key: 'api',
    children: 'API',
  },
  PRODUCT_PROFILES: {
    key: 'product',
    children: 'Product profiles',
    defaultWidth: '2fr',
  },
});
const columnsArray = Object.values(columns);

const ProductProfilesTable = () => {
  const products = useProductProfilesTableData();

  if (!products?.length) {
    return <NoProductProfilesView />;
  }

  return (
    <TableView aria-label="Selected product profiles table" density="spacious" overflowMode="wrap">
      <TableHeader columns={columnsArray}>{(props) => <Column {...props} />}</TableHeader>
      <TableBody items={products}>
        {(product) => (
          <Row key={product.id}>
            <Cell>{product.name}</Cell>
            <Cell>
              <List aria-label={`Selected product profiles for ${product.name}`}>
                {product.profiles.map((profile) => (
                  <li aria-label={profile.name} key={profile.id}>
                    {profile.name}
                  </li>
                ))}
              </List>
            </Cell>
          </Row>
        )}
      </TableBody>
    </TableView>
  );
};

export default ProductProfilesTable;
