//@ts-check
import { useDialogContainer } from '@adobe/react-spectrum';
import { success, warning } from '@react/react-spectrum/Toast/js/ToastContainer';
import { useEffect, useRef, useState } from 'react';

import useEditProductProfiles from '../../../../../actions/jilApi/selectors/useEditProductProfiles';
import useUserDetails from '../../../../../actions/jilApi/selectors/useUserDetails';

const MAX_POLLING_TIME = 20000; // 20 seconds maximum polling time
const POLLING_INTERVAL = 1000; // Poll every 1 second

/**
 * @param {string} technicalAccountId
 * @param {import('@action-types/user').PatchProductProfilesMutationParams['productProfiles']} editedProductProfiles
 */
const useSaveProductProfiles = (technicalAccountId, editedProductProfiles) => {
  const { dismiss } = useDialogContainer();
  const [pollEnabled, setPollEnabled] = useState(false);
  const pollingTimeoutRef = useRef(null);
  const shouldPoll = pollEnabled && editedProductProfiles.length > 0;
  const editProfilesMutation = useEditProductProfiles();
  const userDetailsQuery = useUserDetails(technicalAccountId, {
    refetchInterval: shouldPoll ? POLLING_INTERVAL : false,
  });

  const saveProductProfiles = async () => {
    try {
      // Clear any existing timeout
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }

      await editProfilesMutation.mutateAsync(
        {
          showErrorToast: false,
          userId: technicalAccountId,
          productProfiles: editedProductProfiles,
        },
        {
          onSuccess: () => {
            setPollEnabled(true);
            // Set a timeout to stop polling after MAX_POLLING_TIME
            pollingTimeoutRef.current = setTimeout(() => {
              if (editedProductProfiles.length > 0) {
                dismiss?.();
                warning(
                  'Product profiles update is taking longer than expected. The changes may still be processing in the background.'
                );
              }
            }, MAX_POLLING_TIME);
          },
        }
      );
    } catch (error) {
      // Do nothing. The error will be handled by the useEditProductProfiles hook.
    }
  };

  useEffect(() => {
    if (pollEnabled && !editedProductProfiles?.length) {
      setPollEnabled(false);
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }
      dismiss?.();
      success('Product profiles updated successfully');
    }
  }, [dismiss, editedProductProfiles?.length, pollEnabled]);

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }
    };
  }, []);

  return {
    saveProductProfiles,
    editProfilesMutation,
    refetchingSelectedProfiles: shouldPoll,
    userDetailsQuery,
  };
};

export default useSaveProductProfiles;
