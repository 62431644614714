{
  "alpha": "アルファ",
  "black": "ブラック",
  "blue": "青",
  "blue purple": "ブルーパープル",
  "brightness": "明るさ",
  "brown": "ブラウン",
  "brown yellow": "ブラウンイエロー",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "シアン",
  "cyan blue": "シアンブルー",
  "dark": "ダーク",
  "gray": "グレー",
  "grayish": "グレイッシュ",
  "green": "緑",
  "green cyan": "グリーンシアン",
  "hue": "色相",
  "light": "ライト",
  "lightness": "明度",
  "magenta": "マゼンタ",
  "magenta pink": "マゼンタピンク",
  "orange": "オレンジ",
  "orange yellow": "オレンジイエロー",
  "pale": "ペール",
  "pink": "ピンク",
  "pink red": "ピンクレッド",
  "purple": "パープル",
  "purple magenta": "パープルマゼンタ",
  "red": "赤",
  "red orange": "レッドオレンジ",
  "saturation": "彩度",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} 透明",
  "very dark": "最も暗い",
  "very light": "ベリーライト",
  "vibrant": "鮮やか",
  "white": "ホワイト",
  "yellow": "イエロー",
  "yellow green": "イエローグリーン"
}
