import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorDialogFallback from '../ErrorDialogFallback';
import EcAcquiredAdminDialog from './EcAcquiredAdminDialog';
import EcAcquiredNonAdminDialog from './EcAcquiredNonAdminDialog';

/** @typedef {'beginInstallation' | 'applicationAcquired'} DialogVariant */
/** @typedef {Record<DialogVariant, string>} DialogTitle */

/**
 * @typedef EcAcquiredDialogProps
 * @property {boolean} isAdmin
 */

/** @param {EcAcquiredDialogProps & import('./EcAcquiredAdminDialog').EcAcquiredAdminDialogProps & import('./EcAcquiredNonAdminDialog').EcAcquiredNonAdminDialogProps} props */
const EcAcquiredDialog = ({ isAdmin, ...props }) => {
  const Dialog = isAdmin ? EcAcquiredAdminDialog : EcAcquiredNonAdminDialog;
  return (
    <ErrorBoundary FallbackComponent={() => <ErrorDialogFallback close={props.close} />}>
      <Dialog {...props} />
    </ErrorBoundary>
  );
};

export default EcAcquiredDialog;
