{
  "alpha": "Alpha",
  "black": "黑色",
  "blue": "蓝色",
  "blue purple": "蓝紫色",
  "brightness": "亮度",
  "brown": "棕色的",
  "brown yellow": "棕黄色",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "蓝绿色",
  "cyan blue": "青蓝色",
  "dark": "深色",
  "gray": "灰色",
  "grayish": "浅灰色的",
  "green": "绿色",
  "green cyan": "绿青色",
  "hue": "色相",
  "light": "浅色",
  "lightness": "明亮度",
  "magenta": "紫红色",
  "magenta pink": "紫粉色",
  "orange": "橙色",
  "orange yellow": "橙黄色",
  "pale": "苍白的",
  "pink": "粉色",
  "pink red": "粉红色",
  "purple": "紫色",
  "purple magenta": "紫洋红色",
  "red": "红色",
  "red orange": "红橙色",
  "saturation": "饱和度",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} 透明",
  "very dark": "很暗",
  "very light": "很浅",
  "vibrant": "生机勃勃",
  "white": "白色",
  "yellow": "黄色",
  "yellow green": "黄色/绿色"
}
