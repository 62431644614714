import { Breadcrumbs, Divider, Flex, Grid, Item, ProgressCircle, View } from '@adobe/react-spectrum';
import { error } from '@react/react-spectrum/Toast';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';

import { handleApiCall, manageApi } from '@exchange-frontends/api';
import { AppCrashComponent, ErrorBoundary } from '@exchange-frontends/components';

import { useStore } from '../../../../../store';
import { CONSENT_STATUS_TO_APP_STATUS, DEPLOYMENT_STATUS } from '../../../../../utils/Constants';
import { useCurrentEnvFetch } from '../../../../../utils/customHooks';
import { poll } from '../../../../../utils/utils';
import { UserDataContext } from '../../../../App';
import { AppManageButtons } from '../AppManage';
import { EnvDetailsButtons } from '../env/tabs/details/EnvDetailsButtons';
import ManageAppSideNav from './ManageAppSideNav';

const buildPath = (index, orgId, appId, envId) => {
  switch (index) {
    case 'appName':
      return `/manage/apps/ec/${orgId}/ABD/view/${appId}`;
    case 'manage':
      return `/manage/apps/ec/${orgId}/ABD/list`;
    case 'envName':
      return `/manage/apps/ec/${orgId}/ABD/view/${appId}/${envId}/details`;
    default:
      return null;
  }
};

const ecAppsLoadingSelector = (state) => state.ecAppsLoading;

/**
 * Used for App Builder routes/tab
 */
const AppManageLayout = ({ children }) => {
  const isAppDetails = useMatch('/manage/apps/ec/:orgId/ABD/view/:appId/');
  const isEnvDetails = useMatch('/manage/apps/ec/:orgId/ABD/view/:appId/:envId/details');
  const isEnvConfig = useMatch('/manage/apps/ec/:orgId/ABD/view/:appId/:envId/deploy');
  const location = useLocation();
  const { appId, orgId, envId } = useParams();
  const navigate = useNavigate();
  const { currentEnv } = useCurrentEnvFetch();
  const envLoading = useStore((state) => state.envLoading);
  const { accessToken } = useContext(UserDataContext) ?? {};
  const deployingState = useStore((state) => state.isDeploying);
  const appLoading = useStore(ecAppsLoadingSelector);
  const currentAppLoading = useStore((state) => state.currentAppLoading);
  const fetchDeploymentStatus = useStore((state) => state.fetchDeploymentStatus);
  const deploymentsFromStore = useStore((state) => state.currentDeploymentsId);
  const allApps = useStore((state) => state.allApps);
  const setAppStoreValue = useStore((state) => state.setAppStoreValue);
  const currentDeployment = deploymentsFromStore.find((e) => e?.[envId])?.[envId];
  const stateEnvList = useStore((state) => state.envList);
  const setEnvStoreValue = useStore((state) => state.setEnvStoreValue);
  const envList = useMemo(() => stateEnvList?.[appId] || null, [stateEnvList?.[appId], appId]);
  const app = useMemo(() => allApps?.find((obj) => obj.id === appId) || null, [allApps, appId]);
  const loading = isAppDetails
    ? currentAppLoading || appLoading
    : currentAppLoading || appLoading || (!currentEnv && envLoading);
  const [currentAppLoaded, setCurrentAppLoaded] = useState(false);

  //for public apps load extra data
  /**
   * David here, this useEffect is doing a search to get version(s) informations for the app
   * These informations are then added in the store, in the array of apps (the ones that
   * were fetched for the list of apps) on that app.
   * This should be refactored and extracted out of here.
   * I had one idea to help reduce the dependency array of this useEffect:
   *   - create a new store action that would be called from here, and that would be responsible
   *     for fetching the version(s) informations for the app and updating the store.
   *   - this way we can remove `app` and `allApps` from the dependency array
   * @updated 2024-06-26
   * @author ens08083
   */
  useEffect(() => {
    if (app && !app.jaeger && !app?.description && !currentAppLoaded) {
      let mounted = true;
      const handler = handleApiCall(manageApi.getPublicEc, {
        accessToken,
        orgId,
        apps: [{ appId }],
        mode: 'details',
      });
      setAppStoreValue({ currentAppLoading: true });
      (async () => {
        try {
          const app = await handler.run();
          if (mounted) {
            const filteredApps = allApps.map((item) =>
              item.id === app?.[0].id
                ? {
                    ...item,
                    ...app[0],
                  }
                : item
            );
            setAppStoreValue({ allApps: filteredApps, currentAppLoading: false });
            setCurrentAppLoaded(true);
          }
        } catch (err) {
          console.error(err);
          // if the query has been aborted, do nothing
          if (err.name !== 'AbortError') {
            if (mounted) {
              setAppStoreValue({ currentAppLoading: false });
              setCurrentAppLoaded(true);
            }
          }
        }
      })();
      return () => {
        mounted = false;
        handler.controller.abort();
      };
    }
  }, [appId, app, accessToken, currentAppLoaded, orgId]);

  /** load env list */
  useEffect(() => {
    if (accessToken && app?.status === CONSENT_STATUS_TO_APP_STATUS.ALLOWED && !envList) {
      let mounted = true;
      const handler = handleApiCall(manageApi.getEnvList, { accessToken, appId, orgId });
      setEnvStoreValue({ envLoading: true });
      (async () => {
        try {
          const response = await handler.run();
          if (mounted) {
            const envListLoaded = { [appId]: response };
            setEnvStoreValue({ envList: envListLoaded });
            setEnvStoreValue({ envLoading: false });
          }
        } catch (err) {
          console.error(err);
          if (err.name !== 'AbortError') {
            error(err.userMessage, { timeout: 0 });
            if (mounted) {
              setEnvStoreValue({ envList: { [appId]: [] } });
              setEnvStoreValue({ envLoading: false });
            }
          }
        }
      })();
      return () => {
        mounted = false;
        handler.controller.abort();
      };
    }
  }, [accessToken, orgId, appId, app?.status, envId, envLoading, setEnvStoreValue]);

  /**
   * poll deployment status to update deployment and environment with a latest details
   * @updated 2024-02-27 @ens08083
   */
  const pollController = useRef(new AbortController());
  useEffect(() => {
    if (
      currentDeployment?.id &&
      [DEPLOYMENT_STATUS.IN_PROGRESS, DEPLOYMENT_STATUS.UNDEPLOYMENT_IN_PROGRESS].includes(currentDeployment?.status)
    ) {
      poll({
        fn: () => fetchDeploymentStatus(accessToken, orgId, envId, currentDeployment.id),
        validate: (data) =>
          pollController.current?.signal?.aborted ||
          ![DEPLOYMENT_STATUS.IN_PROGRESS, DEPLOYMENT_STATUS.UNDEPLOYMENT_IN_PROGRESS].includes(data ?? ''),
        interval: 30_000,
        signal: pollController.current.signal,
      }).then(() => {
        pollController.current = new AbortController();
      });
    }
    return () => {
      pollController.current.abort();
      pollController.current = new AbortController();
    };
  }, [accessToken, currentDeployment?.id, currentDeployment?.status, envId, fetchDeploymentStatus, orgId]);

  useEffect(() => {
    let mounted = true;

    if (accessToken && envId && orgId && appId && !envLoading) {
      const handler = handleApiCall(manageApi.getEnv, { accessToken, appId, orgId, envId });
      setAppStoreValue({ envLoading: true });
      (async () => {
        try {
          const env = await handler.run();
          /** update env status in the env list to display accurate data */
          if (mounted) {
            if (stateEnvList?.[appId]) {
              const index = stateEnvList[appId].findIndex((obj) => obj.id === envId);
              if (index !== -1) {
                stateEnvList[appId][index] = env;
              } else {
                stateEnvList[appId].push(env);
              }
            }
            setEnvStoreValue({ envLoading: false, envList: stateEnvList });
          }
        } catch (err) {
          console.error(err);
          if (err.name !== 'AbortError' && mounted) {
            setEnvStoreValue({ envLoading: false, envError: err });
          }
        }
      })();
      return () => {
        mounted = false;
        handler.controller.abort();
      };
    }
  }, [accessToken, orgId, appId, envId, deployingState]);

  useEffect(() => {
    if (accessToken && envId && orgId && appId && !envLoading) {
      let mounted = true;
      const handler = handleApiCall(manageApi.getDeployments, { accessToken, appId, orgId, envId });
      (async () => {
        try {
          const deployments = await handler.run();
          if (mounted) {
            setEnvStoreValue({ deploymentsLoading: true });
          }
          let isDeploying = false;
          if (deployments.length > 0) {
            /** to prevent issues with the UI when need to switch between different env */
            isDeploying = [DEPLOYMENT_STATUS.IN_PROGRESS, DEPLOYMENT_STATUS.UNDEPLOYMENT_IN_PROGRESS].includes(
              deployments[0]?.status
            );

            const d = deploymentsFromStore;
            const index = d.findIndex((obj) => obj?.[envId]);
            if (index !== -1) {
              d[index] = { [envId]: deployments[0] || null };
            } else {
              d.push({ [envId]: deployments[0] || null });
            }
            if (mounted) {
              setEnvStoreValue({ currentDeploymentsId: deploymentsFromStore });
            }
          }
          setEnvStoreValue({ deploymentsLoading: false, isDeploying: isDeploying });
        } catch (err) {
          console.error(err);
          if (mounted && err.name !== 'AbortError') {
            setEnvStoreValue({ deploymentsLoading: false });
          }
        }
      })();
      return () => {
        mounted = false;
        handler.controller.abort();
      };
    }
  }, [accessToken, orgId, appId, envId, deployingState, envLoading]);

  //build breadcrumbItems
  const breadcrumbItems = useMemo(() => {
    const items = [
      { label: 'Manage', index: 'manage' },
      { label: app?.name || app?.title || '', index: 'appName' },
    ];
    if (currentEnv?.name && envId) {
      items.push({ label: currentEnv.name, index: 'envName' });
      if (location.pathname.includes('details')) {
        items.push({ label: 'Environment details', index: 'envDetails' });
      }
      if (location.pathname.includes('deploy')) {
        items.push({ label: 'Configuration', index: 'configuration' });
      }
      if (location.pathname.includes('insights')) {
        items.push({ label: 'Insights', index: 'insights' });
      }
    }
    return items;
  }, [app?.name, app?.title, currentEnv?.name, envId, location.pathname]);

  const handleBreadcrumbClick = (index) => {
    const path = buildPath(index, orgId, appId, envId);
    if (path !== null) {
      navigate(path);
    }
  };
  return (
    <>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="100%">
          <ProgressCircle size="L" isIndeterminate aria-label="Loading…" />
        </Flex>
      ) : (
        <>
          <Grid
            areas={{
              base: ['header', 'nav', 'content'],
              M: ['header   header', 'nav      content'],
              L: ['header header  header', 'nav    content content', 'nav    content content'],
            }}
            columns={{
              M: ['size-3400', '1fr'],
              L: ['size-3400', '1fr'],
            }}
            gap="size-100"
          >
            <View gridArea="header" height="size-1000">
              <Flex justifyContent="space-between">
                <div style={{ flex: '1' }}>
                  {breadcrumbItems.length > 0 && (
                    <Breadcrumbs onAction={(e) => handleBreadcrumbClick(e)}>
                      {breadcrumbItems.map((item) => {
                        return <Item key={item.index}>{item.label}</Item>;
                      })}
                    </Breadcrumbs>
                  )}
                </div>
                {isAppDetails && <AppManageButtons app={app} selectedOrg={orgId} />}
                {isEnvDetails && isEnvConfig && <EnvDetailsButtons env={currentEnv} app={app} orgId={orgId} />}
              </Flex>
              <Divider size="M" marginTop="20px" />
            </View>
            <View gridArea="nav">
              <Flex direction={{ base: 'row', M: 'column' }} gap="size-100" margin="size-100">
                <View height="100%" minWidth="size-3400">
                  <ManageAppSideNav currentEnvId={envId} currentAppId={appId} currentOrgId={orgId} />
                </View>
              </Flex>
            </View>
            <View gridArea="content" height="100%">
              <ErrorBoundary errorComponent={<AppCrashComponent appName="Manage" />}>{children}</ErrorBoundary>
            </View>
          </Grid>
        </>
      )}
    </>
  );
};

export default AppManageLayout;
