//@ts-check
import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';

import { useStore } from '../../../pages/manage/store';
import { ContainerContext } from '../../../utils';
import editProductProfiles from '../actions/patchProductProfiles';

const selectedOrgSelector = (state) => state.selectedOrg?.code;

/**
 * @param {import('@tanstack/react-query').UseMutationOptions<
 *   import('@action-types/user').User,
 *   import('@action-types/RequestError').RequestError<import('@action-types/user').JilApiErrorResponse>,
 *   import('@action-types/user').PatchProductProfilesMutationParams,
 *   unknown
 * >} [options]
 */
const useEditProductProfiles = (options) => {
  const orgId = useStore(selectedOrgSelector);
  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/user').PatchProductProfilesMutationState} */
  const data = useMutation({
    ...options,
    mutationKey: ['patchProductProfiles'],
    mutationFn: async (params) => {
      if (!token) {
        throw new Error('Missing token');
      }
      if (!orgId) {
        throw new Error('Missing orgId');
      }
      const response = await editProductProfiles({
        token,
        orgId,
        ...params,
      });
      return response;
    },
  });

  return data;
};

export default useEditProductProfiles;
