//@ts-check
import { Content, Flex, Heading, IllustratedMessage, ProgressCircle } from '@adobe/react-spectrum';
import Error from '@spectrum-icons/illustrations/Error';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import React from 'react';

import useEcAppSdkCodes from '../../../../../actions/consoleApi/selectors/useEcAppSdkCodes';
import useUserDetails from '../../../../../actions/jilApi/selectors/useUserDetails';

/**
 * @param {object} props
 * @param {import('@action-types/ecApp').AppDetails} [props.appDetails]
 * @param {import('@action-types/consent').Consent} [props.consent]
 */
const useLoadingAndErrorState = ({ appDetails, consent }) => {
  const technicalAccountId = consent?.technicalAccountId;
  const servicesQuery = useEcAppSdkCodes(appDetails);
  const services = servicesQuery.data?.services;
  const userQuery = useUserDetails(technicalAccountId, { enabled: services?.length > 0 });
  const isLoadingUser = userQuery.isLoading && userQuery.isFetching;
  const isLoadingServices = servicesQuery.isLoading && servicesQuery.isFetching;
  const isLoading = isLoadingServices || isLoadingUser;

  if (isLoading) {
    return (
      <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
        <ProgressCircle isIndeterminate size="L" aria-label="Loading product profiles" />
      </Flex>
    );
  } else if (servicesQuery.error || userQuery.error) {
    return (
      <IllustratedMessage>
        <Error />
        <Heading>Something went wrong while loading product profiles</Heading>
        <Content>Please try again in a few minutes</Content>
      </IllustratedMessage>
    );
  } else if (!services?.length) {
    return (
      <IllustratedMessage>
        <NotFound />
        <Heading>No product profiles required</Heading>
        <Content>This integration doesn&apos;t require product profiles to gain access</Content>
      </IllustratedMessage>
    );
  }

  return null;
};

export default useLoadingAndErrorState;
