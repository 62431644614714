// import { createBrowserHistory } from 'history';
import { Flex, View } from '@adobe/react-spectrum';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import React, { useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@exchange-frontends/custom-hooks';

import {
  Nav,
  NavCurtain,
  NavCurtainWrapper,
  NavLink,
  NavLinkContent,
  NavLinkContentWrapper,
  NavLinkText,
  NavList,
  NavListHamburgerButton,
  NavListItem,
  NavListWrapper,
  NavLogo,
  NavLogoText,
  NavProfileLink,
  NavProfileWrapper,
  TopNav,
  XCHGHeader,
  XCHGHeaderWrapper,
} from './HeaderComponents';
import OrgSwitcher from './OrgSwitcher';

/**
 * @description Header react component
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
const Header = ({ routes: ROUTES, adobeIms, orgs }) => {
  // @TODO make this an env var?
  const navLogoImageUrl =
    process.env.REACT_APP_LOGO_PATH ||
    `https://www.${
      process.env.REACT_APP_ENVIRONMENT === 'stg1' ? 'stage.' : ''
    }adobe.com/content/dam/cc/icons/Adobe_Corporate_Horizontal_Red_HEX.svg`;
  const [hamburgerExpanded, setHamburgerExpanded] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [smallOrgSwitcherOpen, setSmallOrgSwitcherOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const matchedECAppsRoute = useMatch('/manage/apps/ec/:orgId/:type/*');
  const isCommerceRoute = location.search.includes('originatingFrom=adobeCommerce');
  const maxWidth1200 = useMediaQuery('(max-width:1200px)');
  const maxWidth600 = useMediaQuery('(max-width:600px)');

  /**
   * @description close hamburger menu when viewport is resized above 1200px
   * @dependency maxWidth1200
   * @dependency hamburgerExpanded
   */
  useEffect(() => {
    if (!maxWidth1200 && hamburgerExpanded) {
      setHamburgerExpanded(false);
    }
  }, [maxWidth1200, hamburgerExpanded]);

  /**
   * @description set selected org in session storage
   * @dependency selectedOrg
   * @dependency orgs
   */
  useEffect(() => {
    if ((window?.adobeIMS?.initialized && !window?.adobeIMS?.isSignedInUser?.()) || !orgs?.length) {
      // can't set selected org in session storage... user not signed in
      return;
    }
    let currentlySelectedOrg = null;
    if (!selectedOrg) {
      // running set selected org for the first time...
      try {
        // start by trying to get the selectedOrg from the orgId in the URL
        const routeOrg = isCommerceRoute
          ? new URLSearchParams(location.search).get('orgId')
          : matchedECAppsRoute?.params?.orgId;
        // also try to get selected org from session storage...
        const sessionOrg = JSON.parse(localStorage.getItem('selectedOrg'));
        if (routeOrg) {
          // routeOrg found, setting selectedOrg to routeOrg's org
          if (orgs?.length) {
            currentlySelectedOrg = orgs.find((org) => org.code === routeOrg);
            setSelectedOrg(currentlySelectedOrg ?? orgs?.[0]);
            localStorage.setItem('selectedOrg', JSON.stringify(currentlySelectedOrg));
          }
        } else if (sessionOrg && orgs?.find((org) => org?.id === sessionOrg?.id)) {
          // found selected org in session storage and selected org is in orgs list...
          currentlySelectedOrg = sessionOrg;
          setSelectedOrg(currentlySelectedOrg);
        } else if (orgs?.length) {
          // couldn't find selected org in session storage or selectedOrg is not in orgs list... setting to first org in list
          localStorage.setItem('selectedOrg', JSON.stringify(orgs[0]));
          currentlySelectedOrg = orgs[0];
          setSelectedOrg(currentlySelectedOrg);
        }
      } catch (error) {
        // error getting selected org from session storage... setting to first org in list
        currentlySelectedOrg = orgs?.[0];
        localStorage.setItem('selectedOrg', JSON.stringify(currentlySelectedOrg));
        setSelectedOrg(currentlySelectedOrg);
      }
    } else {
      try {
        const sessionSelectedOrg = JSON.parse(localStorage.getItem('selectedOrg'));
        if (selectedOrg?.id === sessionSelectedOrg?.id) {
          // selected org already set and unchanged... returning
          return;
        }
        // selected org set because it has changed... setting in session storage
        currentlySelectedOrg = selectedOrg;
        localStorage.setItem('selectedOrg', JSON.stringify(currentlySelectedOrg));
      } catch (error) {
        // error getting selected org from session storage... setting to first org in list
        currentlySelectedOrg = orgs?.[0] ?? null;
        localStorage.setItem('selectedOrg', JSON.stringify(currentlySelectedOrg));
      }
    }
    // dispatching selectedOrgChange event... with selectedOrg: ', currentlySelectedOr
    /**
     * @description selectedOrgChange event
     * @event selectedOrgChange
     * @type {CustomEvent}
     * @property {Object} detail - selectedOrg
     */
    const selectedOrgChangeEvent = new CustomEvent('selectedOrgChange', { detail: currentlySelectedOrg });
    document.dispatchEvent(selectedOrgChangeEvent);
  }, [matchedECAppsRoute?.params?.orgId, orgs, selectedOrg, location.search, isCommerceRoute]);

  /**
   * @description update active nav link on load or location chage
   * @dependency location
   * @dependency ROUTES
   */
  useEffect(() => {
    const linksHrefs = [ROUTES.FEATURED, ROUTES.BROWSE + '/cc', ROUTES.BROWSE + '/ec', ROUTES.MANAGE];
    const linksIDsToRoutes = {
      [ROUTES.FEATURED]: [ROUTES.FEATURED],
      [ROUTES.BROWSE + '/cc']: [
        ROUTES.BROWSE + '/cc',
        ROUTES.DETAILS + '/cc',
        ROUTES.PUBLISHER + '/cc',
        ROUTES.DETAILS + '/integrations',
      ],
      [ROUTES.BROWSE + '/ec']: [
        ROUTES.BROWSE + '/ec',
        ROUTES.DETAILS + '/ec',
        ROUTES.DETAILS + '/dc',
        ROUTES.PUBLISHER + '/ec',
        ROUTES.PUBLISHER + '/dc',
      ],
      [ROUTES.MANAGE]: [ROUTES.MANAGE],
    };
    const elements = linksHrefs.map((href) => document.querySelector(`header a[href="${href}"]:not([class*="logo"])`));
    if (elements[0]) {
      elements.forEach((e) => e?.classList.remove('nav-link--active', 'nav-link--disabled'));
      let activeElement = Object.entries(linksIDsToRoutes).find(
        ([_key, routes], idx) => idx !== 0 && routes.some((r) => location.pathname.startsWith(r))
      );
      if (!activeElement) {
        if (location.pathname.startsWith(ROUTES.BROWSE)) {
          activeElement = [ROUTES.BROWSE + '/ec'];
        }
        activeElement = [ROUTES.FEATURED];
      }
      elements[linksHrefs.indexOf(activeElement[0])]?.classList.add('nav-link--active', 'nav-link--disabled');
    }
  }, [ROUTES, location]);

  const mainRoutes = [
    {
      name: 'Featured',
      route: ROUTES.FEATURED,
      daaLl: 'Featured-1',
      requiresAuth: false,
    },
    {
      name: 'Creative Cloud',
      route: ROUTES.BROWSE + '/cc',
      daaLl: 'Creative_Cloud-2',
      requiresAuth: false,
    },
    {
      name: 'Experience Cloud',
      route: ROUTES.BROWSE + '/ec',
      daaLl: 'Experience Cloud-3',
      requiresAuth: false,
    },
    {
      name: 'Manage',
      route: ROUTES.MANAGE,
      daaLl: 'Manage-4',
      requiresAuth: true,
    },
  ];

  return (
    <XCHGHeaderWrapper>
      <XCHGHeader>
        <NavCurtainWrapper
          className={`${hamburgerExpanded ? 'nav-curtain-wrapper--open' : ''} nav-curtain-wrapper`}
          data-requestor="hamburger"
        >
          <NavCurtain></NavCurtain>
        </NavCurtainWrapper>
        <TopNav id="xchg-topnav" daa-lh="gnav-exchange|exchange-gnav" daa-im="true">
          <Nav>
            <NavListWrapper style={{ flexDirection: 'column' }}>
              <NavList>
                <NavListItem>
                  <NavListWrapper style={{ justifyContent: 'center', borderBottom: '1px solid #eaeaea' }}>
                    <NavList>
                      <NavListItem style={{ position: 'relative' }}>
                        <NavListWrapper
                          style={{ maxWidth: '1440px', justifyContent: 'space-between', minHeight: '64px' }}
                        >
                          <NavList>
                            <NavListItem>
                              <NavListWrapper>
                                <NavList>
                                  <NavListItem id="nav-list-item--logo">
                                    <NavLogo
                                      href="/"
                                      daa-ll="logo"
                                      className="nav-logo"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/');
                                        if (hamburgerExpanded) {
                                          setSmallOrgSwitcherOpen(false);
                                          setHamburgerExpanded(false);
                                        }
                                      }}
                                    >
                                      <span style={{ width: '25px', flexShrink: 0 }}>
                                        <img className="nav-logo-image" src={navLogoImageUrl} alt="Adobe" />
                                      </span>
                                      <NavLogoText>
                                        <span className="nav-logo-text--default">Adobe Exchange</span>
                                      </NavLogoText>
                                    </NavLogo>
                                  </NavListItem>
                                  <NavListItem id="nav-list-item--main">
                                    <NavListHamburgerButton
                                      aria-expanded={hamburgerExpanded}
                                      aria-haspopup="true"
                                      aria-label="Navigation menu"
                                      tabIndex="1"
                                      role="button"
                                      type="button"
                                      onClick={() => {
                                        setSmallOrgSwitcherOpen(false);
                                        setHamburgerExpanded(!hamburgerExpanded);
                                      }}
                                    ></NavListHamburgerButton>
                                    <NavListWrapper className="nav-list-wrapper--main">
                                      <NavList>
                                        {mainRoutes.map((route) => {
                                          if (route.requiresAuth && !adobeIms?.isSignedInUser?.()) {
                                            return null;
                                          }
                                          return (
                                            <NavListItem key={route.name}>
                                              <NavLink
                                                href={route.route}
                                                className="nav-link--main"
                                                daa-ll={route.daaLl}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  navigate(route.route);
                                                  if (hamburgerExpanded) {
                                                    setHamburgerExpanded(false);
                                                  }
                                                }}
                                              >
                                                <NavLinkContentWrapper>
                                                  <NavLinkContent>
                                                    <NavLinkText>{route.name}</NavLinkText>
                                                  </NavLinkContent>
                                                </NavLinkContentWrapper>
                                              </NavLink>
                                            </NavListItem>
                                          );
                                        })}
                                        {maxWidth600 && adobeIms?.isSignedInUser?.() && orgs?.length > 0 && (
                                          <NavListItem>
                                            <View width="100%" paddingEnd={'size-200'}>
                                              <Flex alignItems={'center'} justifyContent={'space-between'}>
                                                <NavLink
                                                  href="#"
                                                  className="nav-link--main"
                                                  daa-ll="OrgSwitcher-4"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setSmallOrgSwitcherOpen(!smallOrgSwitcherOpen);
                                                  }}
                                                >
                                                  <NavLinkContentWrapper>
                                                    <NavLinkContent>
                                                      <NavLinkText>
                                                        <span>
                                                          <strong>Selected organization: </strong>
                                                          {selectedOrg?.name}
                                                        </span>
                                                      </NavLinkText>
                                                    </NavLinkContent>
                                                  </NavLinkContentWrapper>
                                                </NavLink>
                                                <ChevronRight size={'S'} />
                                              </Flex>
                                            </View>
                                          </NavListItem>
                                        )}
                                      </NavList>
                                      {maxWidth600 && adobeIms?.isSignedInUser?.() && orgs?.length > 0 && (
                                        <OrgSwitcher
                                          orgs={orgs}
                                          selectedOrg={selectedOrg}
                                          setSelectedOrg={setSelectedOrg}
                                          smallOrgSwitcherOpen={smallOrgSwitcherOpen}
                                          setSmallOrgSwitcherOpen={setSmallOrgSwitcherOpen}
                                        ></OrgSwitcher>
                                      )}
                                    </NavListWrapper>
                                  </NavListItem>
                                </NavList>
                              </NavListWrapper>
                            </NavListItem>
                            <NavListItem>
                              <NavListWrapper>
                                <NavList style={{ alignItems: 'center' }}>
                                  {!maxWidth600 && adobeIms?.isSignedInUser?.() && orgs?.length > 0 && (
                                    <NavListItem>
                                      <OrgSwitcher
                                        orgs={orgs}
                                        selectedOrg={selectedOrg}
                                        setSelectedOrg={setSelectedOrg}
                                      ></OrgSwitcher>
                                    </NavListItem>
                                  )}
                                  <NavListItem>{/* search goes here */}</NavListItem>
                                  <NavListItem>
                                    <NavProfileWrapper daa-ll="Sign In">
                                      <div id="nav-adobe-profile">{/* an empty div if they are not signed in */}</div>
                                      {!adobeIms?.isSignedInUser?.() && (
                                        <>
                                          {/* ...or show the sign in link */}
                                          <NavProfileLink href="#" onClick={() => adobeIms?.signIn()}>
                                            <NavLinkText>{`${
                                              !adobeIms?.isSignedInUser() ? 'Sign In' : ''
                                            }`}</NavLinkText>
                                          </NavProfileLink>
                                        </>
                                      )}
                                    </NavProfileWrapper>
                                  </NavListItem>
                                </NavList>
                              </NavListWrapper>
                            </NavListItem>
                          </NavList>
                        </NavListWrapper>
                      </NavListItem>
                    </NavList>
                  </NavListWrapper>
                </NavListItem>
              </NavList>
            </NavListWrapper>
          </Nav>
        </TopNav>
      </XCHGHeader>
    </XCHGHeaderWrapper>
  );
};

Header.propTypes = {};

export default Header;
