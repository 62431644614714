//@ts-check
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { ContainerContext } from '../../../utils';
import useEnterpriseEntitlements from '../../entitlementApi/selectors/ecAppsList/useEnterpriseEntitlements';
import getAppsById from '../actions/searchEcApps';

/**
 * @param {'ABD' | 'S2S'} appType
 * @param {import('@tanstack/react-query').UseQueryOptions} [options]
 */
const useManageEcAppsListDetails = (appType = 'S2S', options) => {
  const { data: entitlements } = useEnterpriseEntitlements(appType);
  const appIds = entitlements?.enterpriseEntitlements?.map(({ appId }) => appId);

  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;

  /** @type {import('@action-types/ecApp').EntpEntitlemnetsAppDetailsQueryState} */
  const data = useQuery({
    ...(options || {}),
    queryKey: ['manage-ec-apps-list-details', appIds, token],
    enabled: Boolean(appIds?.length && token),
    queryFn: ({ signal }) =>
      getAppsById({
        appIds: /** @type { string[]} */ (appIds),
        token: /** @type {string} */ (token),
        type: 'details',
        signal,
      }),
  });

  return data;
};

export default useManageEcAppsListDetails;
