{
  "alpha": "Alpha",
  "black": "黑",
  "blue": "藍色",
  "blue purple": "藍紫",
  "brightness": "亮度",
  "brown": "棕",
  "brown yellow": "棕黃",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "青",
  "cyan blue": "青藍",
  "dark": "暗",
  "gray": "灰",
  "grayish": "偏灰",
  "green": "綠色",
  "green cyan": "青綠",
  "hue": "色相",
  "light": "淺",
  "lightness": "明亮",
  "magenta": "洋紅",
  "magenta pink": "淺洋紅",
  "orange": "橙",
  "orange yellow": "橙黃",
  "pale": "淡",
  "pink": "粉紅",
  "pink red": "粉紅",
  "purple": "紫",
  "purple magenta": "紫洋紅",
  "red": "紅色",
  "red orange": "橙紅",
  "saturation": "飽和度",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} 透明",
  "very dark": "很暗",
  "very light": "很淺",
  "vibrant": "鮮豔",
  "white": "白",
  "yellow": "黃",
  "yellow green": "黃綠"
}
