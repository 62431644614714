{
  "alpha": "Alfa",
  "black": "czarny",
  "blue": "Niebieski",
  "blue purple": "niebiesko-fioletowy",
  "brightness": "Jasność",
  "brown": "brązowy",
  "brown yellow": "brązowo-żółty",
  "colorName": "{lightness} {chroma} {hue}",
  "cyan": "cyjanowy",
  "cyan blue": "cyjanowo-niebieski",
  "dark": "ciemny",
  "gray": "szary",
  "grayish": "szarawy",
  "green": "Zielony",
  "green cyan": "zielono-cyjanowy",
  "hue": "Odcień",
  "light": "jasny",
  "lightness": "Jaskrawość",
  "magenta": "purpurowy",
  "magenta pink": "purpurowo-różowy",
  "orange": "pomarańczowy",
  "orange yellow": "pomarańczowo-żółty",
  "pale": "blady",
  "pink": "różowy",
  "pink red": "różowo-czerwony",
  "purple": "fioletowy",
  "purple magenta": "fioletowo-purpurowy",
  "red": "Czerwony",
  "red orange": "czerwono-pomarańczowy",
  "saturation": "Nasycenie",
  "transparentColorName": "{lightness} {chroma} {hue}, {percentTransparent} przezroczystości",
  "very dark": "bardzo ciemny",
  "very light": "bardzo jasny",
  "vibrant": "intensywny",
  "white": "biały",
  "yellow": "żółty",
  "yellow green": "żółto-zielony"
}
