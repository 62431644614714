//@ts-check
import useServicesBySdkCodes from './useServicesBySdkCodes';

/**
 * @param {import('@action-types/ecApp').AppDetails} appDetails
 * @param {import('@tanstack/react-query').UseQueryOptions} [options]
 */
const useEcAppSdkCodes = (appDetails, options) => {
  const sdkCodes = appDetails?.sdks?.map((sdk) => sdk?.sdkCode);
  const servicesQuery = useServicesBySdkCodes(sdkCodes, {
    ...(options ?? {}),
    enabled: sdkCodes?.length > 0,
  });

  return servicesQuery;
};

export default useEcAppSdkCodes;
