.spectrum--dark .spectrum-Checkbox-label {
  color: rgb(227, 227, 227);
}
.spectrum--dark .spectrum-Checkbox-box {
  border-color: rgb(144, 144, 144);
  background-color: rgb(47, 47, 47);
}
.spectrum--dark .spectrum-Checkbox.is-indeterminate .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
  border-color: rgb(55, 142, 240);
}
.spectrum--dark .spectrum-Checkbox:hover.is-indeterminate .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(75, 156, 245);

  }
.spectrum--dark .spectrum-Checkbox:active.is-indeterminate .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(90, 169, 250);

  }
.spectrum--dark .spectrum-Checkbox {
  border-color: rgb(144, 144, 144);
}
.spectrum--dark .spectrum-Checkbox:hover .spectrum-Checkbox-box {
      border-color: rgb(185, 185, 185);

      box-shadow: none;
    }
.spectrum--dark .spectrum-Checkbox:hover .spectrum-Checkbox-label {
      color: rgb(255, 255, 255);
    }
.spectrum--dark .spectrum-Checkbox:active .spectrum-Checkbox-box {
      border-color: rgb(227, 227, 227);

    }
.spectrum--dark .spectrum-Checkbox:active .spectrum-Checkbox-label {
      color: rgb(255, 255, 255);
    }
.spectrum--dark .spectrum-Checkbox .spectrum-Checkbox-checkmark,
.spectrum--dark .spectrum-Checkbox .spectrum-Checkbox-partialCheckmark {
    color: rgb(47, 47, 47);
  }
.spectrum--dark .spectrum-Checkbox-input:disabled + .spectrum-Checkbox-box {
    border-color: rgb(90, 90, 90) !important;

    background-color: rgb(47, 47, 47);
  }
.spectrum--dark .spectrum-Checkbox-input:disabled ~ .spectrum-Checkbox-label {
    color: rgb(110, 110, 110);
  }
.spectrum--dark .spectrum-Checkbox-input.focus-ring + .spectrum-Checkbox-box {
    border-color: rgb(38, 128, 235) !important;

    box-shadow: 0 0 0 1px rgb(38, 128, 235) !important;
  }
.spectrum--dark .spectrum-Checkbox-input.focus-ring ~ .spectrum-Checkbox-label {
    color: rgb(75, 156, 245) !important;
  }
.spectrum--dark .spectrum-Checkbox--quiet.is-indeterminate .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox--quiet .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
  border-color: rgb(185, 185, 185);
}
.spectrum--dark .spectrum-Checkbox--quiet:hover.is-indeterminate .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox--quiet:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(227, 227, 227);
  }
.spectrum--dark .spectrum-Checkbox--quiet:active.is-indeterminate .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox--quiet:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box {
    border-color: rgb(255, 255, 255);
  }
.spectrum--dark .spectrum-Checkbox.is-invalid .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox.is-invalid .spectrum-Checkbox-box {
    border-color: rgb(236, 91, 98);
  }
.spectrum--dark .spectrum-Checkbox.is-invalid .spectrum-Checkbox-label {
    color: rgb(247, 109, 116);
  }
.spectrum--dark .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-box {
      border-color: rgb(247, 109, 116);
    }
.spectrum--dark .spectrum-Checkbox.is-invalid:hover .spectrum-Checkbox-label {
      color: rgb(255, 123, 130);
    }
.spectrum--dark .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-input:checked + .spectrum-Checkbox-box,
.spectrum--dark .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-box {
      border-color: rgb(255, 123, 130);
    }
.spectrum--dark .spectrum-Checkbox.is-invalid:active .spectrum-Checkbox-label {
      color: rgb(255, 123, 130);
    }
