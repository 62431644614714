//@ts-check
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const useWorkflow = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const workflow = searchParams.get('workflow');

  const setWorkflow = useCallback(
    /** @param {typeof import("./IntegrationDetails")['WORKFLOWS'][keyof typeof import("./IntegrationDetails")['WORKFLOWS'] ] | null } workflow */
    (workflow) => {
      if (workflow) {
        searchParams.set('workflow', workflow);
      } else {
        searchParams.delete('workflow');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  return { workflow, setWorkflow };
};

export default useWorkflow;
