{
  "alpha": "알파",
  "black": "검은색",
  "blue": "파랑",
  "blue purple": "청자색",
  "brightness": "명도",
  "brown": "갈색",
  "brown yellow": "황갈색",
  "colorName": "{lightness}, {chroma}, {hue}",
  "cyan": "청록색",
  "cyan blue": "청록색",
  "dark": "다크",
  "gray": "회색",
  "grayish": "회갈색",
  "green": "초록",
  "green cyan": "청록색",
  "hue": "색조",
  "light": "라이트",
  "lightness": "밝기",
  "magenta": "자홍색",
  "magenta pink": "마젠타 핑크",
  "orange": "주황색",
  "orange yellow": "불그스름한 노랑",
  "pale": "흙색",
  "pink": "분홍색",
  "pink red": "핑크 레드",
  "purple": "자주색",
  "purple magenta": "보라빛 자홍색",
  "red": "빨강",
  "red orange": "붉은 주황색",
  "saturation": "채도",
  "transparentColorName": "{lightness}, {chroma}, {hue}, {percentTransparent} 투명도",
  "very dark": "매우 어두운",
  "very light": "매우 연함",
  "vibrant": "강렬한",
  "white": "흰색",
  "yellow": "노란색",
  "yellow green": "연두색"
}
