import { Flex, Text } from '@adobe/react-spectrum';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Rating from '@react/react-spectrum/Rating';
import Tooltip from '@react/react-spectrum/Tooltip';
import p from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AppCard from './AppCard';

const MyRating = styled(Rating)`
  filter: grayscale(100%);
`;

const Summary = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 100%;
`;

const CCAppCard = ({ children, ...rest }) => {
  const header = React.Children.map(children, (child) => (child.type.displayName === 'Header' ? child : null));
  const body = React.Children.map(children, (child) => (child.type.displayName === 'Body' ? child : null));
  const footer = React.Children.map(children, (child) => (child.type.displayName === 'Footer' ? child : null));
  return (
    <AppCard {...rest}>
      {header}
      {body}
      {footer}
    </AppCard>
  );
};

const Header = AppCard.Header;
CCAppCard.Header = Header;
CCAppCard.Header.displayName = 'Header';

const Body = (props) => {
  const {
    children,
    rating,
    price,
    loading,
    hasTrial,
    shouldShowPrice,
    shouldShowStars,
    subscriptionTenure,
    requiresPayment,
    ...rest
  } = props;

  const getPriceString = (priceValue, hasTrial) => {
    let string = '';

    if ((priceValue ?? '0.00') === '0.00') {
      string = requiresPayment ? 'Free*' : 'Free';
    } else {
      string = `$${priceValue}${subscriptionTenure ? '/' + subscriptionTenure : ''}`;
      if (hasTrial) {
        string += ' (Trial available)';
      }
    }
    return string;
  };

  return (
    <AppCard.Body {...rest} loading={loading}>
      <Flex direction="column" justifyContent="space-between" height="100%">
        <Summary>{children}</Summary>
        {(shouldShowPrice || shouldShowStars) && (
          <Flex
            direction="row"
            justifyContent={shouldShowPrice && !shouldShowStars ? 'end' : 'space-between'}
            alignItems="center"
            wrap="wrap"
            marginTop="size-100"
          >
            {shouldShowStars && (
              <OverlayTrigger placement="right">
                <span>
                  <MyRating readOnly value={rating ?? 0} />
                </span>
                <Tooltip>{rating ? rating.toFixed(1) : 'Not enough ratings.'}</Tooltip>
              </OverlayTrigger>
            )}
            {shouldShowPrice && (
              <OverlayTrigger placement="top">
                <span>
                  <Text>{getPriceString(price, hasTrial)}</Text>
                </span>
                {requiresPayment ? (
                  <Tooltip variant="inspect">Requires additional purchase to unlock all features</Tooltip>
                ) : (
                  <></>
                )}
              </OverlayTrigger>
            )}
          </Flex>
        )}
      </Flex>
    </AppCard.Body>
  );
};

Body.propTypes = {
  children: p.oneOfType([p.arrayOf(p.node), p.node]),
  loading: p.bool.isRequired,
  price: p.string,
  hasTrial: p.bool,
  rating: p.number,
  shouldShowPrice: p.bool,
  shouldShowStars: p.bool,
};
Body.defaultProps = {
  loading: false,
  shouldShowPrice: true,
  shouldShowStars: true,
};
CCAppCard.Body = Body;
CCAppCard.Body.displayName = 'Body';

const Footer = AppCard.Footer;
CCAppCard.Footer = Footer;
CCAppCard.Footer.displayName = 'Footer';

export default CCAppCard;
