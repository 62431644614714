import { Flex, Image, View } from '@adobe/react-spectrum';
import { Card } from '@react/react-spectrum/Card';
import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getCorrectUrlSlug } from '@exchange-frontends/utils';
import { WithSkeleton } from '@exchange-frontends/components';

import BadgePopover from './BadgePopover';
import usePublisherLink from '../../hooks/usePublisherLink';
import { ROUTES } from '../../constants';



/**
 * react-spectrum Card overriden with custom styles
 * @memberof AppCard
 * @type {styled-component}
 * @property {string} [cardWidth=307px] - the width or the card
 */
const StyledCard = styled(Card)`
  width: ${(props) => (props.cardWidth ? props.cardWidth : '307px')};
  // max-width: 395px;
  justify-content: space-between;
`;

/**
 * container with padding and a fixed size for images
 * @memberof AppCard.Header
 * @type {styled-component}
 */
const ImageContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-right: var(--spectrum-global-dimension-size-200);
  & img {
    border-radius: 4px;
  }
`;

/**
 * react-spectrum Card header overridden to have height: auto
 * @memberof AppCard.Header
 * @type {styled-component}
 */
const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 64px;
`;

/**
 * react-spectrum Card title overridden with custom styles
 * @memberof AppCard.Header
 * @type {styled-component}
 */
const CardTitle = styled(Link)`
  color: rgba(44, 44, 44, 1);
  font-size: 16px;
  font-weight: bold;
  white-space: normal;
  line-height: 20px;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
  :hover {
    text-decoration: underline;
  }
`;

/**
 * react-spectrum Card subtitle link overridden with custom styles
 * @memberof AppCard.Header
 * @type {styled-component}
 */
const CardSubtitleLink = styled(Link)`
  margin: 4px 0 0 0;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

/**
 * react-spectrum Card subtitle overridden with custom styles
 * @memberof AppCard.Header
 * @type {styled-component}
 */
const CardSubtitle = styled.div`
  margin: 4px 0 0 0;
  font-size: 12px;
  color: rgba(116, 116, 116, 1);
`;

/**
 * custom CardBody based on the react-spectrum one
 * @memberof AppCard
 * @type {styled-component}
 */
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
`;

/**
 * react-spectrum Card footer overridden with custom styles
 * @memberof AppCard.Footer
 * @type {styled-component}
 */
const CardFooter = styled.div.attrs(() => ({ className: 'spectrum-Card-footer' }))`
  font-size: 12px;
  margin-right: 16px;
  margin-left: 16px;
  padding-bottom: 16px;
  min-height: 54px;
  box-sizing: border-box;
`;

/**
 * react-spectrum Card body overridden with custom styles
 * @memberof AppCard.Body
 * @type {styled-component}
 */
const CardBodyContent = styled.div`
  margin-top: var(--spectrum-global-dimension-size-100);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 100%;
`;

/**
 * react-spectrum Card footer overridden with custom styles
 * @memberof AppCard.Footer
 * @type {styled-component}
 * @property {('start'|'end'|'center'|'left'|'right'|'space-between'|'space-around'|'space-evenly'|'stretch'|'baseline'|'first baseline'|'last baseline'|'safe center'|'unsafe center')} [justifyContent=space-between] - flex justify-content value
 * @property {('start'|'end'|'center'|'stretch'|'self-start'|'self-end'|'baseline'|'first baseline'|'last baseline'|'safe center'|'unsafe center')} [alignItems=center] - flex align-items value
 */
const StyledCardFooter = styled.div`
  display: flex;
  justify-content: ${(props) => props?.justifyContent ?? 'space-between'};
  align-items: ${(props) => props.alignItems ?? 'center'};
  color: var(--spectrum-global-color-gray-600);
`;

/**
 * AppCard component expands on the react-spectrum Card with some additional styling
 * @component
 * @category Shared Components
 * @subcategory AppCard
 * @prop {string} cardWidth - overrides the width of the card
 * @prop {('AppCard.Header'|'AppCard.Body'|'AppCard.Footer')} children - AppCard sub-components
 */
const AppCard = ({ children, ...rest }) => {
  const header = React.Children.map(children, (child) => (child.type.displayName === 'Header' ? child : null));
  const body = React.Children.map(children, (child) => (child.type.displayName === 'Body' ? child : null));
  const footer = React.Children.map(children, (child) => (child.type.displayName === 'Footer' ? child : null));
  return (
    <StyledCard allowsSelection={false} selected={false} size="L" {...rest}>
      <CardBody>
        {header}
        {body}
      </CardBody>
      {footer}
    </StyledCard>
  );
};

/**
 * AppCard Header sub-component
 * @component
 * @category Shared Components
 * @subcategory AppCard
 * @memberof AppCard
 * @prop {string} icon - url of AppCard icon
 * @prop {string} title - text for the AppCard title
 * @prop {string} subtitle - text for the AppCard subtitle
 * @prop {string} badgeType - type of badge to display (e.g. 'Verified')
 * @prop {string} appId - used to create the title link towards the app page
 * @prop {string} listingType - type of listing (e.g. 'integrations')
 * @prop {string} appName - name of the app (used for URL slug)
 * @prop {boolean} loading - whether the component is in loading state
 * @prop {boolean} disableSkeletonAnimation - whether to disable skeleton loading animation
 * @prop {object} publisher - publisher details object
 * @prop {string} cloud - cloud platform (e.g. 'cc', 'ec')
 * @prop {string} appType - type of app (e.g. 'SLN')
 * @prop {string} id - identifier for analytics
 */
const Header = ({
  icon,
  title,
  subtitle,
  badgeType,
  appId,
  listingType,
  appName,
  loading,
  disableSkeletonAnimation,
  publisher,
  cloud,
  appType,
  id,
}) => {

  
  const appNameSlug = getCorrectUrlSlug(appName ?? title);
    
  const titleLink = useMemo(() => {
    const appType = listingType === 'integrations' ? listingType : cloud?.toLowerCase();
    return `${ROUTES.DETAILS}/${appType}/${appId}/${appNameSlug}`;
  }, [listingType, cloud, appId, appNameSlug]);

  const { publisherLink, publisherTarget, openPublisherLink } = usePublisherLink({ publisher, appType, cloud });

  const PublisherTitleContainer = useMemo(() => 
    listingType === 'integrations' ? CardSubtitle : CardSubtitleLink,
  [listingType]);

  const handlePublisherClick = useCallback((e) => {
    if (listingType !== 'integrations') {
      e.preventDefault();
      openPublisherLink();
    }
  }, [listingType, openPublisherLink]);

  return (
    <Flex justifyContent="space-between">
      <Flex>
        <ImageContainer>
          <WithSkeleton
            shouldRender={!loading}
            enableAnimation={!disableSkeletonAnimation}
            circle={true}
            width="40px"
            height="40px"
          >
            <Image alt="App icon" width="40px" height="40px" objectFit="contain" src={icon} />
          </WithSkeleton>
        </ImageContainer>
        <View marginEnd="auto">
          <CardHeader>
            <WithSkeleton shouldRender={!loading} enableAnimation={!disableSkeletonAnimation} width={150}>
              <CardTitle to={titleLink} data-launchid={id}>
                {title}
              </CardTitle>
            </WithSkeleton>
            <WithSkeleton shouldRender={!loading} enableAnimation={!disableSkeletonAnimation} width={100}>
              <PublisherTitleContainer 
                to={publisherLink} 
                data-launchid={id}
                target={publisherTarget}
                onClick={handlePublisherClick}
              >
                {subtitle}
              </PublisherTitleContainer>
            </WithSkeleton>
          </CardHeader>
        </View>
      </Flex>
      {badgeType && !loading && (
        <Flex marginStart="size-100" width="40%" maxWidth="72px">        
          <BadgePopover
            badgeType={badgeType}
          />
        </Flex>
      )}
    </Flex>
  );
};
AppCard.Header = Header;
AppCard.Header.displayName = 'Header';

/**
 * AppCard Body sub-component
 * @component
 * @category Shared Components
 * @subcategory AppCard
 * @memberof AppCard
 * @prop {string[]|component[]} children - content of the AppCard body
 */
const Body = ({ children, loading, disableSkeletonAnimation }) => (
  <WithSkeleton shouldRender={!loading} enableAnimation={!disableSkeletonAnimation} count={3}>
    <CardBodyContent>{children}</CardBodyContent>
  </WithSkeleton>
);
AppCard.Body = Body;
AppCard.Body.displayName = 'Body';

/**
 * AppCard Footer sub-component
 * @component
 * @category Shared Components
 * @subcategory AppCard
 * @memberof AppCard
 * @prop {string[]|component[]} children - content of the AppCard footer
 */
const Footer = ({ children, loading, disableSkeletonAnimation }) => (
  <CardFooter data-testid="app-card-footer">
    <WithSkeleton shouldRender={!loading} enableAnimation={!disableSkeletonAnimation} height={20}>
      <StyledCardFooter>{children}</StyledCardFooter>
    </WithSkeleton>
  </CardFooter>
);
AppCard.Footer = Footer;
AppCard.Footer.displayName = 'Footer';

export default AppCard;
